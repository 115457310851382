import React from "react";
import styled from "styled-components";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ButtonGroup } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import { SortableItem } from "src/Modules/Talent/Pages/TalentProfile/Sections/SortableItem";

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  position: relative;
`;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const Label = styled.div`
  /* Notch/Label/Medium */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

const getSkillLabel = (code) => mapToLabel(code, skillOptions);

const SkillName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1d7c93;
`;

const SkillYears = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;

  width: fit-content;
  height: 22px;

  background: #1d7c93;
  border-radius: 999px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  letter-spacing: -0.03em;

  color: #f4fbfd;
`;

const SkillContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #f4fbfd;

  gap: 8px;

  height: 32px;
  padding: 0 12px;

  border-radius: 6px;
`;

const Star = styled.div`
  width: 20px;
  height: 20px;

  display: none;

  ${SkillContainer}:hover & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const filterAdditionalSkills = (topSkills, additionalSkills) => {
  let topSkillsSet = new Set(topSkills.map((i) => i.skill));
  return additionalSkills.filter((option) => {
    return !topSkillsSet.has(option);
  });
};

const SortableSkillsSection: React.FC = (props) => {
  let values = props.values;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  let skillsCode = values.topSkills.map((i) => i.skill);

  return (
    <Container>
      <Content>
        <Label>Top Skills</Label>
        <DndContext
          sensors={sensors}
          onDragEnd={(event) => {
            const { active, over } = event;

            if (active.id === over.id) {
              return;
            }

            let newOrder = [];

            const oldIndex = values.topSkills.findIndex(
              (i) => i.skill == active.id
            );
            const newIndex = values.topSkills.findIndex(
              (i) => i.skill == over.id
            );

            newOrder = arrayMove(values.topSkills, oldIndex, newIndex);
            props.form.change("topSkills", newOrder);
          }}
        >
          <SortableContext items={skillsCode} strategy={rectSortingStrategy}>
            <List>
              {values.topSkills.map((skill) => {
                return (
                  <SortableItem
                    key={skill.skill}
                    id={skill.skill}
                    skill={skill}
                    type={"top"}
                    toggleIsTop={() => {
                      if (!skill) {
                        return;
                      }

                      let newValue = values.topSkills.filter(
                        (i) => i.skill != skill.skill
                      );

                      props.form.change("topSkills", newValue);
                    }}
                  ></SortableItem>
                );
              })}
            </List>
          </SortableContext>
        </DndContext>
        <Label>Additional Skills</Label>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => {
            const { active, over } = event;

            if (active.id === over.id) {
              return;
            }

            let newOrder = [];

            const oldIndex = values.skills.findIndex((i) => i == active.id);
            const newIndex = values.skills.findIndex((i) => i == over.id);

            newOrder = arrayMove(values.skills, oldIndex, newIndex);
            props.form.change("skills", newOrder);
          }}
        >
          <SortableContext
            items={values.skills.map((i) => i)}
            strategy={rectSortingStrategy}
          >
            <List>
              {filterAdditionalSkills(values.topSkills, values.skills).map(
                (skill) => {
                  return (
                    <SortableItem
                      key={skill}
                      id={skill}
                      type={"additional"}
                      skill={skill}
                      toggleIsTop={() => {
                        const topSkillsSet = new Set(
                          values.topSkills.map((i) => i.skill)
                        );

                        if (!topSkillsSet.has(skill)) {
                          let newTopSkills = values.topSkills.map((i) => i);
                          newTopSkills.push({ skill: skill, years: 1 });
                          props.form.change("topSkills", newTopSkills);
                        }
                      }}
                    ></SortableItem>
                  );
                }
              )}
            </List>
          </SortableContext>
        </DndContext>
      </Content>
    </Container>
  );
};

export default SortableSkillsSection;
