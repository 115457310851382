import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { GrayDotSeparator } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import ImageWithFallback from "src/Modules/Shared/Components/Util/ImageWithFallback";
import Building2FillIcon from "remixicon-react/Building2FillIcon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: fit-content;
`;

const Logo = styled.div`
  width: 40px;
  aspect-ratio: 1;
`;

const Name = styled.div`
  /* Notch/Label/Small */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const Text = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const Years = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/600 */
  color: #6a7288;
`;

const FallbackIcon = styled.div`
  aspect-ratio: 1;
  width: 100%;
  border-radius: 6px;
  background: #ededf0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EducationEntry: React.FC = (props) => {
  let data = props.data;

  return (
    <Container>
      <Logo>
        <ImageWithFallback
          size={40}
          src={`https://logo.clearbit.com/${data.university?.websiteUrl}`}
          fallback={
            <FallbackIcon>
              <Building2FillIcon size={18} color={"#9FA4B3"} />
            </FallbackIcon>
          }
        />
      </Logo>
      <Info>
        <Name>{data.universityName}</Name>
        <Line>
          <Text>{data.degree}</Text>
          <GrayDotSeparator />
          <Text>{data.fieldOfStudy}</Text>
        </Line>
        <Years>
          {data.startYear} - {data.endYear ?? "Current"}
        </Years>
      </Info>
    </Container>
  );
};

export default EducationEntry;
