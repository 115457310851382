import React from "react";
import styled from "styled-components";
import arrayMutators from "final-form-arrays";
import { Field, Form, FormSpy } from "react-final-form";
import JobStatusDropdown from "src/Modules/Customer/Components/Jobs/JobStatusDropdown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";
import axios from "axios";
import TalentHiringStatusField from "src/Modules/Talent/Components/TalentHiringStatusField";

const Container = styled.div`
  margin-left: auto;
  width: 100%;
`;

const TalentHiringStatus: React.FC = (props) => {
  let value = props.value;
  const queryClient = useQueryClient();

  let updateStatusMutation = useMutation({
    mutationFn: (values) =>
      axios.put("/api/talent/me/profile/hiring-status", values),
    onSuccess: () => {
      queryClient.invalidateQueries(["talent", "profile"]);
    },
  });

  return (
    <Container>
      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={(values) => {
          updateStatusMutation.mutate(values);
        }}
        initialValues={{ hiringStatus: value }}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Field name="hiringStatus" component={TalentHiringStatusField} />
            <FormSpy
              subscription={{ values: true, dirtyFields: true }}
              onChange={(props) => {
                if (props.dirtyFields?.hiringStatus == true) {
                  form.submit();
                }
              }}
            ></FormSpy>
          </form>
        )}
      />
    </Container>
  );
};

export default TalentHiringStatus;
