import React from "react";
import styled, { keyframes } from "styled-components";
import ArrowLeftFillIcon from "remixicon-react/ArrowLeftFillIcon";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import Typography from "src/Modules/Core/Components/Atoms/Typography/Typography";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import Briefcase2LineIcon from "remixicon-react/Briefcase2LineIcon";
import shortlistData from "src/Modules/Admin/Pages/ExperimentalShortlist/shortlistData";

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 32px;
`;

const wiggleAnimation = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(22px);
  }
  100% {
    transform: translateX(0px);
  }
`;

const Message = styled.div`
  font-size: 56px;
  font-family: Inter;
  color: black;
`;

const ArrowLeftContainer = styled.div`
  animation-name: ${wiggleAnimation};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  scroll-snap-align: start;
  cursor: pointer;

  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #ededf0;

  width: 100%;
  height: max-content;
`;

const Facts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  gap: 16px;
`;
const Skills = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 12px;
  gap: 10px;

  background: #f4fbfd;
  border-radius: 6px;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1d7c93;
`;

const Fact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;

  padding: 0px 12px;

  height: 32px;
  width: fit-content;

  background: #fafafb;
  border-radius: 6px;
`;

const Upper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Location = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #6a7288;
`;

const MoreInfo = styled.div`
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #ededf0;

  width: 100%;
  height: 140px;

  padding: 24px;
  margin-bottom: 33px;
`;

const ProfileContainer = styled.div`
  width: 96px;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CandidateSingleView: React.FC = (props) => {
  const id = props.id;

  if (id == null) {
    return (
      <EmptyContainer>
        <ArrowLeftContainer>
          <ArrowLeftLineIcon size={80} color="black" />
        </ArrowLeftContainer>
        <Message>Select someone</Message>
      </EmptyContainer>
    );
  }

  const data = shortlistData.find((i) => {
    return i.id === id;
  });

  return (
    <Container>
      <Card>
        <Upper>
          <ProfileContainer>
            <AccountProfileAvatar size={96} name={data.name} />
          </ProfileContainer>
          <ProfileDetails>
            <Typography type="LabelLarge">{data.name}</Typography>
            <Typography type="BodyLargeRegular">{data.title}</Typography>
            <Location>{data.location}</Location>
          </ProfileDetails>
        </Upper>
        <Facts>
          <Fact>
            <CompanyLogo
              size={18}
              src={`https://logo.clearbit.com/${data.companyUrl}`}
            />
            <Typography type="BodyLargeRegular">{data.company}</Typography>
          </Fact>
          <Fact>
            <Briefcase2LineIcon size={18} color="#444" />
            <Typography type="BodyLargeRegular">{data.years} years</Typography>
          </Fact>
          <Fact>
            <Typography type="BodyLargeRegular">Fact #3</Typography>
          </Fact>
          <Fact>
            <Typography type="BodyLargeRegular">Fact #4</Typography>
          </Fact>
          <Fact>
            <Typography type="BodyLargeRegular">Fact #5</Typography>
          </Fact>
        </Facts>
        <Skills>
          {data.skills.map((i) => {
            return <Skill>{i}</Skill>;
          })}
        </Skills>
        <MoreInfo></MoreInfo>
      </Card>
      <Card>
        <h1 style={{ color: "black", height: "200px" }}>
          PROFILE or something
        </h1>
      </Card>
    </Container>
  );
};

export default CandidateSingleView;
