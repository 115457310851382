import React, { useState } from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form } from "react-final-form";
import EditLineIcon from "remixicon-react/EditLineIcon";
import { NavLink } from "react-router-dom";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import SectionEditCard from "src/Modules/Talent/Pages/TalentProfile/SectionEditCard";
import {
  ButtonGroup,
  EditFooterLine,
  FormField,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import LinkedinLineIcon from "remixicon-react/LinkedinLineIcon";
import TwitterLineIcon from "remixicon-react/TwitterLineIcon";
import GithubLineIcon from "remixicon-react/GithubLineIcon";
import LinkedinFillIcon from "remixicon-react/LinkedinFillIcon";
import TwitterFillIcon from "remixicon-react/TwitterFillIcon";
import GithubFillIcon from "remixicon-react/GithubFillIcon";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import PagesFillIcon from "remixicon-react/PagesFillIcon";
import TextInputFieldWithInsideIcon from "src/Modules/Shared/Components/Temp/TextInputFieldWithInsideIcon";
import ConditionalRender from "src/Modules/Talent/Pages/TalentProfile/ConditionalRender";

const Container = styled.div``;

const EditAction = (props) => {
  let Container = styled.div`
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -20px;
    &:hover svg {
      color: red;
    }
  `;

  return (
    <Container {...props}>
      <EditLineIcon color={"gray"} size={18} />
    </Container>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Url = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1c274a;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RowLines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NewLineInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }
`;

const NewLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 12px;

  width: 100%;
  height: 48px;

  gap: 8px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &.active {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }
`;

const NewLineInputInside = styled.input`
  background: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  border: 1px solid transparent;
  outline: none;
  flex: 1 1 auto;
  width: 120px;

  &::placeholder {
    color: #9fa4b3;
  }
`;

const NewLineIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RowLineSpacer = styled.div`
  height: 8px;
`;

const hasEmptyLine = (values: Array<any>) => {
  if (values == undefined) {
    return false;
  }

  for (const [key, value] of Object.entries(values)) {
    if (value == "") {
      return true;
    }
  }

  return false;
};

const AddMoreLinksAction = styled.div`
  color: var(--Notch-Neutral-400, #9fa4b3);

  /* Notch / Button / Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  margin: 0 auto;
`;

const IconSize = 18;
const IconColor = "#1c274a";

const Link = styled(NavLink)``;

const SocialSection: React.FC = (props) => {
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) =>
      axios.put(`/api/talent/${props.profile.talent.id}/profile/social`, data),
    onSuccess: (data) => {
      setEdit(false);
      props.onUpdate(data);
    },
  });

  let values = props.values;
  let data = props.profile;
  let status = "MetSuggested";

  return (
    <Form
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
      mutators={{ ...arrayMutators }}
      initialValues={{
        socialLinkedinUrl: data.socialLinkedinUrl,
        socialTwitterXUrl: data.socialTwitterXUrl,
        socialGithubUrl: data.socialGithubUrl,
      }}
      render={({ handleSubmit, submitting, values, form }) => (
        <form onSubmit={handleSubmit}>
          <TalentProfileSectionCard
            title="Social Profiles"
            status={props.profileStrength.sections?.social?.status}
            info={props.profileStrength.sections?.social?.info}
            messages={props.profileStrength.sections?.social?.messages}
          >
            {edit && (
              <SectionEditCard>
                <FormContent>
                  <FormField>
                    <Label>Linkedin</Label>
                    <Field
                      name={"socialLinkedinUrl"}
                      icon={<LinkedinFillIcon size={20} />}
                      component={TextInputFieldWithInsideIcon}
                    />
                  </FormField>
                  <FormField>
                    <Label>X ( Twitter )</Label>
                    <Field
                      name={"socialTwitterXUrl"}
                      icon={<TwitterFillIcon size={20} />}
                      component={TextInputFieldWithInsideIcon}
                    />
                  </FormField>
                  <FormField>
                    <Label>Github</Label>
                    <Field
                      name={"socialGithubUrl"}
                      icon={<GithubFillIcon size={20} />}
                      component={TextInputFieldWithInsideIcon}
                    />
                  </FormField>
                  <Label>Other</Label>

                  <FieldArray name={"socialOthersUrl"}>
                    {({ fields }) => (
                      <RowLines>
                        {fields?.map((name, index) => {
                          return (
                            <div id={name}>
                              <Field
                                onFocus={(event: FocusEvent) => {
                                  //remove all empty fields but this
                                  values.socialOthersUrl?.forEach(
                                    (i: any, ii: any) => {
                                      if (i == undefined && index != ii) {
                                        form.mutators.remove(
                                          "socialOthersUrl",
                                          ii
                                        );
                                      }
                                    }
                                  );
                                }}
                                id={`socialOthersUrl_${index}`}
                                name={`${name}`}
                                index={index}
                                form={form}
                                icon={
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.70825 10.8333H6.27242C6.41502 13.4723 7.25621 16.0261 8.70992 18.2333C6.88903 17.9472 5.21425 17.0659 3.94743 15.727C2.68062 14.3881 1.89318 12.6672 1.70825 10.8333V10.8333ZM1.70825 9.1666C1.89318 7.33269 2.68062 5.61175 3.94743 4.27286C5.21425 2.93398 6.88903 2.05262 8.70992 1.7666C7.25621 3.97378 6.41502 6.52756 6.27242 9.1666H1.70825ZM18.2916 9.1666H13.7274C13.5848 6.52756 12.7436 3.97378 11.2899 1.7666C13.1108 2.05262 14.7856 2.93398 16.0524 4.27286C17.3192 5.61175 18.1067 7.33269 18.2916 9.1666V9.1666ZM18.2916 10.8333C18.1067 12.6672 17.3192 14.3881 16.0524 15.727C14.7856 17.0659 13.1108 17.9472 11.2899 18.2333C12.7436 16.0261 13.5848 13.4723 13.7274 10.8333H18.2924H18.2916ZM7.94158 10.8333H12.0583C11.9211 13.0828 11.2127 15.2603 9.99992 17.1599C8.78717 15.2603 8.07875 13.0828 7.94158 10.8333V10.8333ZM7.94158 9.1666C8.07875 6.91703 8.78717 4.73957 9.99992 2.83993C11.2127 4.73957 11.9211 6.91703 12.0583 9.1666H7.94158Z"
                                      fill="#1C274A"
                                    />
                                  </svg>
                                }
                                component={TextInputFieldWithInsideIcon}
                                placeholder={`Website #${index + 1}`}
                              />
                              <RowLineSpacer />
                            </div>
                          );
                        })}
                        <ConditionalRender condition={false}>
                          {!hasEmptyLine(values.socialOthersUrl) && (
                            <NewLineContainer>
                              <NewLineIcon>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.70825 10.8333H6.27242C6.41502 13.4723 7.25621 16.0261 8.70992 18.2333C6.88903 17.9472 5.21425 17.0659 3.94743 15.727C2.68062 14.3881 1.89318 12.6672 1.70825 10.8333V10.8333ZM1.70825 9.1666C1.89318 7.33269 2.68062 5.61175 3.94743 4.27286C5.21425 2.93398 6.88903 2.05262 8.70992 1.7666C7.25621 3.97378 6.41502 6.52756 6.27242 9.1666H1.70825ZM18.2916 9.1666H13.7274C13.5848 6.52756 12.7436 3.97378 11.2899 1.7666C13.1108 2.05262 14.7856 2.93398 16.0524 4.27286C17.3192 5.61175 18.1067 7.33269 18.2916 9.1666V9.1666ZM18.2916 10.8333C18.1067 12.6672 17.3192 14.3881 16.0524 15.727C14.7856 17.0659 13.1108 17.9472 11.2899 18.2333C12.7436 16.0261 13.5848 13.4723 13.7274 10.8333H18.2924H18.2916ZM7.94158 10.8333H12.0583C11.9211 13.0828 11.2127 15.2603 9.99992 17.1599C8.78717 15.2603 8.07875 13.0828 7.94158 10.8333V10.8333ZM7.94158 9.1666C8.07875 6.91703 8.78717 4.73957 9.99992 2.83993C11.2127 4.73957 11.9211 6.91703 12.0583 9.1666H7.94158Z"
                                    fill="#1C274A"
                                  />
                                </svg>
                              </NewLineIcon>
                              <NewLineInputInside
                                onFocus={(event: FocusEvent) => {
                                  //remove all empty fields but this
                                  values.socialOthersUrl?.forEach(
                                    (i: any, ii: any) => {
                                      if (i == undefined) {
                                        form.mutators.remove(
                                          "socialOthersUrl",
                                          ii
                                        );
                                      }
                                    }
                                  );
                                }}
                                placeholder={``}
                                onChange={(event) => {
                                  event.target.disabled = true;
                                  const index = values.socialOthersUrl
                                    ? values.socialOthersUrl.length
                                    : 0;
                                  form.mutators.insert(
                                    "socialOthersUrl",
                                    index,
                                    event.target.value
                                  );
                                  event.target.value = "";
                                  setTimeout(() => {
                                    const el = document.getElementById(
                                      "socialOthersUrl_" + index
                                    );
                                    el.focus();
                                    event.target.disabled = false;
                                  });
                                }}
                              />
                            </NewLineContainer>
                          )}
                        </ConditionalRender>
                      </RowLines>
                    )}
                  </FieldArray>
                  <AddMoreLinksAction
                    onClick={() => {
                      // Add more links
                      const index = values.socialOthersUrl
                        ? values.socialOthersUrl.length
                        : 0;

                      form.mutators.insert("socialOthersUrl", index, "");
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1196_5266)">
                        <path
                          d="M9.16675 9.1665V4.1665H10.8334V9.1665H15.8334V10.8332H10.8334V15.8332H9.16675V10.8332H4.16675V9.1665H9.16675Z"
                          fill="#9FA4B3"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1196_5266">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span>Add More Links</span>
                  </AddMoreLinksAction>
                  <EditFooterLine />
                  <ButtonGroup>
                    <div style={{ marginLeft: "auto" }} />
                    <Button onClick={() => form.submit()} variant="primary">
                      Save
                    </Button>
                  </ButtonGroup>
                </FormContent>
              </SectionEditCard>
            )}
            {!edit && (
              <List>
                <EditAction
                  onClick={() => {
                    setEdit(true);
                  }}
                />
                {data.socialLinkedinUrl && (
                  <Item>
                    <Icon>
                      <LinkedinFillIcon size={IconSize} color={IconColor} />
                    </Icon>
                    <Url>{data.socialLinkedinUrl}</Url>
                    <Link to={data.socialLinkedinUrl} />
                  </Item>
                )}
                {data.socialTwitterXUrl && (
                  <Item>
                    <Icon>
                      <TwitterFillIcon size={IconSize} color={IconColor} />
                    </Icon>
                    <Url>{data.socialTwitterXUrl}</Url>
                    <Link to={data.socialTwitterXUrl} />
                  </Item>
                )}
                {data.socialGithubUrl && (
                  <Item>
                    <Icon>
                      <GithubFillIcon size={IconSize} color={IconColor} />
                    </Icon>
                    <Url>{data.socialGithubUrl}</Url>
                    <Link to={data.socialGithubUrl} />
                  </Item>
                )}

                {data.socialOthersUrl?.map((i) => {
                  return (
                    <Item>
                      <Icon>
                        <PagesFillIcon size={IconSize} color={IconColor} />
                      </Icon>
                      <Url>{i}</Url>
                      <Link to={i} />
                    </Item>
                  );
                })}
              </List>
            )}
          </TalentProfileSectionCard>
        </form>
      )}
    />
  );
};

export default SocialSection;
