import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: "Inter";
  font-size: 12px;

  padding: 0px 12px;

  min-width: 20px;

  width: fit-content;
  border-radius: 6px;

  height: 24px;
`;

const Default = styled(Container)`
  color: #0f172a;
  background: #f2f2f2;
`;

const TalentStage: React.FC = (props) => {
  let stage = props.stage;

  return <Default>{stage ?? "Undefined"}</Default>;
};

export default TalentStage;
