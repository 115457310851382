import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { GrayDotSeparator } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
`;

const Logo = styled.div`
  width: 40px;
  aspect-ratio: 1;
`;

const Name = styled.div`
  /* Notch/Label/Small */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const Text = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const Years = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/600 */
  color: #6a7288;
`;

const Description = styled.pre`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  white-space: break-spaces;

  color: #1c274a;

  width: 100%;

  span {
    display: block;
  }

  ul {
    margin-top: 8px;
    padding-left: 24px;
  }
`;

const Contributions = styled.ul`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  padding-left: 12px;
  color: #1c274a;
`;

const Skills = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 4px;
  flex-wrap: wrap;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: 4px 8px;

  /* Notch/Secondary/Teal/50 */
  background: #f4fbfd;
  /* Notch/Neutral/50 */
  border: 1px solid #fafafb;
  border-radius: 6px;

  /* React.js */

  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;
  color: #1d7c93;
`;

const descriptionToHTML = (text) => {
  let fragments = [];
  let bullets = [];

  if (text.length == 0) {
    return null;
  }

  let lines = text.split("\n");

  for (let line of lines) {
    let lineWithNoPadding = line.trim();

    if (lineWithNoPadding.startsWith("-")) {
      bullets.push(<li>{lineWithNoPadding.substring(1)}</li>);
    } else {
      if (bullets.length > 0) {
        fragments.push(<ul>{bullets}</ul>);
        bullets = [];
      }
      fragments.push(<span>{line}</span>);
    }
  }

  if (bullets.length > 0) {
    fragments.push(<ul>{bullets}</ul>);
  }

  return <div>{fragments}</div>;
};

const EducationEntry: React.FC = (props) => {
  let data = props.data;

  return (
    <Container>
      <Logo>
        <CompanyLogo
          size={40}
          src={`https://logo.clearbit.com/${data.companyWebsite}`}
        />
      </Logo>
      <Info>
        <Name>{data.companyName}</Name>
        <div style={{ height: 4 }} />
        <Name>{data.title}</Name>
        <Line>
          <Years>{data.employmentType}</Years>
          <GrayDotSeparator />
          <Years>
            {mapToLabel(data.startDateMonth, [
              { value: 1, label: "January" },
              { value: 2, label: "February" },
              { value: 3, label: "March" },
              { value: 4, label: "April" },
              { value: 5, label: "May" },
              { value: 6, label: "June" },
              { value: 7, label: "July" },
              { value: 8, label: "August" },
              { value: 9, label: "September" },
              { value: 10, label: "October" },
              { value: 11, label: "November" },
              { value: 12, label: "December" },
            ])?.substring(0, 3)}{" "}
            {data.startDateYear}
            {" - "}
            {data.currentlyWorking ? (
              <>{"Present"}</>
            ) : (
              <>
                {mapToLabel(data.endDateMonth, [
                  { value: 1, label: "January" },
                  { value: 2, label: "February" },
                  { value: 3, label: "March" },
                  { value: 4, label: "April" },
                  { value: 5, label: "May" },
                  { value: 6, label: "June" },
                  { value: 7, label: "July" },
                  { value: 8, label: "August" },
                  { value: 9, label: "September" },
                  { value: 10, label: "October" },
                  { value: 11, label: "November" },
                  { value: 12, label: "December" },
                ])?.substring(0, 3)}{" "}
                {data.endDateYear}
              </>
            )}
          </Years>
          <Years></Years>
        </Line>
        {data.remote ? (
          <Line>
            <Years>Remote</Years>
          </Line>
        ) : (
          <Line>
            <Years>{data.location?.label}</Years>
          </Line>
        )}
        <div style={{ height: 12 }} />
        {data.roleDescription && (
          <>
            <Description>{descriptionToHTML(data.roleDescription)}</Description>
          </>
        )}
        <div style={{ height: 12 }} />

        {data.keyContributions?.length > 0 && (
          <Contributions>
            {data.keyContributions?.map((i) => {
              return <li>{i}</li>;
            })}
          </Contributions>
        )}
        <div style={{ height: 12 }} />

        {data.skills?.length > 0 && (
          <>
            <Text>Skills</Text>
            <Skills>
              {data.skills?.map((i) => {
                return <Skill>{mapToLabel(i, skillOptions)}</Skill>;
              })}
            </Skills>
          </>
        )}
      </Info>
    </Container>
  );
};

export default EducationEntry;
