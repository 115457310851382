import React from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import TalentProfileSectionList from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionList";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ProjectEntry from "src/Modules/Talent/Pages/TalentProfile/Sections/ProjectEntry";
import AddFillIcon from "remixicon-react/AddFillIcon";
import ProjectEditForm from "src/Modules/Talent/Pages/TalentProfile/Sections/ProjectEditForm";

const Container = styled.div``;

const ProjectsSection: React.FC = (props) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `/api/talent/${props.profile.talent.id}/profile/projects`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `/api/talent/${props.profile.talent.id}/profile/projects/${data.id}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (data) =>
      axios.delete(
        `/api/talent/${props.profile.talent.id}/profile/projects/${data.id}`
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  let profile = props.profile;

  return (
    <TalentProfileSectionCard
      title="Projects"
      status={props.profileStrength.sections?.projects?.status}
      info={props.profileStrength.sections?.projects?.info}
      messages={props.profileStrength.sections?.projects?.messages}
    >
      <TalentProfileSectionList
        singleItemView={(item) => (
          <ProjectEntry data={item} profile={profile} />
        )}
        singleItemEditForm={(formProps) => (
          <ProjectEditForm formProps={formProps} />
        )}
        createMutation={createMutation}
        updateMutation={updateMutation}
        deleteMutation={deleteMutation}
        items={profile.projects}
        profile={profile}
        itemForm={(formProps) => (
          <ProjectEditForm formProps={formProps} profile={profile} />
        )}
        createItemLabel={
          <>
            <AddFillIcon size={20} />
            Add Project
          </>
        }
      />
    </TalentProfileSectionCard>
  );
};

export default ProjectsSection;
