import React from "react";
import styled from "styled-components";
import {
  FormField,
  FormRow,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import { Field } from "react-final-form";
import UniversitySelect from "src/Modules/Core/Components/Form/Select/UniversitySelect/UniversitySelect";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import ToggleSwitchField from "src/Modules/Shared/Components/Temp/ToggleSwitchField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import MainSkillsField from "src/Modules/Shared/Components/Temp/MainSkillsField";
import CommonMultipleSkillsSelect from "src/Modules/Shared/Components/Temp/CommonMultipleSkillsSelect";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import { FormLabel } from "src/Modules/Recruiter/Pages/Profile/ProfileEditForm";
import { FieldArray } from "react-final-form-arrays";
import ProjectScreenshot from "src/Modules/Talent/Pages/TalentProfile/ProjectScreenshot";
import ProjectEditScreenshot from "src/Modules/Talent/Pages/TalentProfile/Sections/ProjectEditScreenshot";
import AssosiatedWorkHistorySelect from "src/Modules/Talent/Pages/TalentProfile/AssosiatedWorkHistorySelect";
import Dropzone from "react-dropzone";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";

const Container = styled.div``;

const RowLines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NewLineInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }
`;

const RowLineSpacer = styled.div`
  height: 8px;
`;

const hasEmptyLine = (values: Array<any>) => {
  if (values == undefined) {
    return false;
  }

  for (const [key, value] of Object.entries(values)) {
    if (value == "") {
      return true;
    }
  }

  return false;
};

const UploadContainer = styled.div`
  height: auto;
  width: 100%;
  cursor: pointer;
`;

const DeleteAction = styled.div`
  cursor: pointer;
  color: red;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.42px;
  margin-right: auto;
`;

const isUploadedUrl = (url: string) => {
  if (url?.includes("us-west-2")) {
    return true;
  }
  return false;
};

const ProjectEditForm: React.FC = (props) => {
  let formProps = props.formProps;
  let values = formProps.values;
  return (
    <>
      <FormField>
        <Label>Project Name</Label>
        <Field name={"name"} component={TextInputField} />
      </FormField>
      <FormField>
        <Label>Position Held</Label>
        <Field name={"positionHeld"} component={TextInputField} />
      </FormField>
      <FormField>
        <Label>Project URL</Label>
        <Field name={"url"} component={TextInputField} />
      </FormField>
      <FormField>
        <Label>Associated with</Label>
        <Field
          name={"associatedWorkHistory"}
          component={AssosiatedWorkHistorySelect}
          options={props.profile.workHistories.map((i) => {
            return {
              label: i.data.companyName,
              value: i.id,
              url: i.data.companyWebsite,
              companyWebsite: i.data.companyWebsite,
            };
          })}
        />
      </FormField>
      <FormField>
        <Label>Skills</Label>
        <Field name={"skills"} component={CommonMultipleSkillsSelect} />
      </FormField>
      <FormField>
        <Label>Description</Label>
        <Field name={"description"} component={TextareaField} />
      </FormField>
      <FormField>
        <Dropzone
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
          }}
          onDrop={(acceptedFiles) => {
            let fileReader = new FileReader();

            let data = new FormData();
            data.append("file", acceptedFiles[0]);

            formProps.form.change("uploadedScreenshot", acceptedFiles[0]);
            formProps.form.change("deleteUploadedScreenshot", false);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <UploadContainer {...getRootProps()}>
                <ProjectEditScreenshot values={values} />
                <input {...getInputProps()} />
              </UploadContainer>
              {(values.uploadedScreenshot ||
                isUploadedUrl(values.screenshotUrl)) &&
                !values.deleteUploadedScreenshot && (
                  <DeleteAction
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      formProps.form.change("uploadedScreenshot", null);
                      formProps.form.change("deleteUploadedScreenshot", true);
                    }}
                  >
                    Remove Uploaded Screenshot
                  </DeleteAction>
                )}
            </>
          )}
        </Dropzone>
      </FormField>
    </>
  );
};

export default ProjectEditForm;
