import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  background: #ededf0;
  height: 48px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const Tab = styled(NavLink)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex: 1;
  max-width: 200px;

  &.active {
    color: #1c274a;

    background: white;
    border-radius: 6px;
  }
`;

type Props = {
  links: { label: string; to: string }[];
};

const HorizontalToggleNavigation: React.FC = (props) => {
  let links = props.links;
  return (
    <Container>
      {links.map((link, i) => {
        return <Tab to={link.to}>{link.label}</Tab>;
      })}
    </Container>
  );
};

export default HorizontalToggleNavigation;
