import React, { useState } from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import SectionEditCard from "src/Modules/Talent/Pages/TalentProfile/SectionEditCard";
import { Field, Form } from "react-final-form";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import EditLineIcon from "remixicon-react/EditLineIcon";

const Container = styled.div``;

const Summary = styled.div`
  color: var(--Notch-Neutral-900, #1c274a);
  position: relative;
  /* Notch/Body/M - Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
  max-width: 100%;
  word-break: break-word;
  white-space: break-spaces;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  color: var(--Notch-Neutral-900, #1c274a);

  /* Notch/Label/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
`;

const EditFooterLine = styled.div`
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin: 24px auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;
`;

const Empty = styled.div`
  color: #999;
  margin: 0 auto;
  font-size: 14px;
  font-style: italic;
`;

const EditAction = (props) => {
  let Container = styled.div`
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -20px;
    &:hover svg {
      color: red;
    }
  `;

  return (
    <Container {...props}>
      <EditLineIcon color={"gray"} size={18} />
    </Container>
  );
};

const SummarySection: React.FC = (props) => {
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();

  let values = props.values;
  let data = props.values;

  const mutation = useMutation({
    mutationFn: (data) =>
      axios.put(`/api/talent/${data.talent.id}/profile/summary`, data),
    onSuccess: (data) => {
      setEdit(false);
      props.onUpdate(data);
    },
  });

  let status = props.profileStrength?.sections?.summary?.status;
  let info = props.profileStrength?.sections?.summary?.info;

  return (
    <Form
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
      initialValues={values}
      render={({ handleSubmit, submitting, values, form }) => (
        <form onSubmit={handleSubmit}>
          <TalentProfileSectionCard
            title="Summary"
            status={status}
            info={info}
            messages={props.profileStrength.sections?.summary?.messages}
          >
            {edit && (
              <SectionEditCard>
                <FormField>
                  <Label>Write your summary</Label>
                  <Field
                    name={"summary"}
                    component={TextareaField}
                    style={{ height: "140px" }}
                  />
                </FormField>
                <EditFooterLine />
                <ButtonGroup>
                  <Button onClick={() => form.submit()} variant="primary">
                    Save
                  </Button>
                </ButtonGroup>
              </SectionEditCard>
            )}

            {!edit && (
              <Summary>
                <EditAction
                  onClick={() => {
                    setEdit(true);
                  }}
                />
                <span>{data.summary}</span>
                {data.summary == null && <Empty>No summary</Empty>}
              </Summary>
            )}
          </TalentProfileSectionCard>
        </form>
      )}
    />
  );
};

export default SummarySection;
