import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import AdminTable from "src/Modules/Admin/Components/AdminTable/AdminTable";
import { Column } from "react-table";
import CompanyCell from "src/Modules/Admin/Components/AdminTable/CompanyCell";
import arrayMutators from "final-form-arrays";
import axios from "axios";
import { Field, Form, FormSpy } from "react-final-form";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import CandidatesLegacySearch from "src/Modules/Admin/Components/Pages/ShortList/CandidatesLegacySearch";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import * as yup from "yup";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import ShortlistCandidateStatus from "src/Modules/Admin/Components/Pages/ShortList/Components/ShortlistCandidateStatus";
import JobStatusDropdown from "src/Modules/Customer/Components/Jobs/JobStatusDropdown";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import {
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import Loader2FillIcon from "remixicon-react/Loader2FillIcon";
import CompanyJobInfoLine from "src/Modules/Admin/Components/Pages/ShortList/Components/CompanyJobInfoLine";
import { format } from "date-fns";
import MailLineIcon from "remixicon-react/MailLineIcon";
import CandidateShortlistsStatusWithDetails from "src/Modules/Admin/Components/Pages/ShortList/CandidateShortlistsStatusWithDetails";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import CandidateStatusChangeAction from "src/Modules/Admin/Components/Pages/ShortList/Components/CandidateStatusChangeAction";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 24px;
  border-radius: 12px;

  width: 100%;
  min-height: 200px;

  margin-bottom: 32px;

  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const PrimaryButton = styled.button`
  height: 48px;
  width: max-content;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const TalentSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 600px;
  margin: 20px 0 20px auto;
  gap: 20px;
`;

const HeaderLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TalentColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`;

const TalentColumnNameEmail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;

  .name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #1c274a;
  }

  .email {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #6a7288;
  }
`;

const ProfilePicture = styled.img`
  width: 32px;
  aspect-ratio: 1;
  border-radius: 999px;
`;

const ContainerSelect = styled.div`
  width: 120px;
  height: 30px;
`;

const ActionList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
`;

const ActionItem = styled.div`
  width: 22px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 0.3s;

  &:hover svg {
    background: #f8fafc;
    cursor: pointer;
    color: #105cf7;
  }
`;

const schema = yup.object({
  talentsToAdd: yup.object().required(),
});

const FetchingMessage = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 320px;
  background: #e2e8f0;
  color: black;
  position: fixed;
  top: 12px;
  right: 0;
  border-radius: 12px 0 0 12px;
`;

const VersionTwo = styled.div`
  background: #0e52dd;
  color: white;
  font-family: Inter;
  font-size: 10px;
  font-weight: bold;
  border-radius: 5px;
  padding: 0 4px;
  letter-spacing: 0.4px;
`;

const ShortlistCandidates: React.FC = (props) => {
  const job = props.data;
  let queryClient = useQueryClient();
  let fetching = useIsFetching();
  const addCandidateMutation = useMutation({
    mutationFn: BackofficeApi.jobs.id(job.id).shortlist.addCandidate,
    onSuccess: (data, variables, context) => {
      if (data.data.status == "error") {
        alert(data.data.message);
        return;
      }
      queryClient.invalidateQueries(["jobs"]);
    },
    onError: (error) => {},
  });

  const addv2CandidateMutation = useMutation({
    mutationFn: (values) =>
      axios.post(
        `/api/matching/v1/jobs/${job.id}/shortlist/add-candidate-v2`,
        values
      ),
    onSuccess: (data, variables, context) => {
      if (data.data.status == "error") {
        alert(data.data.message);
        return;
      }
      queryClient.invalidateQueries(["jobs"]);
    },
    onError: (error) => {},
  });

  const Columns: Column<any>[] = [
    {
      Header: "Talent",
      accessor: (row) => (
        <TalentColumn>
          <ProfilePicture src={row.legacyProfile.profileBase} />
          <TalentColumnNameEmail>
            <span className="name">{row.legacyProfile.fullname}</span>
            <span className="email">{row.legacyProfile.email}</span>
          </TalentColumnNameEmail>
          {row.v2 && <VersionTwo>Talent App</VersionTwo>}
        </TalentColumn>
      ),
      width: 33,
    },
    {
      Header: "Shortlist Status",
      accessor: (row) => (
        <div style={{ display: "flex", gap: 12 }}>
          <CandidateShortlistsStatusWithDetails data={row} />
          {row.status != "Draft" && <CandidateStatusChangeAction id={row.id} />}
        </div>
      ),
    },
    {
      Header: "Confidence",
      accessor: (row) => (
        <ContainerSelect>
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={(values) => {
              BackofficeApi.shortlistCandidates
                .id(row.id)
                .updateConfidenceLevel(values);
            }}
            initialValues={row}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="confidenceLevel"
                  component={JobStatusDropdown}
                  options={[
                    { value: 1, label: "Low" },
                    { value: 2, label: "Medium" },
                    { value: 3, label: "High" },
                  ]}
                />
                <FormSpy
                  subscription={{ values: true, dirtyFields: true }}
                  onChange={(props) => {
                    if (props.dirtyFields?.confidenceLevel == true) {
                      form.submit();
                    }
                  }}
                ></FormSpy>
              </form>
            )}
          />
        </ContainerSelect>
      ),
    },
    {
      Header: "Published at",
      accessor: ({ publishedAt }) =>
        publishedAt
          ? format(new Date(publishedAt), "PPp")
          : "Not published yet",
    },
    {
      Header: "Actions",
      accessor: ({ id, email }) => (
        <ActionList>
          <ActionItem
            onClick={() => {
              BackofficeApi.shortlistCandidates
                .id(id)
                .refreshImport()
                .then((response) => {
                  queryClient.invalidateQueries(["jobs"]);
                });
            }}
          >
            <RefreshLineIcon size={18} />
          </ActionItem>
          <ActionItem
            onClick={() => {
              navigator.clipboard.writeText(email);
            }}
          >
            <MailLineIcon size={18} />
          </ActionItem>
        </ActionList>
      ),
    },
  ];

  let shortlist = job.shortlistedCandidates;

  return (
    <div>
      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={(values) => {
          const talent = values.talentsToAdd;
          addCandidateMutation.mutate(talent);
        }}
        initialValues={{}}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <HeaderLine>
              <TalentSearch>
                <Field
                  name={"talentsToAdd"}
                  component={CandidatesLegacySearch}
                  options={[]}
                />
                <Button
                  type="submit"
                  size="medium"
                  disabled={
                    !schema.isValidSync(values) ||
                    addCandidateMutation.isLoading
                  }
                >
                  Add to shortlist
                </Button>
              </TalentSearch>
            </HeaderLine>
          </form>
        )}
      />

      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={(values) => {
          addv2CandidateMutation.mutate(values);
        }}
        initialValues={{}}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <HeaderLine>
              <TalentSearch>
                <Field
                  name={"talentId"}
                  component={TextInputField}
                  options={[]}
                />
                <Button
                  type="submit"
                  size="medium"
                  disabled={addv2CandidateMutation.isLoading}
                >
                  Add to shortlist [new]
                </Button>
              </TalentSearch>
            </HeaderLine>
          </form>
        )}
      />

      <Card>
        <Title>{shortlist.length ?? 0} Candidates</Title>
        {fetching == 0 && (
          <AdminTable data={shortlist ?? []} columns={Columns} />
        )}

        {fetching > 0 && <Title>Updating...</Title>}
      </Card>
    </div>
  );
};

export default ShortlistCandidates;
