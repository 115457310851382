import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import Typography from "src/Modules/Core/Components/Atoms/Typography/Typography";
import Briefcase2LineIcon from "remixicon-react/Briefcase2LineIcon";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  scroll-snap-align: start;
  cursor: pointer;

  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #ededf0;

  width: 100%;
  height: max-content;

  &.active {
    border: 1px solid #4880f5;
  }
`;

const Facts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  gap: 8px;
`;
const Skills = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 6px;
  gap: 10px;

  background: #f4fbfd;
  border-radius: 6px;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1d7c93;
`;

const Fact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;

  padding: 0 6px;

  height: 24px;
  width: fit-content;

  background: #fafafb;
  border-radius: 6px;
`;

const Upper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Location = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #6a7288;
`;

const ProfileContainer = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type Props = {
  selected: string | null;
  setSelected: () => void;
  data: object;
};

const NavigationCard: React.FC<Props> = (props) => {
  const { data, selected, setSelected } = props;
  return (
    <Container
      className={selected == data.id ? "active" : ""}
      onClick={() => {
        setSelected(data.id);
      }}
    >
      <Upper>
        <ProfileContainer>
          <AccountProfileAvatar size={56} name={data.name} />
        </ProfileContainer>
        <ProfileDetails>
          <Typography type="LabelMedium">{data.name}</Typography>
          <Typography type="BodyMediumRegular">{data.title}</Typography>
          <Location>{data.location}</Location>
        </ProfileDetails>
      </Upper>
      <Facts>
        <Fact>
          <CompanyLogo
            size={18}
            src={`https://logo.clearbit.com/${data.companyUrl}`}
          />
          <Typography type="BodyMediumRegular">{data.company}</Typography>
        </Fact>
        <Fact>
          <Briefcase2LineIcon size={18} color="#444" />
          <Typography type="BodyMediumRegular">{data.years} years</Typography>
        </Fact>
      </Facts>
      <Skills>
        {data.skills.map((i) => {
          return <Skill>{i}</Skill>;
        })}
      </Skills>
    </Container>
  );
};

export default NavigationCard;
