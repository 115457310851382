import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 4px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 6px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 12px;
`;

const IconContainer = styled.div`
  aspect-ratio: 1;
  width: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
`;

const Description = styled.div`
  color: var(--Notch-Neutral-900, #1c274a);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
`;

const WarningContainer = styled(MessageContainer)`
  background: #fff8e2;
`;

const AlertContainer = styled(MessageContainer)`
  background: #ffeaec;
`;

const TipContainer = styled(MessageContainer)`
  background: #eff6ff;
`;

type Props = {
  messages: Array<{ type: "warning" | "alert" | "tip"; description: string }>;
};

const ProfileMessageBanner: React.FC<Props> = (props) => {
  const messages = props.messages;

  const warnings = messages.filter((message) => message.type === "warning");
  const alerts = messages.filter((message) => message.type === "alert");
  const tips = messages.filter((message) => message.type === "tip");

  if (messages.length == 0) {
    return null;
  }

  return (
    <Container>
      {alerts.length > 0 && (
        <AlertContainer>
          {alerts.map((alert) => (
            <Message>
              <IconContainer>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1163_5152)">
                    <path
                      d="M10.7216 2.50013L18.66 16.2501C18.7331 16.3768 18.7716 16.5205 18.7716 16.6668C18.7716 16.8131 18.7331 16.9568 18.66 17.0834C18.5868 17.2101 18.4817 17.3153 18.355 17.3885C18.2283 17.4616 18.0846 17.5001 17.9383 17.5001H2.06164C1.91537 17.5001 1.77167 17.4616 1.64499 17.3885C1.51831 17.3153 1.41311 17.2101 1.33997 17.0834C1.26683 16.9568 1.22833 16.8131 1.22833 16.6668C1.22833 16.5205 1.26684 16.3768 1.33998 16.2501L9.27831 2.50013C9.35145 2.37345 9.45665 2.26826 9.58333 2.19513C9.71001 2.122 9.8537 2.0835 9.99998 2.0835C10.1463 2.0835 10.2899 2.122 10.4166 2.19513C10.5433 2.26826 10.6485 2.37345 10.7216 2.50013ZM9.16664 13.3335V15.0001H10.8333V13.3335H9.16664ZM9.16664 7.50013V11.6668H10.8333V7.50013H9.16664Z"
                      fill="#E32525"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1163_5152">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </IconContainer>
              <Description>{alert.description}</Description>
            </Message>
          ))}
        </AlertContainer>
      )}
      {warnings.length > 0 && (
        <WarningContainer>
          {warnings.map((warning) => (
            <Message>
              <IconContainer>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1163_5157)">
                    <path
                      d="M6.61753 15C6.37003 13.9391 5.25336 13.0716 4.79503 12.5C4.01019 11.5194 3.51832 10.337 3.37608 9.08912C3.23384 7.8412 3.44703 6.57849 3.99107 5.44643C4.53512 4.31438 5.38789 3.35904 6.45116 2.69047C7.51443 2.02189 8.74493 1.66728 10.0009 1.66748C11.2569 1.66768 12.4873 2.02269 13.5504 2.6916C14.6134 3.36052 15.4659 4.31613 16.0096 5.44836C16.5533 6.58059 16.766 7.84337 16.6234 9.09124C16.4808 10.3391 15.9885 11.5213 15.2034 12.5016C14.745 13.0725 13.63 13.94 13.3825 15H6.61669H6.61753ZM13.3334 16.6666V17.5C13.3334 17.942 13.1578 18.3659 12.8452 18.6785C12.5326 18.9911 12.1087 19.1666 11.6667 19.1666H8.33336C7.89133 19.1666 7.46741 18.9911 7.15485 18.6785C6.84229 18.3659 6.66669 17.942 6.66669 17.5V16.6666H13.3334ZM10.8334 8.33748V4.99998L7.08336 10.0041H9.16669V13.3375L12.9167 8.33748H10.8334Z"
                      fill="#FBB32B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1163_5157">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </IconContainer>
              <Description>{warning.description}</Description>
            </Message>
          ))}
        </WarningContainer>
      )}
      {tips.length > 0 && (
        <TipContainer>
          {tips.map((warning) => (
            <Message>
              <IconContainer>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1163_5157)">
                    <path
                      d="M6.61753 15C6.37003 13.9391 5.25336 13.0716 4.79503 12.5C4.01019 11.5194 3.51832 10.337 3.37608 9.08912C3.23384 7.8412 3.44703 6.57849 3.99107 5.44643C4.53512 4.31438 5.38789 3.35904 6.45116 2.69047C7.51443 2.02189 8.74493 1.66728 10.0009 1.66748C11.2569 1.66768 12.4873 2.02269 13.5504 2.6916C14.6134 3.36052 15.4659 4.31613 16.0096 5.44836C16.5533 6.58059 16.766 7.84337 16.6234 9.09124C16.4808 10.3391 15.9885 11.5213 15.2034 12.5016C14.745 13.0725 13.63 13.94 13.3825 15H6.61669H6.61753ZM13.3334 16.6666V17.5C13.3334 17.942 13.1578 18.3659 12.8452 18.6785C12.5326 18.9911 12.1087 19.1666 11.6667 19.1666H8.33336C7.89133 19.1666 7.46741 18.9911 7.15485 18.6785C6.84229 18.3659 6.66669 17.942 6.66669 17.5V16.6666H13.3334ZM10.8334 8.33748V4.99998L7.08336 10.0041H9.16669V13.3375L12.9167 8.33748H10.8334Z"
                      fill="#1d4ed8"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1163_5157">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </IconContainer>
              <Description>{warning.description}</Description>
            </Message>
          ))}
        </TipContainer>
      )}
    </Container>
  );
};

export default ProfileMessageBanner;
