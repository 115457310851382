const options = {
  workHistoryYears: Array(100)
    .fill(0)
    .map((_, i) => ({
      value: 2024 - i,
      label: 2024 - i,
    })),
  readyIn: [
    { label: "Immediately", value: "Immediately" },
    { label: "1 week", value: "1 week" },
    { label: "2+ weeks", value: "2 weeks" },
    {
      label: "More than a month",
      value: "More than a month",
    },
  ],
  jobSearchingStatus: [
    {
      label: "Actively Looking",
      value: "Active",
    },
    {
      label: "Open to Offers",
      value: "Open",
    },
    {
      label: "Not Available",
      value: "Unavailable",
    },
  ],
  experienceYears: [
    { label: "1+ years", value: "1" },
    { label: "2+ years", value: "2" },
    { label: "3+ years", value: "3" },
    { label: "4+ years", value: "4" },
    { label: "5+ years", value: "5" },
    { label: "6+ years", value: "6" },
    { label: "7+ years", value: "7" },
    { label: "8+ years", value: "8" },
    { label: "9+ years", value: "9" },
    { label: "10+ years", value: "10" },
    { label: "11+ years", value: "11" },
    { label: "12+ years", value: "12" },
    { label: "13+ years", value: "13" },
    { label: "14+ years", value: "14" },
    { label: "15+ years", value: "15" },
  ],
  languages: [],
  primaryRole: [
    {
      value: "Fullstack",
      label: "Fullstack Engineer",
    },
    {
      value: "Backend",
      label: "Backend Engineer",
    },
    {
      value: "Frontend",
      label: "Frontend Engineer",
    },
    {
      value: "DevOps",
      label: "DevOps Engineer",
    },
    {
      value: "Mobile",
      label: "Mobile Engineer",
    },
    { value: "Data", label: "Data Engineer" },
  ],
  seniority: [
    { label: "Middle", value: "Middle" },
    { label: "Senior", value: "Senior" },
    { label: "Lead", value: "Lead" },
    { label: "Staff", value: "Staff" },
    { label: "Principal", value: "Principal" },
  ],
  workingHours: [
    { value: "0", label: "12:00 am" },
    { value: "1", label: "1:00 am" },
    { value: "2", label: "2:00 am" },
    { value: "3", label: "3:00 am" },
    { value: "4", label: "4:00 am" },
    { value: "5", label: "5:00 am" },
    { value: "6", label: "6:00 am" },
    { value: "7", label: "7:00 am" },
    { value: "8", label: "8:00 am" },
    { value: "9", label: "9:00 am" },
    { value: "10", label: "10:00 am" },
    { value: "11", label: "11:00 am" },
    { value: "12", label: "12:00 pm" },
    { value: "13", label: "1:00 pm" },
    { value: "14", label: "2:00 pm" },
    { value: "15", label: "3:00 pm" },
    { value: "16", label: "4:00 pm" },
    { value: "17", label: "5:00 pm" },
    { value: "18", label: "6:00 pm" },
    { value: "19", label: "7:00 pm" },
    { value: "20", label: "8:00 pm" },
    { value: "21", label: "9:00 pm" },
    { value: "22", label: "10:00 pm" },
    { value: "23", label: "11:00 pm" },
  ],
  hiringType: [
    { value: "Employee", label: "Employee" },
    { value: "Contractor", label: "Contractor" },
    { value: "Either", label: "Either" },
  ],
  workplace: [
    { value: "Remote", label: "Remote" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "On-site", label: "On-site" },
  ],
  markets: [
    { label: "Analytics", value: "Analytics" },
    {
      label: "Finance and Accounting",
      value: "Finance and Accounting",
    },
    {
      label: "Human Resources",
      value: "Human Resources",
    },
    {
      label: "Infrastructure",
      value: "Infrastructure",
    },
    { label: "Legal", value: "Legal" },
    {
      label: "Office Management",
      value: "Office Management",
    },
    { label: "Operations", value: "Operations" },
    {
      label: "Productivity",
      value: "Productivity",
    },
    {
      label: "Recruiting and Talent",
      value: "Recruiting and Talent",
    },
    { label: "Retail", value: "Retail" },
    {
      label: "Sales & Marketing",
      value: "Sales & Marketing",
    },
    { label: "Security", value: "Security" },
    {
      label: "Supply Chain & logistics",
      value: "Supply Chain & logistics",
    },
    { label: "Education", value: "Education" },
    {
      label: "Apparel and Cosmetics",
      value: "Apparel and Cosmetics",
    },
    {
      label: "Consumer Electronics",
      value: "Consumer Electronics",
    },
    { label: "Content", value: "Content" },
    {
      label: "Food and Beverage",
      value: "Food and Beverage",
    },
    { label: "Gaming", value: "Gaming" },
    {
      label: "Home and Personal",
      value: "Home and Personal",
    },
    {
      label: "Job and Career Services",
      value: "Job and Career Services",
    },
    {
      label: "Social Media",
      value: "Social Media",
    },
    {
      label: "Transportation",
      value: "Transportation",
    },
    { label: "Travel", value: "Travel" },
    { label: "VR / AR", value: "VR / AR" },
    {
      label: "Asset Management",
      value: "Asset Management",
    },
    { label: "Banking", value: "Banking" },
    {
      label: "Consumer Finance",
      value: "Consumer Finance",
    },
    {
      label: "Credit & lending",
      value: "Credit & lending",
    },
    { label: "Insurance", value: "Insurance" },
    { label: "Payments", value: "Payments" },
    {
      label: "Health and Wellness",
      value: "Health and Wellness",
    },
    { label: "Diagnostics", value: "Diagnostics" },
    { label: "Healthcare", value: "Healthcare" },
    {
      label: "Construction",
      value: "Construction",
    },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Automotive", value: "Automotive" },
    {
      label: "Aviation and Space",
      value: "Aviation and Space",
    },
    { label: "Climate", value: "Climate" },
    { label: "Drones", value: "Drones" },
    { label: "Energy", value: "Energy" },
    {
      label: "Manufacturing & Robotics",
      value: "Manufacturing & Robotics",
    },
    {
      label: "Sports & Entertainment",
      value: "Sports & Entertainment",
    },
    {
      label: "Crypto / Blockchain",
      value: "Crypto / Blockchain",
    },
    { label: "Big Data", value: "Big Data" },
    {
      label: "Collaboration",
      value: "Collaboration",
    },
    {
      label: "Developer tools",
      value: "Developer tools",
    },
    {
      label: "Communication",
      value: "Communication",
    },
    { label: "On-demand", value: "On-demand" },
    {
      label: "Artificial Intelligence (AI)",
      value: "Artificial Intelligence (AI)",
    },
    { label: "SEO", value: "SEO" },
    {
      label: "Business Intelligence",
      value: "Business Intelligence",
    },
    {
      label: "Digital media",
      value: "Digital media",
    },
  ],
  companyStages: [
    { value: "Stage 1", label: "Stage 1" },
    { value: "Stage 2", label: "Stage 2" },
    { value: "Stage 3", label: "Stage 3" },
    { value: "Stage 4", label: "Stage 4" },
  ],
};

export default options;
