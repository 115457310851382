import React from "react";
import styled from "styled-components";
import Card from "src/Modules/Recruiter/Pages/Home/Card";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form } from "react-final-form";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import Dropzone from "react-dropzone";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";
import JobTimezoneField from "src/Modules/Customer/Components/Jobs/JobTimezoneField";
import Timezones, {
  timezoneOptions,
} from "src/Modules/Shared/Components/Temp/timezones";
import languages from "src/Modules/Shared/Components/Temp/languages";
import Lock2LineIcon from "remixicon-react/Lock2LineIcon";
import Lock2FillIcon from "remixicon-react/Lock2FillIcon";
import { FormContent } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";

const Container = styled.div`
  width: 760px;
  max-width: 94vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;

  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const NonPublic = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #858b9d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  width: 100%;

  /* Notch/Neutral/50 */

  background: #fafafb;
  border-radius: 12px;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const UploadLogoButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  height: 32px;

  border: 1px solid #105cf7;
  border-radius: 6px;
  background: white;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
`;

const RemoveAction = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const OverlappingHoursOption = [
  { value: "0", label: "12:00 am" },
  { value: "1", label: "1:00 am" },
  { value: "2", label: "2:00 am" },
  { value: "3", label: "3:00 am" },
  { value: "4", label: "4:00 am" },
  { value: "5", label: "5:00 am" },
  { value: "6", label: "6:00 am" },
  { value: "7", label: "7:00 am" },
  { value: "8", label: "8:00 am" },
  { value: "9", label: "9:00 am" },
  { value: "10", label: "10:00 am" },
  { value: "11", label: "11:00 am" },
  { value: "12", label: "12:00 pm" },
  { value: "13", label: "1:00 pm" },
  { value: "14", label: "2:00 pm" },
  { value: "15", label: "3:00 pm" },
  { value: "16", label: "4:00 pm" },
  { value: "17", label: "5:00 pm" },
  { value: "18", label: "6:00 pm" },
  { value: "19", label: "7:00 pm" },
  { value: "20", label: "8:00 pm" },
  { value: "21", label: "9:00 pm" },
  { value: "22", label: "10:00 pm" },
  { value: "23", label: "11:00 pm" },
];

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CancelButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

const BasicInfoEditForm: React.FC = (props) => {
  const queryClient = useQueryClient();

  let profile = props.profile;

  const mutation = useMutation({
    mutationFn: (values) =>
      axios.put(`/api/talent/${props.talentId}/profile/basic-info`, values),
    onSuccess: (data) => {
      queryClient.setQueryData(["talent", "profile"], data);
      queryClient.invalidateQueries(["talents"]);
      props.onClose();
    },
  });

  const pictureMutation = useMutation({
    mutationFn: (values) =>
      axios.post(
        `/api/talent/${props.talentId}/profile/profile-picture`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["profile"]);
      queryClient.invalidateQueries(["auth"]);
      props.onClose();
    },
  });

  const pictureDeleteMutation = useMutation({
    mutationFn: () =>
      axios.delete(`/api/talent/${props.talentId}/profile/profile-picture`),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["profile"]);
      queryClient.invalidateQueries(["auth"]);
    },
  });

  return (
    <Form
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
      initialValues={profile}
      keepDirtyOnReinitialize={true}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <CloseIcon
              onClick={() => {
                props.onClose();
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_36_4546)">
                  <path
                    d="M16.0001 14.8217L20.1251 10.6967L21.3034 11.875L17.1784 16L21.3034 20.125L20.1251 21.3033L16.0001 17.1783L11.8751 21.3033L10.6968 20.125L14.8218 16L10.6968 11.875L11.8751 10.6967L16.0001 14.8217Z"
                    fill="#B8BCC7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_4546">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(6 6)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </CloseIcon>
            <Row>
              <Title>Edit Profile Intro</Title>
            </Row>
            <PageTitleLine />

            <FormContainer>
              <Dropzone
                accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg", ".jpeg"],
                }}
                onDrop={(acceptedFiles) => {
                  let fileReader = new FileReader();

                  let data = new FormData();
                  data.append("file", acceptedFiles[0]);

                  pictureMutation.mutate(data);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <CenterRow>
                    <UploadContainer {...getRootProps()}>
                      <AccountProfileAvatar
                        size={72}
                        name={props.profile?.firstName}
                        profilePictureUrl={
                          props.profile?.account?.profilePicture
                        }
                      />
                      <UploadLogoButton
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        Upload picture
                      </UploadLogoButton>
                    </UploadContainer>
                    {props.profile?.account?.profilePicture != null && (
                      <RemoveAction
                        onClick={() => {
                          pictureDeleteMutation.mutate();
                        }}
                      >
                        Delete
                      </RemoveAction>
                    )}
                    <input {...getInputProps()} />
                  </CenterRow>
                )}
              </Dropzone>
              <Row>
                <FormField>
                  <FormLabel>First Name</FormLabel>
                  <Field name={"firstName"} component={TextInputField} />
                </FormField>
                <FormField>
                  <FormLabel>Last Name</FormLabel>
                  <Field name={"lastName"} component={TextInputField} />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Location</FormLabel>
                  <Field name={"location"} component={LocationInputField} />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Primary Role</FormLabel>
                  <Field
                    name={"primaryRole"}
                    component={DefaultSelectField}
                    options={TalentProfileOptions.primaryRole}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Seniority</FormLabel>
                  <Field
                    name={"seniority"}
                    component={DefaultSelectField}
                    options={TalentProfileOptions.seniority}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Total Experience</FormLabel>
                  <Field
                    name={"engineeringExperienceYears"}
                    component={DefaultSelectField}
                    options={TalentProfileOptions.experienceYears}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Remote Experience</FormLabel>
                  <Field
                    name={"remoteExperienceYears"}
                    component={DefaultSelectField}
                    options={TalentProfileOptions.experienceYears}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Timezone</FormLabel>
                  <Field
                    name="timezone"
                    placeholder="Timezone"
                    component={DefaultSelectField}
                    options={timezoneOptions}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Working hours</FormLabel>

                  <Field
                    name="workingHoursStartTime"
                    placeholder="Start time"
                    component={DefaultSelectField}
                    options={OverlappingHoursOption}
                  />
                </FormField>
                <FormField>
                  <FormLabel style={{ color: "transparent" }}>.</FormLabel>
                  <Field
                    name="workingHoursEndTime"
                    placeholder="End time"
                    component={DefaultSelectField}
                    options={OverlappingHoursOption}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Languages</FormLabel>
                  <Field
                    name="languages"
                    placeholder="Select languages"
                    isMulti={true}
                    component={DefaultSelectField}
                    options={languages}
                  />
                </FormField>
              </Row>
            </FormContainer>
            <div style={{ height: 22 }} />
            {/*
            <Title>
              Identity
              <NonPublic>
                <Lock2FillIcon size={18} color="#858b9d" />
                <span>Non-public</span>
              </NonPublic>
            </Title>
            <div style={{ height: 22 }} />
            <FormContainer>
              <Row>
                <FormField>
                  <FormLabel>Pronouns</FormLabel>
                  <Field
                    name={"pronouns"}
                    placeholder="Choose your pronouns"
                    component={DefaultSelectField}
                    options={[
                      { label: "He/Him", value: "He/Him" },
                      { label: "She/Her", value: "She/Her" },
                      { label: "They/Them", value: "They/Them" },
                      { label: "Bark/Woof", value: "Bark/Woof" },
                      { label: "Xe/Xem", value: "Xe/Xem" },
                      { label: "I/Myself", value: "I/Myself" },
                    ]}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Gender Identity</FormLabel>
                  <Field
                    name={"genderIdentity"}
                    placeholder="Choose your gender identity"
                    component={DefaultSelectField}
                    options={[{ label: "So many of them", value: "xx" }]}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Race / Ethnicity</FormLabel>
                  <Field
                    name={"raceAndEthnicity"}
                    placeholder="Choose your race/ethnicity"
                    component={DefaultSelectField}
                    options={[{ label: "So many of them", value: "xx" }]}
                  />
                </FormField>
              </Row>
            </FormContainer>
            */}
            <ButtonGroup>
              <CancelButton
                onClick={(event) => {
                  event.preventDefault();
                  props.onClose();
                }}
              >
                Cancel
              </CancelButton>
              <Button
                type="submit"
                size="large"
                disabled={mutation.isPending}
                variant="primary"
              >
                Save
              </Button>
            </ButtonGroup>
          </Container>
        </form>
      )}
    />
  );
};

export default BasicInfoEditForm;
