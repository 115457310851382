import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px;
  border-radius: 12px;
  background: var(--Notch-Neutral-50, #fafafb);
  min-height: 100px;
`;

const SectionEditCard: React.FC = (props) => {
  return <Container>{props.children}</Container>;
};

export default SectionEditCard;
