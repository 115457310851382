import React, { useState } from "react";
import styled from "styled-components";
import {
  addDays,
  compareAsc,
  format,
  isMonday,
  parseISO,
  toDate,
} from "date-fns";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import TimeValuePopup from "src/Modules/Admin/Pages/Organization/TimeValuePopup";
import { GrayDotSeparator } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import { CardTitle } from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import { NavLink, useSearchParams } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px;
  border-radius: 6px;
  gap: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const CurrentRange = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;
  color: #1c274a;
  border: 1px solid #ededf0;
  padding: 2px 12px;
  border-radius: 6px;
`;

const Icon = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 24px;
  cursor: pointer;
`;

const IconDisabled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 24px;
`;

const ResponsiveScroll = styled.div`
  max-width: calc(100vw - 100px);
  overflow-y: hidden;
  position: relative;

  @media screen and (min-width: 1000px) {
    overflow-y: visible;
    width: 100%;
    max-width: unset;
  }
`;

const TimeTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: fit-content;

  @media screen and (min-width: 1000px) {
    overflow-y: visible;
    width: 100%;
    max-width: unset;
  }
`;

const StatCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

const StatTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #858b9d;
`;

const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;

  width: 100%;
  height: 96px;

  border: 1px solid #ededf0;
  border-radius: 6px;

  @media (min-width: 700px) {
    width: 49%;
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`;

const DoughnutContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 100%;
`;

const Graph = styled.div`
  height: 100%;
  aspect-ratio: 1;
  z-index: 1000;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatWorkHours = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const StatHoursLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const NextInvoiceLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;

  color: #009c64;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: flex-end;
`;

const LegendLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const Dot = styled.div`
  width: 12px;
  aspect-ratio: 1;
  border-radius: 99px;
`;

const DotWorkedHours = styled(Dot)`
  background: #105cf7;
`;

const DotMissing = styled(Dot)`
  background: #78a3fa;
`;

const DotTimeOff = styled(Dot)`
  background: #c1d5fd;
`;

const LegendText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  color: #1c274a;
`;

const TimeColumn = styled.div``;

const ColumnHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 10px;
  color: #1c274a;
  gap: 2px;
  padding: 0px;
`;

const DayName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #858b9d;
`;

const DayNumber = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const TimeValueRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-bottom: 1px solid #ededf0;
`;

const HiredColumns = styled.div``;

const HiredName = styled.div`
  width: 165px;
  height: 24px;

  /* Notch/Body/M - Bold */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1c274a;
`;

const TableHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ededf0;
  width: 100%;
  height: 56px;
`;

const TableRowType = styled.div`
  margin: 0 20px;
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TypeWorked = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #105cf7;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 6px;

  background: #f8faff;
  border-radius: 6px;
`;

const TypeTimeOff = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #6a7288;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 6px;

  background: #fafafb;
  border-radius: 6px;
`;

const UserNameCell = styled(NavLink)`
  width: 188px;
  height: 56px;

  /* Notch/Body/M - Bold */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;
  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const DateCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  justify-content: center;

  height: 56px;
  width: 56px;

  @media screen and (min-width: 1000px) {
    flex: 1;
  }
`;

const TimeValue = styled.div`
  width: 100%;
  height: 24px;

  background: #105cf7;
  border-radius: 999px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.empty {
    background: #e6eefe;
  }

  &.timeOff {
        background: #e6eefe;
    color: #09348B;
    );
  }

  &.future {
    background: white;
    border: 1px dashed gray;
  }
`;

function parseISOLocal(s) {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2]);
}

const HSpan = styled.span`
  padding-left: 1px;
`;

const Loading = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: #0f172a;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const GrayLine = styled.div`
  width: 100%;
  height: 1px;
  background: #e6eefe;
`;

const renderTimeOffData = (data) => {
  let today = new Date();

  if (!data.active) {
    return <TimeValue className="future" />;
  }

  // If the value is in the future
  if (compareAsc(today, parseISO(data.date)) == -1) {
    return <TimeValue className="future" />;
  }

  if (data.timeOffHours > 0) {
    return <TimeValue className={"timeOff"}>8h</TimeValue>;
  }

  if (data.timeOffHours == 0) {
    return <TimeValue className={"timeOff"}></TimeValue>;
  }

  return null;
};

const renderHourData = (data) => {
  let today = new Date();

  if (!data.active) {
    return <TimeValue className="future" />;
  }

  if (data.workedHours > 0) {
    return (
      <TimeValuePopup data={data}>
        <TimeValue>
          {data.workedHours}
          <HSpan>h</HSpan>
        </TimeValue>
      </TimeValuePopup>
    );
  }

  if (data.timeOffHours > 0) {
    return (
      <TimeValuePopup data={data}>
        <TimeValue className={"timeOff"}>{data.timeOffHours}h</TimeValue>
      </TimeValuePopup>
    );
  }

  // If it's weekend day
  if (data.isWeekend == true) {
    return <TimeValue className={"empty"} />;
  }

  return (
    <TimeValuePopup data={data}>
      <TimeValue className="timeOff">{data.workedHours ?? 0}h</TimeValue>
    </TimeValuePopup>
  );
};

const OrganizationTimeSheet: React.FC = (props) => {
  let organizationId = props.organizationId;

  let current = "current";
  const [searchParams, setSearchParams] = useSearchParams({
    queryDate: "current",
  });

  if (searchParams.get("queryDate") != "current") {
    current = searchParams.get("queryDate");
  }

  let query = useQuery({
    queryFn: () =>
      axios.get(`/api/organizations/v1/${organizationId}/hires/timesheet`, {
        params: { query: current },
      }),
    queryKey: ["organizations", organizationId, "hires", "timesheet", current],
    placeholderData: (previousData, previousQuery) => previousData,
  });

  let hires = props.hires;

  if (query.isLoading) {
    return null;
  }

  if (query.data?.data?.result == null) {
    return null;
  }
  let data = query.data?.data?.result;

  if (data?.stats == null) {
    return null;
  }

  let stat = {
    totalWorkHours: data?.stats.totalWorkHours,
    incompleteHorus: data?.stats.incompleteHours,
    totalOffHours: data?.stats.totalOffHours,
  };

  let selectedHire = null;

  if (props.selectedHire) {
    selectedHire = props.hires.find((i) => {
      return i.id == props.selectedHire;
    });
  }

  if (selectedHire) {
    let singleTimeSheet = data[selectedHire.nikabotUserId];
    stat = {
      totalWorkHours: singleTimeSheet.total.totalWorkHours,
      incompleteHorus: singleTimeSheet.total.totalIncomplete,
      totalOffHours: singleTimeSheet.total.totalTimeOffHours,
    };
  }

  return (
    <Container>
      <Card>
        <Title>Overview</Title>
        <GrayLine />
        <StatCards>
          <StatCard>
            <StatTitle>Total Worked Hours</StatTitle>
            <Line>
              <StatWorkHours>{stat.totalWorkHours} </StatWorkHours>
              <StatHoursLabel>hours</StatHoursLabel>
            </Line>
          </StatCard>
          <StatCard>
            <StatTitle>Total Incomplete</StatTitle>
            <Line>
              <StatWorkHours>
                {selectedHire
                  ? stat.incompleteHorus
                  : data?.stats.incompleteHours}
              </StatWorkHours>
              <StatHoursLabel>hours</StatHoursLabel>
            </Line>
          </StatCard>
          <StatCard>
            <StatTitle>Next Invoice</StatTitle>
            <NextInvoiceLabel>{data?.stats.nextInvoice}</NextInvoiceLabel>
          </StatCard>
          <StatCard>
            <DoughnutContainer>
              <Graph>
                <Doughnut
                  options={{
                    legend: false,
                    tooltips: {
                      enabled: false,
                    },
                    cutout: "60%",
                    borderWidth: 0,
                    backgroundColor: ["#105cf7", "#78A3FA", "#C1D5FD"],
                  }}
                  data={{
                    datasets: [
                      {
                        data: [
                          stat.totalWorkHours,
                          stat.incompleteHorus,
                          stat.totalOffHours,
                        ],
                      },
                    ],
                  }}
                />
              </Graph>
              <Legend>
                <LegendLine>
                  <DotWorkedHours />
                  <LegendText>Work hours</LegendText>
                </LegendLine>
                <LegendLine>
                  <DotMissing />
                  <LegendText>Missing</LegendText>
                </LegendLine>
                <LegendLine>
                  <DotTimeOff />
                  <LegendText>Time-off</LegendText>
                </LegendLine>
              </Legend>
            </DoughnutContainer>
          </StatCard>
        </StatCards>
      </Card>
      <Card>
        {selectedHire && (
          <UserNameCell to={`..`} relative="path">
            <AccountProfileAvatar
              profilePictureUrl={selectedHire.profilePictureUrl}
              name={selectedHire.nikabotUserDetails.name}
            />
            {selectedHire.nikabotUserDetails.name}
          </UserNameCell>
        )}
        <Row>
          <CurrentRange>{data.nav.cycleLabel}</CurrentRange>
          {data.nav.previousCycle ? (
            <Icon to={"?queryDate=" + data.nav.previousCycle}>
              <ArrowLeftSLineIcon
                onClick={() => {
                  // setFilterStartDate(addDays(filterStartDate, -14));
                }}
                size={20}
                color={"#105CF7"}
              />
            </Icon>
          ) : (
            <IconDisabled>
              <ArrowLeftSLineIcon
                onClick={() => {
                  // setFilterStartDate(addDays(filterStartDate, -14));
                }}
                size={20}
                color={"#ccc"}
              />
            </IconDisabled>
          )}

          {data.nav.nextCycle ? (
            <Icon to={"?queryDate=" + data.nav.nextCycle}>
              <ArrowRightSLineIcon
                onClick={() => {
                  //  setFilterStartDate(addDays(filterStartDate, 14));
                }}
                size={20}
                color={"#105CF7"}
              />
            </Icon>
          ) : (
            <IconDisabled>
              <ArrowRightSLineIcon
                onClick={() => {
                  //  setFilterStartDate(addDays(filterStartDate, 14));
                }}
                size={20}
                color={"#ccc"}
              />
            </IconDisabled>
          )}

          {query.isFetching && <Loading>Loading...</Loading>}
        </Row>
        {!query.isLoading && (
          <ResponsiveScroll>
            <TimeTable>
              <TableHeaderRow>
                {!selectedHire && <UserNameCell></UserNameCell>}
                {selectedHire && <TableRowType></TableRowType>}
                {data.nav.tableHeader.map((i) => {
                  return (
                    <DateCell>
                      <DayName>{i.dayName}</DayName>
                      <DayNumber>{i.dayNumber}</DayNumber>
                    </DateCell>
                  );
                })}
                <DateCell>
                  <DayName>Tot</DayName>
                  <DayNumber>-</DayNumber>
                </DateCell>
              </TableHeaderRow>
              {!selectedHire &&
                hires.map((hire) => {
                  let rowData = data[hire.nikabotUserId];
                  let timesheet = rowData.timesheet;
                  return (
                    <TableHeaderRow>
                      <UserNameCell to={`${hire.id}`}>
                        <AccountProfileAvatar
                          profilePictureUrl={hire.profilePictureUrl}
                          name={hire.nikabotUserDetails.name}
                        />
                        {hire.nikabotUserDetails.name}
                      </UserNameCell>
                      {Object.keys(timesheet).map((i) => {
                        return (
                          <DateCell>{renderHourData(timesheet[i])}</DateCell>
                        );
                      })}
                      <DateCell>
                        <TimeValue>
                          {rowData?.total?.totalWorkHours}
                          <HSpan>h</HSpan>
                        </TimeValue>
                      </DateCell>
                    </TableHeaderRow>
                  );
                })}
              {selectedHire && (
                <>
                  <TableHeaderRow>
                    <TableRowType className="worked">
                      <TypeWorked>Worked</TypeWorked>
                    </TableRowType>
                    {Object.keys(
                      data[selectedHire.nikabotUserId].timesheet
                    ).map((i) => {
                      return (
                        <DateCell>
                          {renderHourData(
                            data[selectedHire.nikabotUserId].timesheet[i]
                          )}
                        </DateCell>
                      );
                    })}
                    <DateCell>
                      <TimeValue>
                        {stat.totalWorkHours}
                        <HSpan>h</HSpan>
                      </TimeValue>
                    </DateCell>
                  </TableHeaderRow>

                  <TableHeaderRow>
                    <TableRowType className="worked">
                      <TypeTimeOff>Time-off</TypeTimeOff>
                    </TableRowType>
                    {Object.keys(
                      data[selectedHire.nikabotUserId].timesheet
                    ).map((i) => {
                      return (
                        <DateCell>
                          {renderTimeOffData(
                            data[selectedHire.nikabotUserId].timesheet[i]
                          )}
                        </DateCell>
                      );
                    })}
                    <DateCell>
                      <TimeValue>
                        {stat.totalOffHours}
                        <HSpan>h</HSpan>
                      </TimeValue>
                    </DateCell>
                  </TableHeaderRow>
                </>
              )}
            </TimeTable>
          </ResponsiveScroll>
        )}
      </Card>
    </Container>
  );
};

export default OrganizationTimeSheet;
