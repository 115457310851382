import React from "react";
import styled from "styled-components";
import {
  Card,
  CardTitle,
  SeparationLink,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";
import GovernmentLineIcon from "remixicon-react/GovernmentLineIcon";
import Text from "src/Modules/Shared/Components/Atom/Text/Text";

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #7dd3fc;
  border-radius: 6px;
`;

const EducationName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const EducationDegree = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Years = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;
`;

const Dot = styled.div`
  aspect-ratio: 1;
  width: 3px;
  background: #d2d4db;
  border-radius: 99px;
`;

const Period = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;
`;

const Education: React.FC = (props) => {
  let items = props.data.Education;

  return (
    <Card>
      <CardTitle>Education</CardTitle>
      <List>
        {items.map((i: any) => {
          return (
            <>
              <Item>
                <Icon>
                  <GovernmentLineIcon size={20} />
                </Icon>
                <Content>
                  <EducationName>{i.University}</EducationName>
                  <Period>
                    <Years>{i.Degree}</Years>
                    <Dot />
                    <Years>{i.Description}</Years>
                  </Period>
                  <Years>
                    {i.From} - {i.To}
                  </Years>
                </Content>
              </Item>
              <SeparationLink />
            </>
          );
        })}
      </List>
    </Card>
  );
};

export default Education;
