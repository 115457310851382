import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 999px;
  background: var(--Notch-Secondary-Orange-50, #fff8e2);

  &.blue {
    background: var(--Notch-Primary-Blue-50, #f8faff);
  }
`;

const Value = styled.div`
  height: 100%;
  border-radius: 999px;
  background: var(--Notch-Secondary-Orange-600, #fbb32b);
  transition: 1.3s;

  &.green {
    background: green;
  }

  &.blue {
    background: var(--Notch-Primary-Blue-700, #0e52dd);
  }
`;

const ProgressBar: React.FC = (props) => {
  let completion = props.value;

  let color = "yellow";

  if (props.profile?.active == true) {
    color = "blue";
  }

  return (
    <Container className={color}>
      <Value style={{ width: `${completion}%` }} className={color} />
    </Container>
  );
};

export default ProgressBar;
