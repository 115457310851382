import React, { useState } from "react";
import styled from "styled-components";
import Card from "src/Modules/Recruiter/Pages/Home/Card";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form } from "react-final-form";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import Dropzone from "react-dropzone";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";
import JobTimezoneField from "src/Modules/Customer/Components/Jobs/JobTimezoneField";
import Timezones, {
  timezoneOptions,
} from "src/Modules/Shared/Components/Temp/timezones";
import languages from "src/Modules/Shared/Components/Temp/languages";
import Lock2LineIcon from "remixicon-react/Lock2LineIcon";
import Lock2FillIcon from "remixicon-react/Lock2FillIcon";
import { FormContent } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import SalaryInputWithIcon from "src/Modules/Customer/Components/Jobs/SalaryInputWithIcon";
import AddFillIcon from "remixicon-react/AddFillIcon";
import BlockSelectField from "src/Modules/Shared/Components/Temp/BlockSelectField";

const Container = styled.div`
  width: 760px;
  max-width: 94vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;

  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Header = styled(Title)`
  margin: 12px 0 4px 0;
`;

const NonPublic = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #858b9d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: 0 16px 0 0;
  gap: 24px;

  width: 100%;

  /* Notch/Neutral/50 */

  background: #fafafb;
  border-radius: 12px;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const UploadLogoButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  height: 32px;

  border: 1px solid #105cf7;
  border-radius: 6px;
  background: white;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
`;

const RemoveAction = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const OverlappingHoursOption = [
  { value: "0", label: "12:00 am" },
  { value: "1", label: "1:00 am" },
  { value: "2", label: "2:00 am" },
  { value: "3", label: "3:00 am" },
  { value: "4", label: "4:00 am" },
  { value: "5", label: "5:00 am" },
  { value: "6", label: "6:00 am" },
  { value: "7", label: "7:00 am" },
  { value: "8", label: "8:00 am" },
  { value: "9", label: "9:00 am" },
  { value: "10", label: "10:00 am" },
  { value: "11", label: "11:00 am" },
  { value: "12", label: "12:00 pm" },
  { value: "13", label: "1:00 pm" },
  { value: "14", label: "2:00 pm" },
  { value: "15", label: "3:00 pm" },
  { value: "16", label: "4:00 pm" },
  { value: "17", label: "5:00 pm" },
  { value: "18", label: "6:00 pm" },
  { value: "19", label: "7:00 pm" },
  { value: "20", label: "8:00 pm" },
  { value: "21", label: "9:00 pm" },
  { value: "22", label: "10:00 pm" },
  { value: "23", label: "11:00 pm" },
];

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CancelButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

const InfoLine = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #6a7288;

  margin-top: -2px;
`;

const SetMinimumAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.03em;

  color: #105cf7;

  gap: 6px;
  margin-bottom: 4px;

  cursor: pointer;
`;

const WorkPrefencesEditForm: React.FC = (props) => {
  const queryClient = useQueryClient();

  let profile = props.profile;
  const [showMinimumRate, setShowMinimumRate] = useState(
    profile.minimumRate != null
  );

  const mutation = useMutation({
    mutationFn: (values) =>
      axios.put(
        `/api/talent/${profile.talent.id}/profile/work-preferences`,
        values
      ),
    onSuccess: (data) => {
      queryClient.setQueryData(["talent", "profile"], data);
      props.onClose();
    },
  });

  return (
    <Form
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
      initialValues={profile}
      keepDirtyOnReinitialize={true}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <CloseIcon
              onClick={() => {
                props.onClose();
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_36_4546)">
                  <path
                    d="M16.0001 14.8217L20.1251 10.6967L21.3034 11.875L17.1784 16L21.3034 20.125L20.1251 21.3033L16.0001 17.1783L11.8751 21.3033L10.6968 20.125L14.8218 16L10.6968 11.875L11.8751 10.6967L16.0001 14.8217Z"
                    fill="#B8BCC7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_4546">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(6 6)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </CloseIcon>
            <Row>
              <Title>
                Work Preferences
                <NonPublic>
                  <Lock2FillIcon size={18} color="#858b9d" />
                  <span>Non-public</span>
                </NonPublic>
              </Title>
            </Row>
            <PageTitleLine />
            <Header>Role & Company</Header>
            <FormContainer>
              <Row>
                <FormField>
                  <FormLabel>Roles</FormLabel>
                  <Field
                    name={"preferredRoles"}
                    component={DefaultSelectField}
                    placeholder={"Select roles"}
                    options={TalentProfileOptions.primaryRole}
                    isMulti={true}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Job Type</FormLabel>
                  <Field
                    name="jobType"
                    component={DefaultSelectField}
                    options={[
                      { label: "Full-time", value: "Full-time" },
                      { label: "Part-time", value: "Part-time" },
                      {
                        label: "Either",
                        value: "Either",
                      },
                    ]}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Hiring Type</FormLabel>
                  <Field
                    name="preferredHiringType"
                    component={DefaultSelectField}
                    isMulti={false}
                    options={TalentProfileOptions.hiringType}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Seniority Level</FormLabel>
                  <Field
                    name="seniority"
                    component={DefaultSelectField}
                    isMulti={false}
                    options={TalentProfileOptions.seniority}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Workplace</FormLabel>
                  <Field
                    name="workplace"
                    component={DefaultSelectField}
                    isMulti={false}
                    options={TalentProfileOptions.workplace}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Markets</FormLabel>
                  <Field
                    name={"preferredMarkets"}
                    component={DefaultSelectField}
                    isMulti={true}
                    options={TalentProfileOptions.markets}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <FormLabel>Company Stage</FormLabel>
                  <Field
                    name={"preferredCompanyStage"}
                    component={DefaultSelectField}
                    isMulti={true}
                    options={TalentProfileOptions.companyStages}
                  />
                </FormField>
              </Row>
            </FormContainer>
            <Header>Salary & Availability</Header>

            <FormContainer>
              <Row>
                <FormField>
                  <FormLabel>Expected Rate</FormLabel>
                  <Field
                    name={"expectedRate"}
                    component={SalaryInputWithIcon}
                    icon={
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#F8FAFF" />
                        <path
                          d="M11.593 18.2727V5.54545H12.4083V18.2727H11.593ZM14.3373 9.36364C14.2776 8.85985 14.0357 8.46875 13.6114 8.19034C13.1872 7.91193 12.6668 7.77273 12.0503 7.77273C11.5996 7.77273 11.2052 7.84564 10.8671 7.99148C10.5323 8.13731 10.2705 8.33783 10.0816 8.59304C9.89598 8.84825 9.80318 9.13826 9.80318 9.46307C9.80318 9.73485 9.86781 9.96851 9.99707 10.1641C10.1296 10.3563 10.2987 10.517 10.5042 10.6463C10.7097 10.7723 10.9251 10.8767 11.1505 10.9595C11.3759 11.0391 11.583 11.1037 11.7719 11.1534L12.806 11.4318C13.0712 11.5014 13.3662 11.5975 13.691 11.7202C14.0191 11.8428 14.3323 12.0102 14.6306 12.2223C14.9322 12.4311 15.1808 12.6996 15.3763 13.0277C15.5719 13.3558 15.6697 13.7585 15.6697 14.2358C15.6697 14.786 15.5255 15.2831 15.2371 15.7273C14.9521 16.1714 14.5345 16.5244 13.9843 16.7862C13.4374 17.0481 12.7729 17.179 11.9907 17.179C11.2615 17.179 10.6301 17.0613 10.0965 16.826C9.5662 16.5907 9.14859 16.2625 8.84366 15.8416C8.54205 15.4207 8.37136 14.9318 8.33159 14.375H9.60431C9.63746 14.7595 9.76672 15.0777 9.9921 15.3295C10.2208 15.5781 10.5091 15.7637 10.8572 15.8864C11.2085 16.0057 11.5863 16.0653 11.9907 16.0653C12.4613 16.0653 12.8839 15.9891 13.2584 15.8366C13.633 15.6809 13.9296 15.4654 14.1483 15.1903C14.3671 14.9119 14.4765 14.5871 14.4765 14.2159C14.4765 13.8778 14.382 13.6027 14.1931 13.3906C14.0042 13.1785 13.7556 13.0062 13.4474 12.8736C13.1391 12.741 12.806 12.625 12.4481 12.5256L11.1952 12.1676C10.3998 11.9389 9.77003 11.6125 9.30602 11.1882C8.842 10.764 8.61 10.2088 8.61 9.52273C8.61 8.95265 8.76412 8.45549 9.07235 8.03125C9.38391 7.60369 9.80152 7.27225 10.3252 7.03693C10.8522 6.7983 11.4405 6.67898 12.0901 6.67898C12.7464 6.67898 13.3297 6.79664 13.8401 7.03196C14.3505 7.26397 14.7549 7.58215 15.0532 7.98651C15.3548 8.39086 15.5139 8.8499 15.5305 9.36364H14.3373Z"
                          fill="#105CF7"
                        />
                      </svg>
                    }
                  />
                  <InfoLine>
                    You will be prioritized for matching jobs that meet or
                    exceed your expected rate.
                  </InfoLine>
                </FormField>
              </Row>

              {showMinimumRate && (
                <Row>
                  <FormField>
                    <FormLabel>Minimum Rate</FormLabel>
                    <Field
                      name={"minimumRate"}
                      component={SalaryInputWithIcon}
                      icon={
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="24" height="24" rx="12" fill="#F8FAFF" />
                          <path
                            d="M11.593 18.2727V5.54545H12.4083V18.2727H11.593ZM14.3373 9.36364C14.2776 8.85985 14.0357 8.46875 13.6114 8.19034C13.1872 7.91193 12.6668 7.77273 12.0503 7.77273C11.5996 7.77273 11.2052 7.84564 10.8671 7.99148C10.5323 8.13731 10.2705 8.33783 10.0816 8.59304C9.89598 8.84825 9.80318 9.13826 9.80318 9.46307C9.80318 9.73485 9.86781 9.96851 9.99707 10.1641C10.1296 10.3563 10.2987 10.517 10.5042 10.6463C10.7097 10.7723 10.9251 10.8767 11.1505 10.9595C11.3759 11.0391 11.583 11.1037 11.7719 11.1534L12.806 11.4318C13.0712 11.5014 13.3662 11.5975 13.691 11.7202C14.0191 11.8428 14.3323 12.0102 14.6306 12.2223C14.9322 12.4311 15.1808 12.6996 15.3763 13.0277C15.5719 13.3558 15.6697 13.7585 15.6697 14.2358C15.6697 14.786 15.5255 15.2831 15.2371 15.7273C14.9521 16.1714 14.5345 16.5244 13.9843 16.7862C13.4374 17.0481 12.7729 17.179 11.9907 17.179C11.2615 17.179 10.6301 17.0613 10.0965 16.826C9.5662 16.5907 9.14859 16.2625 8.84366 15.8416C8.54205 15.4207 8.37136 14.9318 8.33159 14.375H9.60431C9.63746 14.7595 9.76672 15.0777 9.9921 15.3295C10.2208 15.5781 10.5091 15.7637 10.8572 15.8864C11.2085 16.0057 11.5863 16.0653 11.9907 16.0653C12.4613 16.0653 12.8839 15.9891 13.2584 15.8366C13.633 15.6809 13.9296 15.4654 14.1483 15.1903C14.3671 14.9119 14.4765 14.5871 14.4765 14.2159C14.4765 13.8778 14.382 13.6027 14.1931 13.3906C14.0042 13.1785 13.7556 13.0062 13.4474 12.8736C13.1391 12.741 12.806 12.625 12.4481 12.5256L11.1952 12.1676C10.3998 11.9389 9.77003 11.6125 9.30602 11.1882C8.842 10.764 8.61 10.2088 8.61 9.52273C8.61 8.95265 8.76412 8.45549 9.07235 8.03125C9.38391 7.60369 9.80152 7.27225 10.3252 7.03693C10.8522 6.7983 11.4405 6.67898 12.0901 6.67898C12.7464 6.67898 13.3297 6.79664 13.8401 7.03196C14.3505 7.26397 14.7549 7.58215 15.0532 7.98651C15.3548 8.39086 15.5139 8.8499 15.5305 9.36364H14.3373Z"
                            fill="#105CF7"
                          />
                        </svg>
                      }
                    />
                    <InfoLine>
                      This is the least you’ll consider regardless of equity or
                      benefits. You won’t be matched with roles below this rate.
                    </InfoLine>
                  </FormField>
                </Row>
              )}

              {!showMinimumRate && (
                <Row>
                  <FormField>
                    <SetMinimumAction onClick={() => setShowMinimumRate(true)}>
                      <AddFillIcon color={"#105cf7"} size={18} />
                      <span>Set a minimum rate</span>
                    </SetMinimumAction>
                    <InfoLine>
                      Match with roles offering less than expected salary but
                      with valuable equity or benefits.
                    </InfoLine>
                  </FormField>
                </Row>
              )}
              <Row>
                <FormField>
                  <FormLabel>Working hours</FormLabel>

                  <Field
                    name="workingHoursStartTime"
                    placeholder="Start time"
                    component={DefaultSelectField}
                    options={OverlappingHoursOption}
                  />
                </FormField>
                <FormField>
                  <FormLabel style={{ color: "transparent" }}>.</FormLabel>
                  <Field
                    name="workingHoursEndTime"
                    placeholder="End time"
                    component={DefaultSelectField}
                    options={OverlappingHoursOption}
                  />
                </FormField>
              </Row>

              <Row>
                <FormField>
                  <FormLabel>Ready to start in</FormLabel>
                  <Field
                    name="readyToWorkIn"
                    component={DefaultSelectField}
                    options={TalentProfileOptions.readyIn}
                  />
                </FormField>
              </Row>
            </FormContainer>

            <ButtonGroup>
              <CancelButton
                onClick={(event) => {
                  event.preventDefault();
                  props.onClose();
                }}
              >
                Cancel
              </CancelButton>
              <Button
                type="submit"
                size="large"
                disabled={mutation.isPending}
                variant="primary"
              >
                Save
              </Button>
            </ButtonGroup>
          </Container>
        </form>
      )}
    />
  );
};

export default WorkPrefencesEditForm;
