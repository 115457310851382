import React, { useState } from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import Typography from "src/Modules/Core/Components/Atoms/Typography/Typography";
import { GrayDotSeparator } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import { style } from "@vanilla-extract/css";
import shortlistData from "./shortlistData";
import NavigationCard from "src/Modules/Admin/Pages/ExperimentalShortlist/NavigationCard";
import CandidateSingleView from "src/Modules/Admin/Pages/ExperimentalShortlist/CandidateSingleView";

const Container = styled.div`
  background: yellow;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 64px;
  width: 100%;
  background: white;
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ededf0;
`;

const Role = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Content = styled.div`
  display: grid;

  grid-template-columns: 380px 1fr;

  width: 100%;
  height: 100%;
`;

const Navigation = styled.div`
  height: calc(100vh - 64px);
  width: 380px !important;
  display: flex;
  flex-direction: column;
  background: #fafafb;
  border-right: 1px solid #ededf0;
`;

const SelectedView = styled.div`
  width: 100%;
  height: 100%;
  background: #fafafb;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-padding: 8px;
`;

const ExperimentalShortlistPage: React.FC = (props) => {
  const [selected, setSelected] = useState<string | null>(null);

  const data = shortlistData;

  return (
    <Container>
      <Header>
        <Role>
          <CompanyLogo size={32} src={`https://logo.clearbit.com/airbnb.com`} />
          <Typography type={"LabelMedium"}>Airbnb</Typography>
          <GrayDotSeparator />
          <Typography type="LabelMedium">Software Engineer</Typography>
        </Role>
      </Header>
      <Content>
        <Navigation>
          <List>
            {data.map((item, index) => (
              <NavigationCard
                selected={selected}
                setSelected={setSelected}
                data={item}
                key={item.id}
              />
            ))}
          </List>
        </Navigation>
        <SelectedView>
          <CandidateSingleView id={selected} />
        </SelectedView>
      </Content>
    </Container>
  );
};

export default ExperimentalShortlistPage;
