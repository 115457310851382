import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { GrayDotSeparator } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import ImageWithFallback from "src/Modules/Shared/Components/Util/ImageWithFallback";
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: fit-content;
`;

const Logo = styled.div`
  width: 40px;
  aspect-ratio: 1;
`;

const Name = styled.div`
  /* Notch/Label/Small */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const Text = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const Years = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/600 */
  color: #6a7288;
`;

const Url = styled.a`
  font-family: Inter;
  font-size: 14px;
  color: #0e52dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const EducationEntry: React.FC = (props) => {
  let data = props.data;
  let url = "";
  try {
    url = new URL(data.credentialUrl).hostname;
  } catch (e) {}

  return (
    <Container>
      <Logo>
        <ImageWithFallback
          size={40}
          src={`https://logo.clearbit.com/${url}`}
          fallback={
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="6" fill="#EDEDF0" />
              <path
                d="M19.9997 15.8335C21.7678 15.8335 23.4635 16.5359 24.7137 17.7861C25.964 19.0364 26.6663 20.7321 26.6663 22.5002C26.6663 24.2683 25.964 25.964 24.7137 27.2142C23.4635 28.4645 21.7678 29.1668 19.9997 29.1668C18.2316 29.1668 16.5359 28.4645 15.2856 27.2142C14.0354 25.964 13.333 24.2683 13.333 22.5002C13.333 20.7321 14.0354 19.0364 15.2856 17.7861C16.5359 16.5359 18.2316 15.8335 19.9997 15.8335ZM19.9997 17.5002C18.6736 17.5002 17.4018 18.027 16.4641 18.9646C15.5265 19.9023 14.9997 21.1741 14.9997 22.5002C14.9997 23.8263 15.5265 25.098 16.4641 26.0357C17.4018 26.9734 18.6736 27.5002 19.9997 27.5002C21.3258 27.5002 22.5975 26.9734 23.5352 26.0357C24.4729 25.098 24.9997 23.8263 24.9997 22.5002C24.9997 21.1741 24.4729 19.9023 23.5352 18.9646C22.5975 18.027 21.3258 17.5002 19.9997 17.5002ZM19.9997 18.7502L21.1022 20.9835L23.5663 21.3418L21.783 23.0793L22.2038 25.5343L19.9997 24.3752L17.7955 25.5335L18.2163 23.0793L16.433 21.341L18.8972 20.9827L19.9997 18.7502ZM24.9997 11.6668V14.1668L23.8638 15.1152C22.9213 14.6207 21.8922 14.3125 20.833 14.2077V11.6668H24.9997ZM19.1663 11.666V14.2077C18.1075 14.3124 17.0787 14.6202 16.1363 15.1143L14.9997 14.1668V11.6668L19.1663 11.666Z"
                fill="#9FA4B3"
              />
            </svg>
          }
        />
      </Logo>
      <Info>
        <Name>{data.name}</Name>
        <Line>
          <Text>Issued by {data.issuingOrganization}</Text>
        </Line>
        <Years>
          {mapToLabel(data.issueDateMonth, [
            { value: 1, label: "January" },
            { value: 2, label: "February" },
            { value: 3, label: "March" },
            { value: 4, label: "April" },
            { value: 5, label: "May" },
            { value: 6, label: "June" },
            { value: 7, label: "July" },
            { value: 8, label: "August" },
            { value: 9, label: "September" },
            { value: 10, label: "October" },
            { value: 11, label: "November" },
            { value: 12, label: "December" },
          ])?.substring(0, 3)}{" "}
          {data.issueDateYear}
        </Years>
        {data.credentialUrl && (
          <Url
            href={
              data.credentialUrl?.includes("http")
                ? data.credentialUrl
                : "//" + data.credentialUrl
            }
            target={"_blank"}
          >
            {data.credentialUrl}
            <ExternalLinkLineIcon size={14} color={"#0e52dd"} />
          </Url>
        )}
      </Info>
    </Container>
  );
};

export default EducationEntry;
