import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import TalentStage from "src/Modules/Admin/Pages/Talents/TalentStage";
import { CircleFlag } from "react-circle-flags";
import ConditionalRender from "src/Modules/Talent/Pages/TalentProfile/ConditionalRender";
import MailOpenLineIcon from "remixicon-react/MailOpenLineIcon";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";
import AsRelativeTime from "src/Modules/Shared/Components/AsRelativeTime";
import { format } from "date-fns";

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  background: white;
  border-radius: 16px;

  padding: 24px;

  gap: 30px;

  display: flex;
  flex-direction: column;
`;

const UpperSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 12px;
`;

const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NameAndStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;

  gap: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Location = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  line-height: 1;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const Email = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LowerSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  height: 120px;

  padding: 12px;

  &:not(:last-child) {
    border-right: 1px solid #f0f0f0;
  }
`;

const InfoBoxLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #858b9d;
  margin-bottom: 16px;
`;

const InfoBoxValue = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const InfoBoxSubValue = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #858b9d;
`;

const IdContent = styled.div`
  font-family: Monaco;
  font-size: 15px;
  font-weight: 500;

  background: lightcyan;
  border-radius: 6px;
  padding: 0px 12px;
  margin-left: 20px;
`;

const ProfileStatusDraft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: "Inter";
  font-size: 12px;

  padding: 0px 12px;

  min-width: 20px;

  width: fit-content;
  border-radius: 6px;

  height: 24px;

  color: #334155;
  border: 1px solid #334155;
`;

const HiringStatus = styled.div``;

const TalentManagementOverviewHeader: React.FC = (props) => {
  let talentId = props.talentId;

  let query = useQuery(BackofficeApi.talents.id(talentId).get());

  if (query.isLoading) {
    return null;
  }

  let profile = query.data?.data.talentProfile;
  let talent = query.data?.data;

  return (
    <Container>
      <UpperSection>
        <ProfilePictureContainer>
          <AccountProfileAvatar
            size={72}
            name={profile.account?.firstName ?? profile.fullname ?? "X"}
            profilePictureUrl={profile.account?.profilePicture}
          />
        </ProfilePictureContainer>
        <ProfileInfo>
          <NameAndStatus>
            <span>{profile.fullname}</span>
            <TalentStage stage={talent.stage} />
            <IdContent>{talentId}</IdContent>
          </NameAndStatus>
          <ConditionalRender condition={profile.location}>
            <Location>
              <CircleFlag
                countryCode={profile.location?.countryCode}
                height={16}
              />
              <span>{profile.location?.label}</span>
            </Location>
          </ConditionalRender>
          <Email>
            <MailOpenLineIcon size={15} color={"#6A7288"} />
            <span>{talent.account?.email}</span>
          </Email>
        </ProfileInfo>
      </UpperSection>
      <LowerSection>
        <InfoBox>
          <InfoBoxLabel>Salary</InfoBoxLabel>
          <ConditionalRender condition={profile.expectedRate}>
            <InfoBoxValue>${profile.expectedRate} /yr</InfoBoxValue>
          </ConditionalRender>
          <ConditionalRender condition={!profile.expectedRate}>
            <InfoBox>-</InfoBox>
          </ConditionalRender>
          <ConditionalRender condition={profile.minimumRate}>
            <InfoBoxSubValue>${profile.minimumRate} /yr</InfoBoxSubValue>
          </ConditionalRender>
          <ConditionalRender condition={!profile.minimumRate}>
            <InfoBoxSubValue>-</InfoBoxSubValue>
          </ConditionalRender>
        </InfoBox>
        <InfoBox>
          <InfoBoxLabel>Availability</InfoBoxLabel>
          <ConditionalRender condition={profile.jobType}>
            <InfoBoxValue>{profile.jobType}</InfoBoxValue>
          </ConditionalRender>
          <ConditionalRender condition={profile.readyToWorkIn}>
            <InfoBoxSubValue>Ready in: {profile.readyToWorkIn}</InfoBoxSubValue>
          </ConditionalRender>
        </InfoBox>
        <InfoBox>
          <InfoBoxLabel>Profile</InfoBoxLabel>
          <ProfileStatusDraft>Draft</ProfileStatusDraft>
        </InfoBox>
        <InfoBox>
          <InfoBoxLabel>Job Search Status</InfoBoxLabel>
          <ConditionalRender condition={profile.hiringStatus == "Active"}>
            <InfoBoxValue>Actively Looking</InfoBoxValue>
          </ConditionalRender>
          <ConditionalRender condition={profile.hiringStatus == "Open"}>
            <InfoBoxValue>Open to opportunities</InfoBoxValue>
          </ConditionalRender>
          <ConditionalRender condition={profile.hiringStatus == "Unavailable"}>
            <InfoBoxValue>Not available</InfoBoxValue>
          </ConditionalRender>
        </InfoBox>
        <InfoBox>
          <InfoBoxLabel>Last Activity at</InfoBoxLabel>
        </InfoBox>
      </LowerSection>
    </Container>
  );
};

export default TalentManagementOverviewHeader;
