import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { style } from "@vanilla-extract/css";
import styled from "styled-components";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import AddFillIcon from "remixicon-react/AddFillIcon";

const Container = styled.div`
  cursor: pointer;
  border-radius: 6px;

  &.isDragging {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  }
`;

const Hold = styled.div`
  width: 20px;
  height: 20px;
  background: red;
`;

const SkillName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1d7c93;
`;

const SkillYears = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;

  width: fit-content;
  height: 22px;

  background: #1d7c93;
  border-radius: 999px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  letter-spacing: -0.03em;

  color: #f4fbfd;
`;

const SkillContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: white;

  gap: 8px;

  height: 32px;
  padding: 0 12px;

  border-radius: 6px;
`;

const Star = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Remove = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const getSkillLabel = (code) => mapToLabel(code, skillOptions);

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export function SortableItem(props) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  if (transform) {
    transform.scaleY = 1;
    transform.scaleX = 1;
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 100 : 1,
    transition,
  };

  let type = props.type;
  let skill = props.skill;

  return (
    <Container
      ref={setNodeRef}
      style={style}
      className={isDragging ? "isDragging" : ""}
    >
      <SkillContainer>
        <Star
          onClick={() => {
            props.toggleIsTop();
          }}
        >
          {type == "top" && <CloseLineIcon size={18} color={"#1d7c93"} />}
          {type == "additional" && <AddFillIcon size={18} color={"#1d7c93"} />}
        </Star>
        <Flex {...attributes} {...listeners}>
          {type == "top" && <SkillName>{getSkillLabel(skill.skill)}</SkillName>}
          {type == "additional" && (
            <SkillName>{getSkillLabel(skill)}</SkillName>
          )}
          {type == "top" && <SkillYears>{skill.years}y</SkillYears>}
        </Flex>
      </SkillContainer>
    </Container>
  );
}
