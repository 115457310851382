import axiosInstance from "src/Modules/Core/Service/Api";
import axios from "axios";
import { ca } from "date-fns/locale";

export const BackofficeApi = {
  organizations: {
    list: (searchParams) => ({
      queryKey: ["organizations", "list", Object.fromEntries(searchParams)],
      queryFn: (x) => {
        return axiosInstance.get("/api/organizations/v1/list", {
          params: x.queryKey[2],
        });
      },
    }),
    create: (values: Record<string, any>) =>
      axiosInstance.post("/api/organizations/v1/list"),
    get: (organizationId: string) => ({
      single: () => ({
        queryKey: ["organizations", "get", organizationId],
        queryFn: () =>
          axiosInstance.get(`/api/organizations/v1/${organizationId}`),
      }),
      inviteOwner: (values) =>
        axiosInstance.post(
          `/api/organizations/v1/${organizationId}/invite-owner`,
          values
        ),
      getOwnerInvitations: () => ({
        queryKey: ["organizations", "get", organizationId, "owner-invitations"],
        queryFn: () =>
          axiosInstance.get(
            `/api/organizations/v1/${organizationId}/owner-invitations`
          ),
      }),

      survey: () => ({
        queryKey: ["organizations", "get", organizationId, "survey"],
        queryFn: () =>
          axiosInstance.get(`/api/organizations/v1/${organizationId}/survey`),
      }),
      details: {
        set: (values: object) =>
          axiosInstance.post(
            `/api/organizations/v1/${organizationId}/details`,
            values
          ),
        uploadLogo: (values: object) =>
          axiosInstance.post(
            `/api/organizations/v1/${organizationId}/details/logo`,
            values,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          ),
        removeLogo: (values: object) =>
          axiosInstance.delete(
            `/api/organizations/v1/${organizationId}/details/logo`,
            values
          ),
      },
      changeRepresentative: (values: Record<string, unknown>) =>
        axiosInstance.post(
          `/api/organizations/v1/${organizationId}/representative`,
          values
        ),
    }),
    inviteCodes: {
      list: (searchParams) => ({
        queryKey: [
          "organizations-invite-codes",
          "list",
          Object.fromEntries(searchParams),
        ],
        queryFn: ({ queryKey }) =>
          axiosInstance.get("/api/organizations/v1/invite-codes"),
      }),
      create: (values: Record<string, unknown>) =>
        axiosInstance.post("/api/organizations/v1/invite-codes", values),
      delete: (id: string) =>
        axiosInstance.delete(`/api/organizations/v1/invite-codes/${id}`),
    },
  },
  reps: {
    list: (searchParams) => ({
      queryKey: ["organization-reps", "list", Object.fromEntries(searchParams)],
      queryFn: (x: unknown) => {
        return axiosInstance.get("/api/backoffice/v1/reps", {
          params: x.queryKey[2],
        });
      },
    }),
    create: (values: Record<string, unknown>) =>
      axiosInstance.post("/api/backoffice/v1/reps", values),
  },
  searchCandidates: (query) =>
    axiosInstance.get("/api/matching/v1/search-candidates", {
      params: {
        query,
      },
    }),
  shortlistCandidates: {
    id: (candidateId) => ({
      updateConfidenceLevel: (values) =>
        axiosInstance.put(
          `/api/matching/v1/shortlisted-candidates/${candidateId}/confidenceLevel`,
          values
        ),
      updateStatus: (values) =>
        axiosInstance.put(
          `/api/matching/v1/shortlisted-candidates/${candidateId}/status`,
          values
        ),
      updateCandidate: (values) =>
        axiosInstance.put(
          `/api/matching/v1/shortlisted-candidates/${candidateId}`,
          values
        ),
      publish: () =>
        axiosInstance.post(
          `/api/matching/v1/shortlisted-candidates/${candidateId}/publish`
        ),
      get: () => ({
        queryKey: ["shortlisted-candidates", candidateId],
        queryFn: () =>
          axiosInstance.get(
            `/api/matching/v1/shortlisted-candidates/${candidateId}`
          ),
      }),
      refreshImport: () =>
        axiosInstance.post(
          `/api/matching/v1/shortlisted-candidates/${candidateId}/import`
        ),
      getProfile: () => ({
        queryKey: ["shortlisted-candidates", candidateId, "profile"],
        queryFn: () =>
          axiosInstance.get(
            `/api/matching/v1/shortlisted-candidates/${candidateId}/profile`
          ),
      }),
    }),
  },
  jobs: {
    list: (searchParams) => ({
      queryKey: ["jobs", "list", Object.fromEntries(searchParams)],
      queryFn: (x) => {
        return axiosInstance.get("/api/matching/v1/jobs", {
          params: x.queryKey[2],
        });
      },
    }),
    id: (jobId: string) => ({
      get: () => ({
        queryKey: ["jobs", "details", jobId],
        queryFn: () => axiosInstance.get(`/api/matching/v1/jobs/${jobId}`),
      }),
      attachOrganization: (values) =>
        axiosInstance.put(
          `/api/matching/v1/jobs/${jobId}/attach-organization`,
          values
        ),
      delete: () => axiosInstance.delete(`/api/matching/v1/jobs/${jobId}`),
      approve: () =>
        axiosInstance.put(`/api/matching/v1/jobs/${jobId}/approve`),
      reject: () => axiosInstance.put(`/api/matching/v1/jobs/${jobId}/reject`),
      shortlist: {
        addCandidate: (values) =>
          axiosInstance.post(
            `/api/matching/v1/jobs/${jobId}/shortlist/add-candidate`,
            values
          ),
      },
    }),
    create: () => axiosInstance.post("/api/matching/v1/jobs"),
  },
  accounts: {
    id: (accountId) => ({
      get: () => ({
        queryKey: ["accounts", "details", accountId],
        queryFn: () =>
          axiosInstance.get(`/api/backoffice/v1/accounts/${accountId}`),
      }),
    }),
    list: (searchParams) => ({
      queryKey: ["accounts", "list", Object.fromEntries(searchParams)],
      queryFn: (x) => {
        return axiosInstance.get("/api/backoffice/v1/accounts", {
          params: x.queryKey[2],
        });
      },
    }),
  },
  talents: {
    list: (searchParams) => ({
      queryKey: ["talents", "list", Object.fromEntries(searchParams)],
      queryFn: (x) => {
        return axiosInstance.get("/api/backoffice/talents/list", {
          params: x.queryKey[2],
        });
      },
    }),
    id: (talentId) => ({
      get: () => ({
        queryKey: ["talents", talentId],
        queryFn: () => axiosInstance.get(`/api/backoffice/talents/${talentId}`),
      }),
    }),
  },
};
