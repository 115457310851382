import React, { useState } from "react";
import styled from "styled-components";
import Card from "src/Modules/Recruiter/Pages/Home/Card";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import CurrentUserProfilePicture from "src/Modules/Shared/Components/Temp/CurrentUserProfilePicture";
import ConditionalRender from "src/Modules/Talent/Pages/TalentProfile/ConditionalRender";
import Briefcase2LineIcon from "remixicon-react/Briefcase2LineIcon";
import WifiLineIcon from "remixicon-react/WifiLineIcon";
import MapPinTimeLineIcon from "remixicon-react/MapPinTimeLineIcon";
import ChatVoiceLineIcon from "remixicon-react/ChatVoiceLineIcon";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import EditLineIcon from "remixicon-react/EditLineIcon";
import BasicInfoEditForm from "src/Modules/Talent/Pages/TalentProfile/BasicInfoEditForm";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";
import languages from "src/Modules/Talent/Pages/TalentProfile/languages";
import { CircleFlag } from "react-circle-flags";
import TalentHiringStatus from "src/Modules/Talent/Components/TalentHiringStatus";
import SalaryAvailabilityEditForm from "src/Modules/Talent/Pages/TalentProfile/SalaryAvailabilityEditForm";
import WorkPrefencesEditForm from "src/Modules/Talent/Pages/TalentProfile/WorkPreferencesEditForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  position: relative;
  margin-top: 20px;
`;

const UpperSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

const UpperTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;

  color: #1c274a;
  line-height: 1;
`;

const RoleTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1c274a;
  line-height: 1;
`;

const VerticalSeparator = styled.div`
  height: 18px;
  width: 1px;
  border-left: 2px solid #ededf0;
`;

const Location = styled.div`
  font-family: "Inter";
  font-style: normal;
  line-height: 1;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const LineSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: #ededf0;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const HiringStatusContainer = styled.div`
  width: 100%;
`;

const EditAction = styled.div`
  position: absolute;
  right: 0;
  top: -24px;
  cursor: pointer;
`;

const TextLine = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const IconSize = 18;
const IconColor = "#1C274A";

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const ExtraSectionLink = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;

  width: 100%;
  height: 41px;

  background: #fafafb;
  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  color: #1c274a;

  cursor: pointer;
`;

const ArrowLink = styled.div`
  margin-left: auto;
`;

const BasicInfoCard: React.FC = (props) => {
  const [popup, setPopup] = useState(false);
  const [salaryPopup, setSalaryPopup] = useState(false);
  const [workPopup, setWorkPopup] = useState(false);

  let profile = props.profile;

  if (profile == null) {
    return null;
  }

  let talentId = props.talentId;

  return (
    <>
      <Card>
        <Container>
          <EditAction>
            <EditLineIcon
              size={18}
              color={"#555"}
              onClick={() => setPopup(true)}
            />
          </EditAction>
          <PopupOverlay active={popup} onClose={() => setPopup(false)}>
            {({ onClose }) => (
              <BasicInfoEditForm
                talentId={talentId}
                profile={profile}
                onClose={onClose}
              />
            )}
          </PopupOverlay>
          <UpperSection>
            <AccountProfileAvatar
              size={72}
              name={profile.firstName}
              profilePictureUrl={profile.account?.profilePicture}
            />
            <UpperTextSection>
              <Name>{profile.firstName}</Name>
              <ConditionalRender
                condition={profile.seniorityLevel || profile.primaryRole}
              >
                <RoleTitle>
                  {profile.seniority} <VerticalSeparator />{" "}
                  {profile.primaryRole}
                </RoleTitle>
              </ConditionalRender>
              <ConditionalRender condition={profile.location}>
                <Location>
                  <CircleFlag
                    countryCode={profile.location?.countryCode}
                    height={16}
                  />
                  <span>{profile.location?.label}</span>
                </Location>
              </ConditionalRender>
            </UpperTextSection>
          </UpperSection>
          <LineSeparator />
          <List>
            <ConditionalRender condition={profile.engineeringExperienceYears}>
              <TextLine>
                <Briefcase2LineIcon size={IconSize} color={IconColor} />

                <span>
                  {profile.engineeringExperienceYears} years of engineering
                  experience
                </span>
              </TextLine>
            </ConditionalRender>
            <ConditionalRender condition={profile.remoteExperienceYears}>
              <TextLine>
                <WifiLineIcon size={IconSize} color={IconColor} />

                <span>
                  {" "}
                  {profile.remoteExperienceYears} years of remote experience
                </span>
              </TextLine>
            </ConditionalRender>
            <ConditionalRender condition={profile.workingHoursStartTime}>
              <TextLine>
                <MapPinTimeLineIcon size={IconSize} color={IconColor} />
                <span>
                  {mapToLabel(
                    profile.workingHoursStartTime,
                    TalentProfileOptions.workingHours
                  )}{" "}
                  -{" "}
                  {mapToLabel(
                    profile.workingHoursEndTime,
                    TalentProfileOptions.workingHours
                  )}
                </span>
              </TextLine>
            </ConditionalRender>
            <ConditionalRender condition={profile.languages?.length > 0}>
              <TextLine>
                <ChatVoiceLineIcon size={IconSize} color={IconColor} />
                <span>
                  {profile.languages
                    .map((i) => mapToLabel(i, languages))
                    .join(", ")}
                </span>
              </TextLine>
            </ConditionalRender>
          </List>
          <HiringStatusContainer>
            <TalentHiringStatus value={profile.hiringStatus} />
          </HiringStatusContainer>
          <LineSeparator />

          <ExtraSectionLink onClick={() => setWorkPopup(true)}>
            <span>Work Preferences</span>

            <ArrowLink>
              <svg
                width="6"
                height="9"
                viewBox="0 0 6 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.00002 4.50016L1.75702 8.74316L0.343018 7.32816L3.17202 4.50016L0.343018 1.67216L1.75702 0.257164L6.00002 4.50016Z"
                  fill="#105CF7"
                />
              </svg>
            </ArrowLink>
          </ExtraSectionLink>
          <PopupOverlay
            active={salaryPopup}
            onClose={() => setSalaryPopup(false)}
          >
            {({ onClose }) => (
              <SalaryAvailabilityEditForm
                onClose={onClose}
                profile={profile}
                onUpdate={props.onUpdate}
              />
            )}
          </PopupOverlay>
          <PopupOverlay active={workPopup} onClose={() => setWorkPopup(false)}>
            {({ onClose }) => (
              <WorkPrefencesEditForm
                onClose={onClose}
                profile={profile}
                onUpdate={props.onUpdate}
              />
            )}
          </PopupOverlay>
        </Container>
      </Card>
    </>
  );
};

export default BasicInfoCard;
