import React, { useState } from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import Education from "src/Modules/Customer/Components/TalentProfile/Education";
import {
  AddAction,
  ButtonGroup,
  CancelEditAction,
  EditAction,
  EditFooterLine,
  EntryList,
  FormContent,
  DeleteAction,
  FormField,
  FormRow,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import AddFillIcon from "remixicon-react/AddFillIcon";
import SectionEditCard from "src/Modules/Talent/Pages/TalentProfile/SectionEditCard";
import { Field, Form } from "react-final-form";
import UniversitySelect from "src/Modules/Core/Components/Form/Select/UniversitySelect/UniversitySelect";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import { FormCard } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import EducationEntry from "src/Modules/Talent/Pages/TalentProfile/Sections/EducationEntry";
import TalentProfileSectionList from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionList";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";

const Container = styled.div``;

const SingleEdit = () => {};

const EducationSection: React.FC = (props) => {
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const [add, setAdd] = useState(false);
  const mutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `/api/talent/${props.profile.talent.id}/profile/educations`,
        data
      ),
    onSuccess: (data) => {
      setAdd(false);
      props.onUpdate(data);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `/api/talent/${props.profile.talent.id}/profile/educations/${data.id}`,
        data
      ),
    onSuccess: (data) => {
      setEdit(null);
      props.onUpdate(data);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (data) =>
      axios.delete(
        `/api/talent/${props.profile.talent.id}/profile/educations/${data.id}`
      ),
    onSuccess: (data) => {
      setEdit(null);
      props.onUpdate(data);
    },
  });

  let mutationPending =
    mutation.isPending || updateMutation.isPending || deleteMutation.isPending;

  let profile = props.profile;

  return (
    <TalentProfileSectionCard
      title="Education"
      status={props.profileStrength.sections?.education?.status}
      info={props.profileStrength.sections?.education?.info}
      messages={props.profileStrength.sections?.education?.messages}
    >
      <TalentProfileSectionList
        items={profile.educations}
        singleItemView={(item) => <EducationEntry data={item} />}
        createMutation={mutation}
        updateMutation={updateMutation}
        deleteMutation={deleteMutation}
        createItemLabel={
          <>
            <AddFillIcon size={20} />
            Add education
          </>
        }
        itemForm={(formProps) => (
          <>
            <FormField>
              <Label>University</Label>
              <Field
                name={"universityName"}
                component={TextInputField}
                options={[]}
              />
            </FormField>
            <FormField>
              <Label>Degree</Label>
              <Field name={"degree"} component={TextInputField} options={[]} />
            </FormField>
            <FormField>
              <Label>Field of Study</Label>
              <Field
                name={"fieldOfStudy"}
                component={TextInputField}
                options={[]}
              />
            </FormField>
            <FormRow>
              <FormField>
                <Label>Start Year</Label>
                <Field
                  name={"startYear"}
                  component={DefaultSelectField}
                  options={TalentProfileOptions.workHistoryYears}
                />
              </FormField>

              <FormField>
                <Label>End Year</Label>
                <Field
                  name={"endYear"}
                  component={DefaultSelectField}
                  options={TalentProfileOptions.workHistoryYears.filter((i) => {
                    let startYear = formProps.values.startYear;
                    if (startYear == null) {
                      return true;
                    }
                    return i.value >= startYear;
                  })}
                />
              </FormField>
            </FormRow>
          </>
        )}
      />
    </TalentProfileSectionCard>
  );
};

export default EducationSection;
