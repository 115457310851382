import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  CardTitle,
  SeparationLink,
  Skill,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import UserLocationLineIcon from "remixicon-react/UserLocationLineIcon";
import MapPin2LineIcon from "remixicon-react/MapPin2LineIcon";
import SeparatorIcon from "remixicon-react/SeparatorIcon";

const StandaloneContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const JobHeaderCompany = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const JobLogo = styled.div`
  width: 40px;
  aspect-ratio: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const JobRole = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const JobContent = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 1fr;
`;

const MultipleJobContentSpace = styled.div`
  width: 100%;
  height: 20px;
`;

const Period = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;
`;
const Dot = styled.div`
  aspect-ratio: 1;
  width: 3px;
  background: #d2d4db;
  border-radius: 99px;
`;

const Side = styled.div`
  width: 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  gap: 8px;
`;

const SideDot = styled.div`
  width: 8px;
  height: 8px;
  background: #d2d4db;
  border-radius: 99px;
`;

const SideLine = styled.div`
  height: 100%;
  width: 1px;
  background: #ededf0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 4px;
`;

const Highlights = styled.ul`
  padding-left: 16px;
`;

const Description = styled.pre`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  white-space: break-spaces;

  color: #1c274a;

  width: 100%;

  span {
    display: block;
  }

  ul {
    margin-top: 8px;
    padding-left: 24px;
  }
`;

const Highlight = styled.li`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const SkillLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-top: 8px;
`;

const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const ShowAll = styled.div`
  width: 100%;
  height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 20px;

  cursor: pointer;
`;

const ShowAllLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  width: 160px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ShowAllLine = styled.div`
  flex: 1;
  height: 1px;
  background: #ededf0;
`;

const MultipleJobSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const Standalone = (job: any) => {
  return (
    <StandaloneContainer>
      <JobHeaderCompany>
        <JobLogo>
          <CompanyLogo
            src={`https://logo.clearbit.com/${job.Logo}`}
            size={40}
          />
        </JobLogo>
        <CompanyName>{job.Company}</CompanyName>
      </JobHeaderCompany>
      <JobContent>
        <Side></Side>
        <Main>
          <JobRole>{job.Role}</JobRole>

          <>
            <Period>
              <span>Full-time</span>
              <Dot />
              <span>{job.Period}</span>
            </Period>
            {job.Location ? (
              <Period>
                <MapPin2LineIcon color={"#D2D4DB"} size={18} />
                <span>{job.Location}</span>
              </Period>
            ) : (
              <>
                {!job.Remote ? (
                  <>
                    {job.City && (
                      <Period>
                        <MapPin2LineIcon color={"#D2D4DB"} size={18} />
                        <span>
                          {job.City}, {job.Country}
                        </span>
                      </Period>
                    )}
                  </>
                ) : (
                  <Period>
                    <MapPin2LineIcon color={"#D2D4DB"} size={18} />
                    <span>Remote</span>
                  </Period>
                )}
              </>
            )}
          </>
          <Highlights>
            {job.Highlights.map((i) => {
              if (i.text == undefined) {
                return null;
              }
              return <Highlight>{i["text"]}</Highlight>;
            })}
          </Highlights>
          <Description>{job.RoleDescription}</Description>
          <SkillLabel>Skills</SkillLabel>
          <SkillList>
            {job.Technologies.map((skill) => {
              return <Skill>{skill}</Skill>;
            })}
          </SkillList>
        </Main>
      </JobContent>
    </StandaloneContainer>
  );
};

const Multiple = (jobs) => {
  let job = jobs[0];
  return (
    <StandaloneContainer>
      <JobHeaderCompany>
        <JobLogo>
          <CompanyLogo
            src={`https://logo.clearbit.com/${job.Logo}`}
            size={40}
          />
        </JobLogo>
        <CompanyName>{job.Company}</CompanyName>
      </JobHeaderCompany>
      {jobs.map((job, i) => {
        return (
          <JobContent>
            <Side>
              <SideDot />
              {i != jobs.length - 1 && <SideLine />}
            </Side>
            <Main>
              <JobRole>{job.Role}</JobRole>

              <>
                <Period>
                  <span>Full-time</span>
                  <Dot />
                  <span>{job.Period}</span>
                </Period>

                {job.Location ? (
                  <Period>
                    <MapPin2LineIcon color={"#D2D4DB"} size={18} />
                    <span>{job.Location}</span>
                  </Period>
                ) : (
                  <>
                    {!job.Remote ? (
                      <>
                        {job.City && (
                          <Period>
                            <MapPin2LineIcon color={"#D2D4DB"} size={18} />
                            <span>
                              {job.City}, {job.Country}
                            </span>
                          </Period>
                        )}
                      </>
                    ) : (
                      <Period>
                        <MapPin2LineIcon color={"#D2D4DB"} size={18} />
                        <span>Remote</span>
                      </Period>
                    )}
                  </>
                )}
              </>
              <Highlights>
                {job.Highlights.map((i) => {
                  if (i.text == undefined) {
                    return null;
                  }
                  return <Highlight>{i["text"]}</Highlight>;
                })}
              </Highlights>
              <SkillLabel>Skills</SkillLabel>
              <SkillList>
                {job.Technologies.map((skill) => {
                  return <Skill>{skill}</Skill>;
                })}
              </SkillList>
              <MultipleJobContentSpace />
            </Main>
          </JobContent>
        );
      })}
    </StandaloneContainer>
  );
};

const JobList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const WorkHistory: React.FC = (props) => {
  let [showAll, setShowAll] = useState(false);

  let jobs = props.data.GroupedEmployments;

  let displayedJobs = [];

  if (showAll) {
    displayedJobs = jobs;
  } else {
    displayedJobs = jobs.slice(0, 3);
  }

  return (
    <Card>
      <CardTitle>Work History</CardTitle>
      <JobList>
        {displayedJobs.map((i: any) => {
          if (i.length == 1) {
            return (
              <>
                {Standalone(i[0])}
                <SeparationLink />
              </>
            );
          } else {
            return (
              <>
                {Multiple(i)}
                <SeparationLink />
              </>
            );
          }
        })}
      </JobList>

      {jobs.length > 3 && !showAll && (
        <ShowAll onClick={() => setShowAll(true)}>
          <ShowAllLine />
          <ShowAllLabel>Show all work history</ShowAllLabel>
          <ShowAllLine />
        </ShowAll>
      )}
    </Card>
  );
};

export default WorkHistory;
