import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Card,
  SeparationLink,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";
import MapPin2LineIcon from "remixicon-react/MapPin2LineIcon";
import Briefcase2LineIcon from "remixicon-react/Briefcase2LineIcon";
import WifiLineIcon from "remixicon-react/WifiLineIcon";
import MapPinTimeLineIcon from "remixicon-react/MapPinTimeLineIcon";
import ChatVoiceLineIcon from "remixicon-react/ChatVoiceLineIcon";
import { is } from "date-fns/locale";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import languages from "src/Modules/Shared/Components/Temp/languages";
import { CircleFlag } from "react-circle-flags";
import countries from "src/Modules/Shared/Components/Temp/countries";
import Timer2LineIcon from "remixicon-react/Timer2LineIcon";
import BuildingLineIcon from "remixicon-react/BuildingLineIcon";

const Picture = styled.img`
  width: 72px;
  aspect-ratio: 1;
  height: 72px;
  border-radius: 999px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Role = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Address = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const PushRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
`;

const Container = styled.div`
  &.fixed {
    position: fixed;
    top: 100px;
    width: 320px;
  }

  @media screen and (min-width: 1280px) {
    &.fixed {
      position: fixed;
      top: 20px;
      width: 320px;
    }
  }
`;

const HoverText = styled.div`
  width: 200px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #ffffff;
  padding: 8px 12px;
  background: #1c274a;
  border-radius: 6px;

  bottom: 28px;
  position: absolute;
`;

const HoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

const getLanguageLabel = (value) => {
  let found = languages.find((i) => {
    return i.value == value;
  });

  return found ? found.label : "";
};

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const InfoLineIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
    color: #111;
  }
`;

const InfoLineText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;
`;

const Place = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const LeftCard: React.FC = (props) => {
  let [isFixed, setIsFixed] = useState(false);
  let [info, setInfo] = useState(false);

  useEffect(() => {
    let effect = () => {
      let element = document.getElementById("summary-card");

      if (window.innerWidth < 1280) {
        setIsFixed(false);
        return;
      }

      if (!element) {
        return;
      }

      let rect = element.getBoundingClientRect();

      if (rect.top > 20) {
        setIsFixed(false);
      }

      if (rect.top <= 20) {
        setIsFixed(true);
      }
    };

    window.addEventListener("scroll", effect, true);
    window.addEventListener("resize", effect, true);
    effect();

    return () => {
      window.removeEventListener("scroll", effect, true);
      window.removeEventListener("resize", effect, true);
    };
  }, []);

  let languages = props.data.Languages;
  let data = props.data;

  languages = languages.map((i) => {
    return getLanguageLabel(i);
  });

  languages = languages.join(", ");
  return (
    <Container className={isFixed ? "fixed" : ""}>
      <Card>
        <Content>
          <Header>
            <Picture src={props.data.Picture} />
            <Info>
              <Name>{props.data.Name}</Name>
              <Role>{props.data.Title}</Role>
              <Place>
                <CircleFlag
                  countryCode={props.data.Country?.toLowerCase()}
                  height={16}
                />
                {mapToLabel(props.data.Country, countries)}
              </Place>
            </Info>
          </Header>
          <SeparationLink />

          <Lines>
            <Line>
              <MapPin2LineIcon size={18} />
              <InfoLineText>{data.City}</InfoLineText>
            </Line>
            <Line>
              <Briefcase2LineIcon size={20} />
              <InfoLineText>
                {props.data.YearsEngineeringExperience} of engineering
                experience
              </InfoLineText>
            </Line>
            <Line>
              <BuildingLineIcon size={20} />
              <span>{data.Timezone}</span>
            </Line>
            <Line>
              <ChatVoiceLineIcon size={20} />
              <span>{languages}</span>
            </Line>
          </Lines>
        </Content>
      </Card>
    </Container>
  );
};

export default LeftCard;
