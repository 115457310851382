import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import {
  FormField,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  width: 100%;
  max-width: 100%;

  height: auto;
  background: #ffffff;

  border: 1px solid #ededf0;
  border-radius: 12px;

  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const NonPublic = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #858b9d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  width: 100%;

  /* Notch/Neutral/50 */

  background: #fafafb;
  border-radius: 12px;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const TalentImportPage: React.FC = (props) => {
  let { talentId } = useParams();
  const queryClient = useQueryClient();

  const importMutation = useMutation({
    mutationFn: (values) =>
      axios.post(`/api/backoffice/talents/${talentId}/linkedin-import`, values),
    onSuccess: () => {
      queryClient.invalidateQueries(["talents"]);
    },
  });

  return (
    <>
      <Container>
        <Form
          onSubmit={(values) => {
            importMutation.mutate(values);
          }}
          render={({ handleSubmit, submitting, values }) => (
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <FormContainer>
                <Row>
                  <FormField>
                    <Label>Linkedin URL</Label>
                    <Field
                      name={"linkedinUrl"}
                      component={TextInputField}
                      placeholder={"Linkedin URL"}
                    />
                  </FormField>
                </Row>
                <Row>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={importMutation.isPending}
                  >
                    Import
                  </Button>
                </Row>
              </FormContainer>
            </form>
          )}
        />
      </Container>
    </>
  );
};

export default TalentImportPage;
