import React, { useState } from "react";
import styled from "styled-components";
import Select, { components, OptionProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import { FieldRenderProps } from "react-final-form";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";
import {
  DropdownIndicator,
  Option,
  ValueContainer,
} from "src/Modules/Core/Components/Form/Select/SelectComponents";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import { useMediaQuery } from "usehooks-ts";
import { CircleFlag } from "react-circle-flags";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";

const Container = styled.div`
  width: 100%;
  min-height: 48px;
  height: fit-content;
  .react-select__control {
    min-height: 48px;
    height: fit-content;
  }

  .react-select__value-container {
    flex-wrap: wrap;
    height: fit-content;
    display: flex;
  }

  div[class$="container"] {
    height: fit-content;
  }

  .react-select__container {
    height: fit-content;
    margin: 20px;
  }
`;

const ContainerStatic = styled.div`
  min-height: 48px;
  height: 48px;

  .react-select__control {
    min-height: 48px;
    height: fit-content;
    overflow: scroll;
  }

  .react-select__container {
    height: fit-content;
    margin: 20px;
  }

  .react-select__value-container {
    width: 30px;
  }
`;

type SelectFieldProps = FieldRenderProps<[], any>;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  width: auto;
  flex: 1;
  cursor: pointer;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 0px 0 16px;
  gap: 12px;
`;

const OptionSelectedCheckmark = styled.div`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

const Website = styled.div`
  margin-left: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #858b9d;
`;

const CustomOption: React.FC<OptionProps> = (props: OptionProps) => {
  let option = props;
  let currentOption = props.options.find((i) => {
    return i.value == option.value;
  });

  return (
    <components.Option {...props}>
      <OptionContainer>
        <CompanyLogo
          size={18}
          src={`https://logo.clearbit.com/${currentOption.url}`}
        />
        <Label>{props.label}</Label>
        <Website>{currentOption.url}</Website>
        {props.isSelected && (
          <OptionSelectedCheckmark>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_149_15692)">
                <path
                  d="M9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 10C1.66663 5.3975 5.39746 1.66667 9.99996 1.66667C14.6025 1.66667 18.3333 5.3975 18.3333 10C18.3333 14.6025 14.6025 18.3333 9.99996 18.3333ZM9.16913 13.3333L15.0608 7.44083L13.8825 6.2625L9.16913 10.9767L6.81163 8.61917L5.63329 9.7975L9.16913 13.3333Z"
                  fill="#105CF7"
                />
              </g>
              <defs>
                <clipPath id="clip0_149_15692">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </OptionSelectedCheckmark>
        )}
      </OptionContainer>
    </components.Option>
  );
};

const AssosiatedWorkHistorySelect: React.FC<SelectFieldProps> = (props) => {
  let selectedOptions = null;
  let isMobile = useMediaQuery("(max-width: 600px)");
  if (props.isMulti) {
    if (Array.isArray(props.input.value)) {
      selectedOptions = props.options.filter((option: any) => {
        let isSelected = props.input.value.find((i) => {
          return i == option.value;
        });

        return isSelected != undefined;
      });
    }
  } else {
    selectedOptions = props.options.filter((option: any) => {
      return option.value === props.input.value;
    });
  }

  let RenderContainer = Container;

  if (props.static == "MobileOnly") {
    if (isMobile) {
      RenderContainer = Container;
    } else {
      RenderContainer = ContainerStatic;
    }
  }

  let SelectComponent = Select;

  if (props.creatable) {
    SelectComponent = CreatableSelect;
  }

  return (
    <RenderContainer>
      <SelectComponent
        closeMenuOnSelect={!props.isMulti}
        isMulti={props.isMulti}
        hideSelectedOptions={false}
        classNamePrefix="react-select"
        components={{
          DropdownIndicator: DropdownIndicator,
          Option: CustomOption,
          ValueContainer: ValueContainer,
        }}
        styles={DefaultSelectStyle}
        options={props.options}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        value={selectedOptions}
        onChange={(newValue: any, actionMeta: any) => {
          if (props.isMulti) {
            props.input.onChange(newValue.map((i: any) => i.value));
          } else {
            props.input.onChange(newValue.value);
          }
        }}
        {...props}
      />
    </RenderContainer>
  );
};

export default AssosiatedWorkHistorySelect;
