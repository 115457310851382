import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import Typography from "src/Modules/Core/Components/Atoms/Typography/Typography";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ExperimentalPage: React.FC = (props) => {
  return (
    <FluidContentContainer>
      <Typography type={"DisplayH1"}>sdfadf</Typography>
    </FluidContentContainer>
  );
};

export default ExperimentalPage;
