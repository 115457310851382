import React from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import TalentProfileSectionList from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionList";
import AddFillIcon from "remixicon-react/AddFillIcon";
import AwardEntry from "src/Modules/Talent/Pages/TalentProfile/Sections/AwardEntry";
import {
  FormField,
  FormRow,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import { Field } from "react-final-form";
import UniversitySelect from "src/Modules/Core/Components/Form/Select/UniversitySelect/UniversitySelect";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";

const Container = styled.div``;

const CertificationsSection: React.FC = (props) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data) =>
      axios.post(`/api/talent/${props.profile.talent.id}/profile/awards`, data),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `/api/talent/${props.profile.talent.id}/profile/awards/${data.id}`,
        data
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (data) =>
      axios.delete(
        `/api/talent/${props.profile.talent.id}/profile/awards/${data.id}`
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  let profile = props.profile;

  return (
    <TalentProfileSectionCard
      title="Certifications & Awards"
      status={props.profileStrength.sections?.certifications?.status}
      info={props.profileStrength.sections?.certifications?.info}
    >
      <TalentProfileSectionList
        singleItemView={(item) => <AwardEntry data={item} />}
        singleItemEditForm={null}
        createMutation={createMutation}
        updateMutation={updateMutation}
        deleteMutation={deleteMutation}
        items={profile.awards}
        profile={profile}
        itemForm={(formProps) => (
          <>
            <FormField>
              <Label>Name</Label>
              <Field name={"name"} component={TextInputField} />
            </FormField>
            <FormField>
              <Label>Issuing Organizations</Label>
              <Field name={"issuingOrganization"} component={TextInputField} />
            </FormField>

            <FormRow>
              <FormField>
                <Label>Issue Date </Label>
                <Field
                  name={"issueDateMonth"}
                  component={DefaultSelectField}
                  options={[
                    { value: 1, label: "January" },
                    { value: 2, label: "February" },
                    { value: 3, label: "March" },
                    { value: 4, label: "April" },
                    { value: 5, label: "May" },
                    { value: 6, label: "June" },
                    { value: 7, label: "July" },
                    { value: 8, label: "August" },
                    { value: 9, label: "September" },
                    { value: 10, label: "October" },
                    { value: 11, label: "November" },
                    { value: 12, label: "December" },
                  ]}
                />
              </FormField>
              <FormField>
                <Label>
                  <span style={{ color: "white" }}>.</span>
                </Label>
                <Field
                  name={"issueDateYear"}
                  component={DefaultSelectField}
                  options={TalentProfileOptions.workHistoryYears}
                />
              </FormField>
            </FormRow>
            <FormField>
              <Label>Credential URL</Label>
              <Field name={"credentialUrl"} component={TextInputField} />
            </FormField>
          </>
        )}
        createItemLabel={
          <>
            <AddFillIcon size={20} />
            Add Certifications and Awards
          </>
        }
      />
    </TalentProfileSectionCard>
  );
};

export default CertificationsSection;
