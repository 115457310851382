export default [
  {
    id: "23",
    name: "John Doe",
    title: "Frontend Engineer",
    location: "New York",
    company: "Notch",
    companyUrl: "notchteam.com",

    years: "4",
    skills: ["React", "Vue", "Javascript"],
  },
  {
    id: "24",
    name: "Mad Doe",
    title: "Frontend Engineer",
    location: "New York",
    company: "Facebook",
    companyUrl: "facebook.com",
    years: "4",
    skills: ["React", "React", "React"],
  },
  {
    id: "25",
    name: "Senior Doe",
    title: "CTO",
    location: "New York",
    company: "Google",
    companyUrl: "google.com",

    years: "2",
    skills: ["React", "React", "React"],
  },
  {
    id: "26",
    name: "Mike Doe",
    title: "Frontend Developer",
    location: "New York",
    company: "Airbnb",
    companyUrl: "airbnb.com",

    years: "3",
    skills: ["React", "React", "React"],
  },
  {
    id: "33",
    name: "John Doe",
    title: "Frontend Engineer",
    location: "New York",
    company: "Company",
    companyUrl: "notchteam.com",

    years: "20+",
    skills: ["React", "React", "React"],
  },
  {
    id: "34",
    name: "John Doe",
    title: "Frontend Engineer",
    location: "New York",
    company: "Company",
    companyUrl: "facebook.com",
    years: "4",
    skills: ["React", "React", "React"],
  },
  {
    id: "45",
    name: "John Doe",
    title: "CTO",
    location: "New York",
    company: "Company",
    companyUrl: "google.com",

    years: "4",
    skills: ["React", "React", "React"],
  },
  {
    id: "46",
    name: "John Doe",
    title: "Frontend Developer",
    location: "New York",
    company: "Company",
    companyUrl: "airbnb.com",

    years: "4",
    skills: ["React", "React", "React"],
  },
  {
    id: "55",
    name: "John Doe",
    title: "Frontend Engineer",
    location: "New York",
    company: "Company",
    companyUrl: "notchteam.com",

    years: "4",
    skills: ["React", "React", "React"],
  },
  {
    id: "56",
    name: "John Doe",
    title: "Frontend Engineer",
    location: "New York",
    company: "Company",
    companyUrl: "facebook.com",
    years: "4",
    skills: ["React", "React", "React"],
  },
  {
    id: "57",
    name: "John Doe",
    title: "CTO",
    location: "New York",
    company: "Company",
    companyUrl: "google.com",

    years: "4",
    skills: ["React", "React", "React"],
  },
  {
    id: "58",
    name: "John Doe",
    title: "Frontend Developer",
    location: "New York",
    company: "Company",
    companyUrl: "airbnb.com",

    years: "4",
    skills: ["React", "React", "React"],
  },
];
