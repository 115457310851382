import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import ProjectScreenshot from "src/Modules/Talent/Pages/TalentProfile/ProjectScreenshot";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 80px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: fit-content;
`;

const Logo = styled.div`
  width: 40px;
  aspect-ratio: 1;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;

  color: #1c274a;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.03em;

  color: #1c274a;
`;

const Description = styled.pre`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  white-space: pre-wrap;
`;

const Skills = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-top: 4px;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: 4px 8px;

  /* Notch/Secondary/Teal/50 */
  background: #f4fbfd;
  /* Notch/Neutral/50 */
  border: 1px solid #fafafb;
  border-radius: 6px;

  /* React.js */

  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;
  color: #1d7c93;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const Text = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
  margin-top: 12px;
`;

const Years = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/600 */
  color: #6a7288;
`;

const Url = styled.a`
  font-family: Inter;
  font-size: 14px;
  color: #0e52dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const ProjectEntry: React.FC = (props) => {
  let data = props.data;

  let associatedWorkHistory = null;

  if (data.associatedWorkHistory) {
    associatedWorkHistory = props.profile.workHistories.find((i) => {
      return i.id == data.associatedWorkHistory;
    });
  }

  return (
    <Container>
      <div style={{ gap: 4, display: "flex", flexDirection: "column" }}>
        <Name>{data.name}</Name>
        <Title>{data.positionHeld}</Title>

        {associatedWorkHistory && (
          <Row>
            <CompanyLogo
              size={24}
              src={`https://logo.clearbit.com/${associatedWorkHistory.data.companyWebsite}`}
            />
            <Name>{associatedWorkHistory.data.companyName}</Name>
          </Row>
        )}
      </div>
      <ProjectScreenshot values={data} />
      {data.url && (
        <TextSection>
          <Url
            href={data.url?.includes("http") ? data.url : "//" + data.url}
            target={"_blank"}
          >
            {data.url}
            <ExternalLinkLineIcon size={14} color={"#0e52dd"} />
          </Url>
        </TextSection>
      )}
      <TextSection>
        <Description>{data.description}</Description>
        {data.skills?.length > 0 && (
          <>
            <Text>Skills</Text>
            <Skills>
              {data.skills?.map((i) => {
                return <Skill>{mapToLabel(i, skillOptions)}</Skill>;
              })}
            </Skills>
          </>
        )}
      </TextSection>
    </Container>
  );
};

export default ProjectEntry;
