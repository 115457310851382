import React, { useState } from "react";
import styled from "styled-components";
import {
  AddAction,
  ButtonGroup,
  CancelEditAction,
  EditFooterLine,
  GrayLineSeparator,
  FormRow,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import AddFillIcon from "remixicon-react/AddFillIcon";
import { Mutation, UseMutationResult } from "@tanstack/react-query";
import { Form } from "react-final-form";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import PencilRulerLineIcon from "remixicon-react/PencilRulerLineIcon";
import EditLineIcon from "remixicon-react/EditLineIcon";
import arrayMutators from "final-form-arrays";

const Container = styled.div``;

const NewEntryContainer = styled.div`
  margin-top: 20px;
`;

const SectionEditCard = styled.div`
  padding: 16px;
  border-radius: 12px;
  background: var(--Notch-Neutral-50, #fafafb);
  min-height: 100px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const EntryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const EditAction = (props) => {
  let Container = styled.div`
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 4px;
    &:hover svg {
      color: red;
    }
  `;

  return (
    <Container {...props}>
      <EditLineIcon color={"gray"} size={18} />
    </Container>
  );
};

const DeleteAction = styled.div`
  cursor: pointer;
  color: red;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.42px;
  margin-right: auto;
`;

const ActionInProgress = styled.div`
  font-family: Inter;
  font-size: 14px;
  margin-left: auto;
  color: #0f172a;
`;

const EntryContainer = styled.div`
  position: relative;
`;

const EntryContent = styled.div``;

type Props = {
  singleItemView: Element;
  singleItemEditForm: Element;
  createMutation: UseMutationResult;
  updateMutation: UseMutationResult;
  deleteMutation: UseMutationResult;
  items: [];
  profile: unknown;
  itemForm: Element;
  createItemLabel: unknown;
};
const TalentProfileSectionList: React.FC<Props> = (props) => {
  const [creating, setCreating] = useState(false);
  const [edit, setEdit] = useState(null);

  let mutationPending =
    props.createMutation.isPending ||
    props.updateMutation.isPending ||
    props.deleteMutation.isPending;

  return (
    <Container>
      <EntryList>
        {props.items.map((item, index) => {
          let isEditing = edit == item.id;
          return (
            <>
              <EntryContainer key={item.id}>
                {!isEditing && (
                  <EditAction
                    onClick={() => {
                      setEdit(item.id);
                      setCreating(false);
                    }}
                  />
                )}
                {!isEditing && (
                  <EntryContent>{props.singleItemView(item)}</EntryContent>
                )}
                {isEditing && (
                  <NewEntryContainer>
                    <Form
                      mutators={{ ...arrayMutators }}
                      onSubmit={(values, form) => {
                        props.updateMutation.mutate(values, {
                          onSuccess: () => {
                            form.reset();
                            setEdit(null);
                          },
                        });
                      }}
                      initialValues={item}
                      render={(formProps) => (
                        <form
                          style={{ width: "100%" }}
                          onSubmit={formProps.handleSubmit}
                        >
                          <SectionEditCard>
                            <EntryContent>
                              {props.singleItemView(formProps.values)}
                            </EntryContent>
                            <EditFooterLine />
                            <FormContent>
                              {props.itemForm(formProps)}
                            </FormContent>
                            <EditFooterLine />
                            {!mutationPending && (
                              <ButtonGroup>
                                <CancelEditAction
                                  onClick={() => {
                                    formProps.form.reset();
                                    setEdit(null);
                                  }}
                                >
                                  Cancel
                                </CancelEditAction>
                                <DeleteAction
                                  onClick={() => {
                                    props.deleteMutation.mutate(
                                      formProps.values,
                                      {
                                        onSuccess: () => setEdit(null),
                                      }
                                    );
                                  }}
                                >
                                  Delete
                                </DeleteAction>
                                <Button
                                  variant="primary"
                                  style={{ marginLeft: "auto" }}
                                  disabled={props.createMutation.isPending}
                                >
                                  Save
                                </Button>
                              </ButtonGroup>
                            )}
                            {mutationPending && (
                              <ButtonGroup>
                                <ActionInProgress>
                                  {props.deleteMutation.isPending &&
                                    "Deleting..."}
                                  {props.updateMutation.isPending &&
                                    "Saving..."}
                                </ActionInProgress>
                              </ButtonGroup>
                            )}
                          </SectionEditCard>
                        </form>
                      )}
                    />
                  </NewEntryContainer>
                )}
              </EntryContainer>
              {index < props.items.length - 1 && <GrayLineSeparator />}
            </>
          );
        })}
      </EntryList>
      {creating && (
        <NewEntryContainer>
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={(values, form) => {
              props.createMutation.mutate(values, {
                onSuccess: () => {
                  form.reset();
                  setCreating(false);
                },
              });
            }}
            initialValues={{}}
            render={(formProps) => (
              <form style={{ width: "100%" }} onSubmit={formProps.handleSubmit}>
                <SectionEditCard>
                  <FormContent>{props.itemForm(formProps)}</FormContent>
                  <EditFooterLine />
                  <ButtonGroup>
                    <CancelEditAction
                      onClick={() => {
                        formProps.form.reset();
                        setCreating(false);
                      }}
                    >
                      Cancel
                    </CancelEditAction>
                    <Button
                      variant="primary"
                      style={{ marginLeft: "auto" }}
                      disabled={props.createMutation.isPending}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </SectionEditCard>
              </form>
            )}
          />
        </NewEntryContainer>
      )}

      {!creating && !edit && (
        <FormRow>
          <AddAction
            onClick={() => {
              setCreating(true);
              setEdit(null);
            }}
          >
            {props.createItemLabel}
          </AddAction>
        </FormRow>
      )}
    </Container>
  );
};

export default TalentProfileSectionList;
