import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div<CompanyLogoProps>`
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  contain: paint;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const ScreenshotContainer = styled.div`
  width: fit-content;
  height: fit-content;
  max-width: 260px;
  aspect-ratio: 1.4;

  border-radius: 6px;
  contain: paint;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
  }

  img {
    width: 172px;
    height: auto;
  }
`;

const DefaultScreenshot = styled(ScreenshotContainer)`
  background: #ededf0;
  color: #e2e8f0;
`;

const ProjectScreenshot: React.FC = ({ values }) => {
  let src = values.screenshotUrl ?? values.url;

  let [loading, setLoading] = useState(true);
  let [found, setFound] = useState(false);
  let [image, setImage] = useState<HTMLImageElement | null>(null);
  let [debounce, setDebounce] = useState<any>(null);
  let [empty, setEmpty] = useState(true);

  let urlBoxPrefix = "";

  useEffect(() => {
    if (src == undefined) {
      return;
    }

    if (debounce != undefined) {
      window.clearTimeout(debounce);
    }

    if (src !== image?.src) {
      setLoading(true);
    }

    let timeoutHandler = window.setTimeout(() => {
      if (src == undefined) {
        return;
      }

      if (src == null || src == "") {
        return;
      }

      setEmpty(false);

      let image = new Image();

      image.onload = () => {
        setLoading(false);
        setFound(true);
        setImage(image);
      };

      image.onerror = () => {
        setLoading(false);
        setFound(false);
      };

      image.src = urlBoxPrefix + src;

      setImage(image);
    }, 800);

    setDebounce(timeoutHandler);

    return () => window.clearTimeout(debounce);
  }, [src]);

  if (empty) {
    return null;
  }

  if (loading) {
    return null;
  }

  if (!found) {
    return null;
  }

  return (
    <Container>
      <ScreenshotContainer>
        <img src={image?.src + ""} />
      </ScreenshotContainer>
    </Container>
  );
};

export default ProjectScreenshot;
