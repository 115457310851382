import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProjectScreenshot from "src/Modules/Talent/Pages/TalentProfile/ProjectScreenshot";
import ErrorWarningLineIcon from "remixicon-react/ErrorWarningLineIcon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const ScreenshotContainer = styled.div<CompanyLogoProps>`
  width: 172px;
  height: 96px;
  border-radius: 6px;
  contain: paint;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #ededf0;

  svg {
  }

  img {
    width: 172px;
    height: auto;
  }
`;

const ScreenshotContainerLoaded = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1.4;
  border-radius: 6px;
  contain: paint;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #ededf0;

  svg {
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoHeader = styled.div`
  color: var(--Notch-Neutral-800, #414a67);

  /* Notch/Label/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
`;

const InfoText = styled.div`
  color: var(--Notch-Neutral-800, #414a67);

  /* Notch / Details / Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.36px;
`;

const isUploadedUrl = (url: string) => {
  if (url.includes("us-west-2")) {
    return true;
  }
  return false;
};

const ProjectEditScreenshot: React.FC = ({ values }) => {
  let src = values.screenshotUrl ?? values.uploadedScreenshot ?? values.url;

  if (values.deleteUploadedScreenshot) {
    src = values.url;
  }

  let [loading, setLoading] = useState(true);
  let [found, setFound] = useState(false);
  let [image, setImage] = useState<HTMLImageElement | null>(null);
  let [debounce, setDebounce] = useState<any>(null);
  let [empty, setEmpty] = useState(true);

  let urlBoxPrefix = "https://api.urlbox.io/v1/16Vr95oU2So1utXd/png?url=";

  useEffect(() => {
    if (src == undefined) {
      if (image) {
        image.src = null;
      }
      return;
    }

    if (debounce != undefined) {
      window.clearTimeout(debounce);
    }

    if (src !== image?.src) {
      setLoading(true);
    }

    let timeoutHandler = window.setTimeout(() => {
      if (src == undefined) {
        return;
      }

      if (src == null || src == "") {
        return;
      }

      setEmpty(false);

      let image = new Image();

      image.onload = () => {
        setLoading(false);
        setFound(true);
        setImage(image);
      };

      image.onerror = () => {
        setLoading(false);
        setFound(false);
      };

      if (src instanceof File) {
        let fr = new FileReader();
        fr.onloadend = () => {
          image.src = fr.result;
        };
        let data = fr.readAsDataURL(src);
      } else {
        if (isUploadedUrl(src)) {
          image.src = src;
        } else {
          image.src =
            urlBoxPrefix +
            src +
            "&click_accept=true&hide_cookie_banners=true&wait_until=requestsfinished&ttl=30000&thumb_width=1920&max_height=1080";
        }
      }

      setImage(image);
    }, 800);

    setDebounce(timeoutHandler);

    return () => window.clearTimeout(debounce);
  }, [src]);

  if (empty) {
    return (
      <Container>
        <ScreenshotContainer>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M42 30V36H48V40H42V46H38V40H32V36H38V30H42ZM42.016 6C43.112 6 44 6.89 44 7.986V26.684C42.7153 26.2302 41.3625 25.9989 40 26V10H8L8.002 38L26.586 19.414C26.9299 19.069 27.388 18.8615 27.8742 18.8305C28.3604 18.7996 28.8411 18.9473 29.226 19.246L29.412 19.416L36.504 26.516C34.9533 26.9885 33.5143 27.7702 32.2739 28.8139C31.0335 29.8576 30.0172 31.1417 29.2865 32.5888C28.5558 34.0359 28.1257 35.616 28.0221 37.2338C27.9186 38.8516 28.1437 40.4736 28.684 42.002L5.984 42C5.45763 41.9995 4.95299 41.79 4.58098 41.4176C4.20896 41.0452 4 40.5404 4 40.014V7.986C4.00366 7.46076 4.2138 6.95803 4.58503 6.58644C4.95625 6.21484 5.45876 6.00419 5.984 6H42.016ZM16 14C17.0609 14 18.0783 14.4214 18.8284 15.1716C19.5786 15.9217 20 16.9391 20 18C20 19.0609 19.5786 20.0783 18.8284 20.8284C18.0783 21.5786 17.0609 22 16 22C14.9391 22 13.9217 21.5786 13.1716 20.8284C12.4214 20.0783 12 19.0609 12 18C12 16.9391 12.4214 15.9217 13.1716 15.1716C13.9217 14.4214 14.9391 14 16 14V14Z"
              fill="#9FA4B3"
            />
          </svg>
        </ScreenshotContainer>
        <InfoContainer>
          <InfoHeader>No project image was found!</InfoHeader>
          <InfoText>
            Drag and drop or click to upload PNG or JPG. 250x250 or larger
            recommended max 5MB.
          </InfoText>
        </InfoContainer>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container>
        <ScreenshotContainer></ScreenshotContainer>
        <InfoContainer>
          <InfoHeader></InfoHeader>
          <InfoText>
            Click to replace logo PNG or JPG. 250x250 or larger recommended max
            5MB.
          </InfoText>
        </InfoContainer>
      </Container>
    );
  }

  if (!found) {
    return <Container></Container>;
  }

  if (!found) {
    return <pre style={{ color: "red" }}>Error</pre>;
  }

  return (
    <Container>
      <ScreenshotContainer>
        <img src={image?.src} />
      </ScreenshotContainer>
      <InfoContainer>
        <InfoHeader></InfoHeader>
        <InfoText>
          Click to or drag and drop to replace logo PNG or JPG. 250x250 or
          larger recommended max 5MB.
        </InfoText>
      </InfoContainer>
    </Container>
  );
};

export default ProjectEditScreenshot;
