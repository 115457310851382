import React, { useState } from "react";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import axios from "axios";
import {
  FormGroupFull,
  FormLabel,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import { useQueryClient } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.04em;
  color: #1c274a;
  text-align: left;
  margin-bottom: 32px;
`;

const ForgotPasswordLink = styled.a`
  display: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-left: auto;
  width: 120px;
  cursor: pointer;

  &:hover {
    color: #0644c3;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  height: 48px;
  width: 100%;

  background: #105cf7;
  border-radius: 6px;

  margin-top: 28px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.03em;

  color: #ffffff;

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    outline: none;
    border: none;
  }
`;

const SignUpTextContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  align-items: center;
  letter-spacing: -0.03em;
  color: #858b9d;
  text-align: center;
  margin-top: 16px;

  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  font-family: Inter;
  color: #ef4444;
  font-size: 14px;
  width: 100%;
  margin: 0 auto;
`;

const ErrorLink = styled.a`
  font-family: Inter;
  color: #105cf7;
  font-size: 14px;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
`;

const SignUpNavLink = styled(NavLink)`
  color: #105cf7;
  padding: 0 0px;
  display: inline-block;
`;

const SignInSchema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
});

type Props = {
  setResetScreen: (value: boolean) => void;
};

const SignInForm: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  let [error, setError] = useState(null);
  return (
    <Container>
      <Header>Welcome back!</Header>
      <Form
        onSubmit={(values) => {
          setError(null);
          axios
            .post("/api/security/v1/sign-in", values)
            .then((response) => {
              let data = response.data;

              if (data.legacy == true) {
                window.location.replace("https://app.notchteam.com");
              } else {
                queryClient.invalidateQueries(["auth"]);
              }
            })
            .catch((error) => {
              let data = error.response.data;
              setError(data.message);
            });
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FormGroupFull>
              <FormLabel>Email</FormLabel>
              <Field name={"email"} component={TextInputField} />
            </FormGroupFull>

            <FormGroupFull>
              <FormLabel>Password</FormLabel>
              <Field
                name={"password"}
                type="password"
                component={TextInputField}
              />
            </FormGroupFull>

            <ForgotPasswordLink
              onClick={() => {
                props.setResetScreen(true);
              }}
            >
              Forgot password?
            </ForgotPasswordLink>

            {error && <ErrorMessage>{error}</ErrorMessage>}
            {error == "Sorry, this account needs to sign in from this link" && (
              <ErrorLink href={"https://app.notchteam.com"}>
                Go to talent sign in
              </ErrorLink>
            )}

            <SubmitButton
              disabled={!SignInSchema.isValidSync(values)}
              type={"submit"}
            >
              Log In
            </SubmitButton>

            <SignUpTextContainer>
              Need to create an account?
              <br /> Sign up as{" "}
              <SignUpNavLink to={"/join-customer"}>
                Company
              </SignUpNavLink> or{" "}
              <SignUpNavLink to={"/join-talent"}>Talent</SignUpNavLink>
            </SignUpTextContainer>
          </form>
        )}
      />
    </Container>
  );
};

export default SignInForm;
