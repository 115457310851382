import React, { useEffect, useState } from "react";
import {
  HaveAnAccountText,
  TalentSignupHeaderContainer,
  TalentSignUpPageContainerStyle,
  TalentSignUpPageFormStyle,
  TalentSignUpPageSidebarStyle,
} from "src/Modules/Shared/WipStyles.css";
import Box from "src/Modules/Shared/Components/Atom/Box/Box";
import { s } from "@design/sprink";
import { sprinkles } from "@design/sprinkles.css";
import styled from "styled-components";
import useStore from "src/Modules/Shared/Slice/useStore";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import TalentFlowSidebar from "src/Modules/Shared/Pages/TalentFlowSidebar";
import TalentFlowUpperNav from "src/Modules/Shared/Pages/TalentFlowUpperNav";
import CustomerFlowSidebar from "src/Modules/Shared/Pages/CustomerFlowSidebar";
import { InferType, object, string } from "yup";
import axios from "axios";
import { Field, Form } from "react-final-form";
import { vars } from "src/Modules/Core/Design/Themes/LightTheme.css";
import { clearInterval } from "timers";
import { useQuery } from "@tanstack/react-query";

const Header = styled.h1`
  fontsize: 40px;
  color: ${vars.colors.gray900};
  fontfamily: Inter;
  fontweight: 600;
  letterspacing: -4%;
  line-height: 1.5;
`;

const UnderHeaderText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #414a67;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 4px;
`;

const LabelStar = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-left: 2px;
`;

const TalentSignupFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 488px;
  width: 100%;
  padding: 0 16px;
  //border: 2px solid red;
  margin: 0 auto;
  grid-area: form;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;
  color: black;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  box-sizing: border-box;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }
`;

const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const PasswordHideSee = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;

  right: 8px;
  top: 42px;
  z-index: 200;
`;

const PasswordHideSee2 = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;

  right: 8px;
  top: 42px;
  z-index: 200;
`;

const PolicyText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.03em;
  color: #858b9d;
  margin-bottom: 24px;

  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.03em;

    /* Notch/Neutral/900 */

    color: #1c274a;
    display: inline;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 8px;

  width: 100%;
  height: 48px;

  /* Notch/Primary/Blue/600 */

  background: #105cf7;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  color: #ffffff;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #105cf7;
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #105cf7;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
  }
`;

const TalentSignupLayout = styled.div`
  display: grid;
  overflow-y: auto;
  height: 100%;

  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 64px minmax(20px, 30px) 1fr minmax(20px, 1fr);
  grid-template-areas: "header header header" ". . ." ". form ." ". . .";

  @media screen and (min-width: 660px) {
    grid-template-columns: 1fr 650px 1fr;
    grid-template-rows: 64px 80px 1fr minmax(20px, 1fr);
    grid-template-areas: "header header header" ". progress header" ". form ." ". . .";
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 1fr 488px 1fr;
    grid-template-rows: 80px minmax(20px, 120px) 1fr minmax(20px, 1fr);
    grid-template-areas: "header header header" ". . ." ". form ." ". . .";
  }

  @media screen and (min-width: 1700px) {
    grid-template-columns: 410px 488px 1fr;
    grid-template-rows: 80px minmax(20px, 120px) 1fr minmax(20px, 1fr);
    grid-template-areas: "header header header" ". . ." ". form ." ". . .";
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: none;
  flex-direction: row;
  align-items: center;
  font-family: Inter;
  grid-area: header;

  @media screen and (min-width: 1280px) {
    display: flex;
  }
`;

const SignInButton = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Primary/Blue/600 */

  border: 1px solid #105cf7;
  border-radius: 6px;
`;

const PasswordHintItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const PasswordHintIcon = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PasswordHintLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/600 */

  color: #6a7288;
  margin-left: 4px;
`;

const PasswordHintHeader = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #6a7288;
  margin: 8px 0 4px 0;
`;

function isAlphaNumeric(str: string) {
  let code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      //   !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

const getPasswordHintValues = (value: string) => {
  const result = [];

  if (value == null || value.length < 8) {
    result.push(
      <PasswordHintItem>
        <PasswordHintIcon>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99996 17.3333C4.39746 17.3333 0.666626 13.6025 0.666626 8.99996C0.666626 4.39746 4.39746 0.666626 8.99996 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 8.99996 17.3333ZM8.99996 15.6666C10.7681 15.6666 12.4638 14.9642 13.714 13.714C14.9642 12.4638 15.6666 10.7681 15.6666 8.99996C15.6666 7.23185 14.9642 5.53616 13.714 4.28591C12.4638 3.03567 10.7681 2.33329 8.99996 2.33329C7.23185 2.33329 5.53616 3.03567 4.28591 4.28591C3.03567 5.53616 2.33329 7.23185 2.33329 8.99996C2.33329 10.7681 3.03567 12.4638 4.28591 13.714C5.53616 14.9642 7.23185 15.6666 8.99996 15.6666Z"
              fill="#D2D4DB"
            />
          </svg>
        </PasswordHintIcon>
        <PasswordHintLabel>Minimum of 8 characters</PasswordHintLabel>
      </PasswordHintItem>
    );
  } else {
    result.push(
      <PasswordHintItem>
        <PasswordHintIcon>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99996 17.3333C4.39746 17.3333 0.666626 13.6025 0.666626 8.99996C0.666626 4.39746 4.39746 0.666626 8.99996 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 8.99996 17.3333ZM8.16913 12.3333L14.0608 6.44079L12.8825 5.26246L8.16913 9.97663L5.81163 7.61913L4.63329 8.79746L8.16913 12.3333Z"
              fill="#00C181"
            />
          </svg>
        </PasswordHintIcon>
        <PasswordHintLabel>Minimum of 8 characters</PasswordHintLabel>
      </PasswordHintItem>
    );
  }

  if (
    value == null ||
    value.toLowerCase() == value ||
    value === value.toUpperCase()
  ) {
    result.push(
      <PasswordHintItem>
        <PasswordHintIcon>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99996 17.3333C4.39746 17.3333 0.666626 13.6025 0.666626 8.99996C0.666626 4.39746 4.39746 0.666626 8.99996 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 8.99996 17.3333ZM8.99996 15.6666C10.7681 15.6666 12.4638 14.9642 13.714 13.714C14.9642 12.4638 15.6666 10.7681 15.6666 8.99996C15.6666 7.23185 14.9642 5.53616 13.714 4.28591C12.4638 3.03567 10.7681 2.33329 8.99996 2.33329C7.23185 2.33329 5.53616 3.03567 4.28591 4.28591C3.03567 5.53616 2.33329 7.23185 2.33329 8.99996C2.33329 10.7681 3.03567 12.4638 4.28591 13.714C5.53616 14.9642 7.23185 15.6666 8.99996 15.6666Z"
              fill="#D2D4DB"
            />
          </svg>
        </PasswordHintIcon>
        <PasswordHintLabel>Upper and lower case letters</PasswordHintLabel>
      </PasswordHintItem>
    );
  } else {
    result.push(
      <PasswordHintItem>
        <PasswordHintIcon>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99996 17.3333C4.39746 17.3333 0.666626 13.6025 0.666626 8.99996C0.666626 4.39746 4.39746 0.666626 8.99996 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 8.99996 17.3333ZM8.16913 12.3333L14.0608 6.44079L12.8825 5.26246L8.16913 9.97663L5.81163 7.61913L4.63329 8.79746L8.16913 12.3333Z"
              fill="#00C181"
            />
          </svg>
        </PasswordHintIcon>
        <PasswordHintLabel>Upper and lower case letters</PasswordHintLabel>
      </PasswordHintItem>
    );
  }

  if (value == null || isAlphaNumeric(value)) {
    result.push(
      <PasswordHintItem>
        <PasswordHintIcon>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99996 17.3333C4.39746 17.3333 0.666626 13.6025 0.666626 8.99996C0.666626 4.39746 4.39746 0.666626 8.99996 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 8.99996 17.3333ZM8.99996 15.6666C10.7681 15.6666 12.4638 14.9642 13.714 13.714C14.9642 12.4638 15.6666 10.7681 15.6666 8.99996C15.6666 7.23185 14.9642 5.53616 13.714 4.28591C12.4638 3.03567 10.7681 2.33329 8.99996 2.33329C7.23185 2.33329 5.53616 3.03567 4.28591 4.28591C3.03567 5.53616 2.33329 7.23185 2.33329 8.99996C2.33329 10.7681 3.03567 12.4638 4.28591 13.714C5.53616 14.9642 7.23185 15.6666 8.99996 15.6666Z"
              fill="#D2D4DB"
            />
          </svg>
        </PasswordHintIcon>
        <PasswordHintLabel>
          At least one number or special character
        </PasswordHintLabel>
      </PasswordHintItem>
    );
  } else {
    result.push(
      <PasswordHintItem>
        <PasswordHintIcon>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99996 17.3333C4.39746 17.3333 0.666626 13.6025 0.666626 8.99996C0.666626 4.39746 4.39746 0.666626 8.99996 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 8.99996 17.3333ZM8.16913 12.3333L14.0608 6.44079L12.8825 5.26246L8.16913 9.97663L5.81163 7.61913L4.63329 8.79746L8.16913 12.3333Z"
              fill="#00C181"
            />
          </svg>
        </PasswordHintIcon>
        <PasswordHintLabel>
          At least one number or special character
        </PasswordHintLabel>
      </PasswordHintItem>
    );
  }

  return result;
};

const signUpSchema = object({
  fullName: string().required(),
  password: string()
    .required()
    .test("pass", (value) => {
      if (!value) {
        return false;
      }

      if (value && value.length < 8) {
        return false;
      }

      if (value.toUpperCase() === value) {
        return false;
      }

      if (value.toLowerCase() === value) {
        return false;
      }

      if (isAlphaNumeric(value)) {
        return false;
      }

      return true;
    }),
});

type SignUpType = InferType<typeof signUpSchema>;

const getPassPosition = (values: any) => {
  if (values.fullName != "" && values.password != "") {
    return "top";
  } else {
    return "bot";
  }
};

const DefaultInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  box-sizing: border-box;
`;

const PasswordHintField = styled.div<{ poz: string }>`
  position: absolute;
  width: 270px;
  background: white;
  border: 1px solid #ededf0;
  box-shadow: 0px 12px 32px rgba(28, 39, 74, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  min-height: 100px;

  top: 84px;
  top: ${(props) => (props.poz == "top" ? "-120px" : "84px")};
  right: 0px;
  padding: 8px;

  @media screen and (min-width: 1050px) {
    right: -280px;
    top: 0px;
  }

  @media screen and (min-width: 1230px) {
    top: 86px;
    right: 0px;
  }

  @media screen and (min-width: 1480px) {
    right: -280px;
    top: 0px;
  }
`;

const InvitationError = styled.div`
  background: #fee2e2;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #f87171;
`;

const CustomerMemberInvitePage: React.FC = (props) => {
  const selectionColors = [
    "peachpuff",
    "orangered",
    "blueviolet",
    "mediumpurple",
    "limegreen",
    "#FEF08A",
    "#99F6E4",
    "#D8B4FE",
    "#E11D48",
    "#A21CAF",
    "#4ADE80",
    "#B45309",
    "#d4d4d4",
    "#fbbf24",
  ];

  const [submitting, setSubmitting] = useState(false);
  const [plain, setPlain] = useState(false);
  const [fieldFullname, setFieldFullname] = useState("");
  const [fieldEmail, setFieldEmail] = useState("");
  const [fieldPassword, setFieldPassword] = useState("");

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const { token } = useParams();

  const [notchColors, setNotchColors] = useState({
    0: selectionColors[0],
    1: selectionColors[0],
    2: selectionColors[0],
    3: selectionColors[0],
    4: selectionColors[0],
  });

  useEffect(() => {
    const x = setInterval(() => {
      let i = 0;
      const newColors = [];
      const newColor =
        selectionColors[Math.floor(Math.random() * selectionColors.length)];
      for (i = 0; i < 5; i++) {
        newColors[i] = newColor;
      }
      setNotchColors(newColors);
    }, 1300);

    return () => clearTimeout(x);
  }, []);

  let query = useQuery({
    queryKey: ["invitationcustomermem"],
    queryFn: () => axios.get("/api/security/v1/invite/" + token),
  });

  if (query.isLoading) {
    return null;
  }

  let isFormValid = false;

  const result = {
    fullName: fieldFullname,
    password: fieldPassword,
  };

  isFormValid = signUpSchema.isValidSync(result);
  return (
    <div className={TalentSignUpPageContainerStyle}>
      <TalentFlowSidebar />
      <TalentSignupLayout>
        <HeaderContainer>
          <div className={HaveAnAccountText}>Have an account?</div>
          <SignInButton to="/">Log In</SignInButton>
        </HeaderContainer>
        <TalentFlowUpperNav />
        <TalentSignupFormContainer>
          {query.isError && (
            <InvitationError>This invitation has expired</InvitationError>
          )}
          {!query.isError && (
            <Form
              onSubmit={(values) => {
                setSubmitting(true);
                axios
                  .post("/api/security/v1/invite/" + token, {
                    fullname: values.fullName,
                    email: values.email,
                    password: values.password,
                  })
                  .then((response) => {
                    setSubmitting(false);
                    window.location.href = "/";
                  });
              }}
              initialValues={{}}
              render={({ handleSubmit, values, submitting }) => (
                <form onSubmit={handleSubmit} method="post">
                  <Header>Join Notch</Header>
                  <UnderHeaderText>
                    Join to access exclusive job offers, intelligent role
                    matching, and career advancing perks and benefits—all at no
                    cost.
                  </UnderHeaderText>
                  <FormFieldContainer>
                    <Label>Full Name</Label>
                    <Field name="fullName">
                      {(props) => <DefaultInput {...props.input} />}
                    </Field>
                  </FormFieldContainer>

                  <FormFieldContainer>
                    {isPasswordFocused && (
                      <PasswordHintField poz={getPassPosition(values)}>
                        <PasswordHintHeader>
                          Password must contain:
                        </PasswordHintHeader>
                        {getPasswordHintValues(values.password)}
                      </PasswordHintField>
                    )}
                    {plain ? (
                      <PasswordHideSee onClick={() => setPlain(false)}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_239_17477)">
                            <path
                              d="M0.984192 10C1.76753 5.73333 5.50669 2.5 10 2.5C14.4934 2.5 18.2317 5.73333 19.0159 10C18.2325 14.2667 14.4934 17.5 10 17.5C5.50669 17.5 1.76836 14.2667 0.984192 10ZM10 14.1667C11.1051 14.1667 12.1649 13.7277 12.9463 12.9463C13.7277 12.1649 14.1667 11.1051 14.1667 10C14.1667 8.89493 13.7277 7.83512 12.9463 7.05372C12.1649 6.27232 11.1051 5.83333 10 5.83333C8.89496 5.83333 7.83515 6.27232 7.05375 7.05372C6.27235 7.83512 5.83336 8.89493 5.83336 10C5.83336 11.1051 6.27235 12.1649 7.05375 12.9463C7.83515 13.7277 8.89496 14.1667 10 14.1667ZM10 12.5C9.33698 12.5 8.7011 12.2366 8.23226 11.7678C7.76342 11.2989 7.50003 10.663 7.50003 10C7.50003 9.33696 7.76342 8.70107 8.23226 8.23223C8.7011 7.76339 9.33698 7.5 10 7.5C10.6631 7.5 11.299 7.76339 11.7678 8.23223C12.2366 8.70107 12.5 9.33696 12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.299 12.2366 10.6631 12.5 10 12.5Z"
                              fill="#1C274A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_239_17477">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </PasswordHideSee>
                    ) : (
                      <PasswordHideSee2 onClick={() => setPlain(true)}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_522_65042)">
                            <path
                              d="M3.76663 4.94506L1.1608 2.34006L2.33996 1.16089L18.8391 17.6609L17.66 18.8392L14.9016 16.0809C13.436 17.0105 11.7356 17.5028 9.99996 17.5001C5.50663 17.5001 1.7683 14.2667 0.984131 10.0001C1.34249 8.05899 2.31882 6.2858 3.76746 4.94506H3.76663ZM12.2975 13.4767L11.0775 12.2567C10.6108 12.48 10.0864 12.553 9.57657 12.4657C9.0667 12.3784 8.59647 12.1351 8.23069 11.7693C7.86491 11.4036 7.62161 10.9333 7.53431 10.4234C7.44702 9.91358 7.52003 9.38918 7.7433 8.92256L6.5233 7.70256C5.99306 8.5038 5.75602 9.46367 5.85232 10.4196C5.94862 11.3756 6.37234 12.2689 7.05173 12.9483C7.73112 13.6277 8.62443 14.0514 9.58039 14.1477C10.5364 14.244 11.4962 14.007 12.2975 13.4767ZM6.64496 3.13339C7.68413 2.72506 8.81663 2.50006 9.99996 2.50006C14.4933 2.50006 18.2316 5.73339 19.0158 10.0001C18.7605 11.3881 18.1865 12.698 17.3391 13.8267L14.1225 10.6101C14.218 9.9664 14.1615 9.30931 13.9574 8.69143C13.7533 8.07356 13.4074 7.51207 12.9472 7.05195C12.4871 6.59182 11.9256 6.24586 11.3078 6.04178C10.6899 5.8377 10.0328 5.78117 9.38913 5.87672L6.64496 3.13422V3.13339Z"
                              fill="#B8BCC7"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_522_65042">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </PasswordHideSee2>
                    )}
                    <Label>Password</Label>

                    <Field name="password">
                      {(props) => (
                        <DefaultInput
                          {...props.input}
                          onFocus={() => {
                            setIsPasswordFocused(true);
                          }}
                          type={plain ? "" : "password"}
                          onBlur={() => {
                            setIsPasswordFocused(false);
                          }}
                        />
                      )}
                    </Field>
                  </FormFieldContainer>
                  <PolicyText>
                    By confirming your email you agree to our{" "}
                    <a href="/">Term of Service</a> and have read and understood
                    our <a href="/">Privacy Policy</a>.
                  </PolicyText>
                  <SubmitButton
                    disabled={submitting || !signUpSchema.isValidSync(values)}
                    type={"submit"}
                  >
                    {submitting ? "Submitting..." : "Start hiring"}
                  </SubmitButton>
                </form>
              )}
            />
          )}
        </TalentSignupFormContainer>
      </TalentSignupLayout>
    </div>
  );
};

export default CustomerMemberInvitePage;
