import React from "react";
import styled from "styled-components";
import { Outlet, useParams } from "react-router-dom";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import { useQuery } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import TalentManagementOverviewHeader from "src/Modules/Admin/Pages/Talents/TalentManagementOverviewHeader";
import HorizontalToggleNavigation from "src/Modules/Admin/Pages/Talents/HorizontalToggleNavigation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TalentViewPage: React.FC = (props) => {
  let { talentId } = useParams();

  let query = useQuery(BackofficeApi.talents.id(talentId).get());

  if (query.isLoading) {
    return null;
  }

  return (
    <FluidContentContainer>
      <Container>
        <TalentManagementOverviewHeader talentId={talentId} />
        <HorizontalToggleNavigation
          links={[
            { label: "Profile", to: "profile" },
            { label: "Import", to: "import" },
            { label: "Invite", to: "invite" },
          ]}
        />
        <Outlet />
      </Container>
    </FluidContentContainer>
  );
};

export default TalentViewPage;
