import React, { useState } from "react";
import styled from "styled-components";
import { useFilters } from "react-table";
import PulseFillIcon from "remixicon-react/PulseFillIcon";
import { DownChevron } from "react-select/dist/declarations/src/components/indicators";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import AddFillIcon from "remixicon-react/AddFillIcon";
import HoverPopup from "src/Modules/Talent/Pages/TalentProfile/HoverPopup";
import ProfileMessageBanner from "src/Modules/Talent/Pages/TalentProfile/ProfileMessageBanner";

const Container = styled.div`
  border-radius: 12px;
  border: 1px solid #ededf0;
  background: #fff;
  width: 100%;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 12px;
`;

const HeaderTitle = styled.div`
  color: var(--Notch-Neutral-900, #1c274a);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: -0.54px;
`;

const Toggle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 20px;
  aspect-ratio: 1;
`;

const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;

  /* Notch / Subheading / Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.72px;
  text-transform: uppercase;
`;

const NotCompleted = styled(Status)`
  border-radius: 4px;
  background: var(--Notch-Neutral-50, #fafafb);
  color: var(--Notch-Neutral-600, #6a7288);
`;

const Partial = styled(Status)`
  border-radius: 4px;
  background: var(--Notch-Secondary-Orange-50, #fff8e2);
  color: var(--Notch-Secondary-Orange-600, #fbb32b);
`;

const Issue = styled(Status)`
  background: #fef2f2;
  color: #fca5a5;
`;

const TalentProfileSectionCard: React.FC = (props) => {
  const [active, setActive] = useState(true);

  let status = props.status ?? null;
  let info = props.info ?? null;
  const messages = props.messages ?? [];

  return (
    <Container
      onClick={() => {
        if (!active) {
          setActive(!active);
        }
      }}
    >
      <Header
        onClick={() => {
          if (active) {
            setActive(!active);
          }
        }}
      >
        <HeaderTitle>{props.title ?? ""}</HeaderTitle>
        <Toggle>
          {active ? (
            <ArrowDownSLineIcon color={"#105CF7"} />
          ) : (
            <AddFillIcon color={"#105CF7"} />
          )}
        </Toggle>
      </Header>
      <ProfileMessageBanner messages={messages} />
      {active && <Content>{props.children}</Content>}
    </Container>
  );
};

export default TalentProfileSectionCard;
