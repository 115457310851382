import React, { useState } from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form } from "react-final-form";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import SectionEditCard from "src/Modules/Talent/Pages/TalentProfile/SectionEditCard";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import {
  ButtonGroup,
  EditFooterLine,
  FormField,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import ConditionalRender from "src/Modules/Talent/Pages/TalentProfile/ConditionalRender";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import EditLineIcon from "remixicon-react/EditLineIcon";
import InterestsField from "src/Modules/Shared/Components/Temp/InterestsField";

const Container = styled.div``;

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const Item = styled.div`
  border-radius: 6px;
  padding: 4px 8px;
  color: black;
`;

const Interests = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const Interest = styled.div`
  border-radius: 6px;
  background: var(--Notch-Neutral-50, #fafafb);
  color: var(--Notch-Neutral-600, #6a7288);

  /* Notch/Label/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;

  padding: 2px 6px;
`;

const EditAction = (props) => {
  let Container = styled.div`
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 4px;
    &:hover svg {
      color: red;
    }
  `;

  return (
    <Container {...props}>
      <EditLineIcon color={"gray"} size={18} />
    </Container>
  );
};

const InterestsSection: React.FC = (props) => {
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) =>
      axios.put(`/api/talent/${props.data.talent.id}/profile/interests`, data),
    onSuccess: (data) => {
      setEdit(false);
      props.onUpdate(data);
    },
  });

  let data = props.data;
  let status = "NotCompleted";

  return (
    <Form
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
      initialValues={data}
      render={({ handleSubmit, submitting, values, form }) => (
        <form onSubmit={handleSubmit}>
          <TalentProfileSectionCard
            title="Interests"
            status={props.profileStrength.sections?.interests?.status}
            info={props.profileStrength.sections?.interests?.info}
            messages={props.profileStrength.sections?.interests?.messages}
          >
            <ConditionalRender condition={true}>
              {edit && (
                <SectionEditCard>
                  <FormField>
                    <Label>Add your interests</Label>
                    <Field
                      name={"interests"}
                      component={InterestsField}
                      isMulti={true}
                      options={[]}
                    />
                  </FormField>
                  <EditFooterLine />
                  <ButtonGroup>
                    <Button onClick={() => form.submit()} variant="primary">
                      Save
                    </Button>
                  </ButtonGroup>
                </SectionEditCard>
              )}

              {!edit && (
                <List>
                  <EditAction onClick={() => setEdit(true)}>
                    <PencilLineIcon color={"lightgray"} />
                  </EditAction>
                  {data.interests && data.interests.length > 0 && (
                    <Interests>
                      {data.interests?.map((interest) => (
                        <Interest>{interest}</Interest>
                      ))}
                    </Interests>
                  )}
                </List>
              )}
            </ConditionalRender>
          </TalentProfileSectionCard>
        </form>
      )}
    />
  );
};

export default InterestsSection;
