import React, { useState } from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form } from "react-final-form";
import EditLineIcon from "remixicon-react/EditLineIcon";
import arrayMutators from "final-form-arrays";
import SectionEditCard from "src/Modules/Talent/Pages/TalentProfile/SectionEditCard";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import * as yup from "yup";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import { ButtonGroup } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import SortableSkillsSection from "src/Modules/Talent/Pages/TalentProfile/Sections/SortableSkillsSection";
import ConditionalRender from "src/Modules/Talent/Pages/TalentProfile/ConditionalRender";
import TalentProfileWarning from "src/Modules/Talent/Pages/TalentProfile/TalentProfileWarning";

const Container = styled.div``;

const FormContent = styled.div`
  padding: 12px;
  background: #c1d5fd;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Label = styled.div`
  /* Notch/Label/Medium */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

const SkillContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #f4fbfd;

  gap: 8px;

  height: 32px;
  padding: 0 12px;

  border-radius: 6px;
`;

const SkillName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1d7c93;
`;

const SkillYears = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;

  width: fit-content;
  height: 22px;

  background: #1d7c93;
  border-radius: 999px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  letter-spacing: -0.03em;

  color: #f4fbfd;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  position: relative;
`;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const getSkillLabel = (code) => mapToLabel(code, skillOptions);

const EditAction = (props) => {
  let Container = styled.div`
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -20px;
    &:hover svg {
      color: red;
    }
  `;

  return (
    <Container {...props}>
      <EditLineIcon color={"gray"} size={18} />
    </Container>
  );
};
const YearsContainer = styled.div`
  width: 180px;
`;

const MissingInfo = styled.div`
  background: #fef2f2;
  color: #fca5a5;
  font-size: 12px;
  font-family: Inter;
`;

const validation = yup.object({
  newSkillCode: yup.string().required(),
  newSkillYears: yup.string().required(),
});

const filterTopSkillsSelection = (topSkills) => {
  let topSkillsSet = new Set(topSkills.map((i) => i.skill));

  return skillOptions.filter((option) => {
    return !topSkillsSet.has(option.value);
  });
};

const filterAdditionalSkills = (topSkills, additionalSkills) => {
  let topSkillsSet = new Set(topSkills.map((i) => i.skill));
  return additionalSkills?.filter((option) => {
    return !topSkillsSet.has(option);
  });
};

const getTopSkillsWithourReference = (topSkills, additionalSkills) => {
  let addSkillSet = new Set(additionalSkills);

  return topSkills?.filter((option) => {
    return !addSkillSet.has(option.skill);
  });
};

const SkillsSection: React.FC = (props) => {
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();

  let profile = props.profile;
  let topSkills = profile.topSkills;
  let additionalSkills = profile.skills;

  let currentTopSkills = topSkills;
  let currentAdditionalSkills = additionalSkills;
  // additionalSkills = [];

  let unreferencedTopSkills = getTopSkillsWithourReference(
    topSkills,
    additionalSkills
  );

  const mutation = useMutation({
    mutationFn: (values) =>
      axios.put(`/api/talent/${profile.talent.id}/profile/skills`, values),
    onSuccess: (data) => {
      setEdit(false);
      props.onUpdate(data);
    },
  });

  let messages = props.profileStrength.sections?.skills?.messages.map(
    (message) => {
      let d = message.description;
      if (message.description.startsWith("These top skills are not listed")) {
        d += unreferencedTopSkills
          .map((i) => mapToLabel(i.skill, skillOptions))
          .join(", ");
      }

      return { type: message.type, description: d };
    }
  );

  return (
    <Form
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
      mutators={{ ...arrayMutators }}
      initialValues={{ skills: additionalSkills, topSkills: topSkills }}
      render={({ handleSubmit, submitting, values, form }) => (
        <form onSubmit={handleSubmit}>
          <TalentProfileSectionCard
            title="Skills"
            status={props.profileStrength.sections?.skills?.status}
            info={props.profileStrength.sections?.skills?.info}
            messages={messages}
          >
            {!edit && (
              <Content>
                <EditAction
                  onClick={() => {
                    setEdit(true);
                  }}
                />

                <Label>Top Skills</Label>
                <List>
                  {topSkills.map((skill) => (
                    <SkillContainer>
                      <SkillName>{getSkillLabel(skill.skill)}</SkillName>
                      <SkillYears>{skill.years}y</SkillYears>
                    </SkillContainer>
                  ))}
                </List>
                <Label>Additional Skills</Label>
                <List>
                  {filterAdditionalSkills(topSkills, additionalSkills).map(
                    (skill) => (
                      <SkillContainer>
                        <SkillName>{getSkillLabel(skill)}</SkillName>
                      </SkillContainer>
                    )
                  )}
                </List>
              </Content>
            )}
            {edit && (
              <SectionEditCard>
                <Row>
                  <Field
                    name={"newSkillCode"}
                    component={DefaultSelectField}
                    options={filterTopSkillsSelection(values.topSkills)}
                  />
                  <YearsContainer>
                    <Field
                      name={"newSkillYears"}
                      component={DefaultSelectField}
                      options={[
                        { label: 1, value: 1 },
                        { label: 2, value: 2 },
                        { label: 3, value: 3 },
                        { label: 4, value: 4 },
                        { label: 5, value: 5 },
                        { label: 6, value: 6 },
                        { label: 7, value: 7 },
                        { label: 8, value: 8 },
                        { label: 9, value: 9 },
                        { label: 10, value: 10 },
                        { label: 11, value: 11 },
                        { label: 12, value: 12 },
                        { label: 13, value: 13 },
                        { label: 14, value: 14 },
                        { label: "15+", value: 15 },
                      ]}
                    />
                  </YearsContainer>

                  <Button
                    variant="primary"
                    size="large"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      let topSkillsSet = new Set(
                        values.topSkills.map((i) => i.skill)
                      );

                      if (topSkillsSet.has(values.newSkillCode)) {
                        return;
                      } else {
                        let newTopSkills = values.topSkills;
                        newTopSkills.push({
                          skill: values.newSkillCode,
                          years: values.newSkillYears,
                        });
                        form.change("topSkills", newTopSkills);
                        form.change("newSkillCode", null);
                        form.change("newSkillYears", null);
                      }
                    }}
                    disabled={!validation.isValidSync(values)}
                  >
                    Add
                  </Button>
                </Row>
                <ConditionalRender condition={true}>
                  <SortableSkillsSection form={form} values={values} />
                </ConditionalRender>
                <Content>
                  <ButtonGroup>
                    <Button
                      style={{ marginLeft: "auto" }}
                      variant="primary"
                      size="medium"
                      type="submit"
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </Content>
              </SectionEditCard>
            )}
          </TalentProfileSectionCard>
        </form>
      )}
    />
  );
};

export default SkillsSection;
