import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import { NavLink, useSearchParams } from "react-router-dom";
import { Column } from "react-table";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import PaginationControlsSingle from "src/Modules/Admin/Components/AdminTable/PaginationControlsSingle";
import AdminTable from "src/Modules/Admin/Components/AdminTable/AdminTable";
import { useQuery } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import TalentStage from "src/Modules/Admin/Pages/Talents/TalentStage";
import CreateTalentAction from "src/Modules/Admin/Pages/Talents/CreateTalentAction";

const Container = styled.div``;

const LinkToTalent = styled(NavLink)``;

const TalentName = styled.div`
  font-family: Inter;
  color: #0f172a;
  font-weight: 500;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 24px;
  border-radius: 12px;

  width: 100%;
  min-height: 200px;

  margin-bottom: 32px;

  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  color: #9fa4b3;

  &:hover {
    color: #4f87f9;
    background: #fafafa;
    border-radius: 5px;
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StageFilterContainer = styled.div`
  width: 200px;
  margin-left: auto;
`;

const Nav = styled.div`
  margin-left: auto;
  width: 400px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0 12px auto;
  align-items: center;
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const CompanyUrl = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const CompanyStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ededf0;
  color: #6a7288;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  border-radius: 6px;
  padding: 2px 12px;
`;

const IsFetchingContainer = styled.div`
  width: 300px;
  height: 25px;
  margin-left: auto;
`;
const IsFetchingText = styled.div`
  font-size: 16px;
  font-family: Inter;
  color: #64748b;
  text-align: right;
  font-weight: 300;
`;

const CompanyLinkToPage = styled(NavLink)`
  cursor: pointer;
  display: block;
  padding: 2px 6px;
  width: fit-content;
  border-radius: 12px;
  :hover {
    background: #ecfeff;
  }
`;

const TalentsPage: React.FC = (props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const info = useQuery({
    ...BackofficeApi.talents.list(searchParams),
    keepPreviousData: true,
  });

  const Columns: Column<any>[] = [
    {
      Header: "Talent",
      accessor: (row) => {
        return (
          <LinkToTalent to={`/talents/${row.id}`}>
            <FlexRow>
              <AccountProfileAvatar
                size={32}
                name={row.account?.fullname ?? row.talentProfile?.fullname}
                profilePictureUrl={row.account?.profilePicture}
              />

              <TalentName>
                {row.account?.fullname ?? row.talentProfile?.fullname}
              </TalentName>
            </FlexRow>
          </LinkToTalent>
        );
      },
      width: 100,
    },
    {
      Header: "Stage",
      accessor: (row) => {
        return <TalentStage stage={row.stage} />;
      },
    },
  ];

  return (
    <FluidContentContainer>
      <PageTitle>Talents</PageTitle>
      <PageTitleLine />
      <CreateTalentAction />
      <Card>
        <Title>
          <span>{info.data?.data.totalResults} Talents</span>
          <Nav>
            <PaginationControlsSingle
              searchParams={searchParams}
              totalResults={info.data?.data.totalResults}
            />
          </Nav>
        </Title>
        <IsFetchingContainer>
          {info.isFetching && <IsFetchingText>Loading...</IsFetchingText>}
        </IsFetchingContainer>
        {!info.isLoading && (
          <AdminTable data={info.data?.data.results} columns={Columns} />
        )}
      </Card>
    </FluidContentContainer>
  );
};

export default TalentsPage;
