import React, { useState } from "react";
import styled from "styled-components";
import {
  FormField,
  FormRow,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import { Field } from "react-final-form";
import UniversitySelect from "src/Modules/Core/Components/Form/Select/UniversitySelect/UniversitySelect";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import ToggleSwitchField from "src/Modules/Shared/Components/Temp/ToggleSwitchField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import MainSkillsField from "src/Modules/Shared/Components/Temp/MainSkillsField";
import CommonMultipleSkillsSelect from "src/Modules/Shared/Components/Temp/CommonMultipleSkillsSelect";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import { FormLabel } from "src/Modules/Recruiter/Pages/Profile/ProfileEditForm";
import { FieldArray } from "react-final-form-arrays";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";
import CompanySearchField from "src/Modules/Shared/Components/Temp/CompanySearchField";
import { FormGroupDynamic } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";

const Container = styled.div``;

const RowLines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NewLineInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }
`;

const RowLineSpacer = styled.div`
  height: 8px;
`;

const hasEmptyLine = (values: Array<any>) => {
  if (values == undefined) {
    return false;
  }

  for (const [key, value] of Object.entries(values)) {
    if (value == "") {
      return true;
    }
  }

  return false;
};

const CustomCompanyFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;

  border: 1px solid #ededf0;
  border-radius: 6px;
  width: 100%;
`;

const CustomCompanyFormSave = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-left: auto;
  background: none;
`;

const WorkHistoryEditForm: React.FC = (props) => {
  let formProps = props.formProps;
  let values = formProps.values;
  let form = formProps.form;

  let [customCompanyForm, setCustomCompanyForm] = useState(false);

  return (
    <>
      {customCompanyForm && (
        <CustomCompanyFormContainer>
          <FormGroupDynamic>
            <FormLabel>Company Name</FormLabel>
            <Field
              name={"companyName"}
              component={TextInputField}
              placeholder="Acme, Inc."
            />
          </FormGroupDynamic>

          <FormGroupDynamic>
            <FormLabel>Company Website</FormLabel>
            <Field
              name={"companyWebsite"}
              component={TextInputField}
              placeholder="yourcompany.com"
            />
          </FormGroupDynamic>

          <CustomCompanyFormSave
            onClick={() => {
              form.change("companyLogo", null);
              setCustomCompanyForm(false);
            }}
          >
            Save
          </CustomCompanyFormSave>
        </CustomCompanyFormContainer>
      )}

      {!customCompanyForm && (
        <FormField>
          <FormLabel>Company Name</FormLabel>
          <Field
            name="company_autocomplete"
            setCustomCompanyForm={(value) => setCustomCompanyForm(value)}
            customValue={{
              label: values.companyName,
              value: values.companyName,
              logo:
                values.companyLogo ??
                "https://logo.clearbit.com/" + values.companyWebsite,
              domain: values.companyWebsite,
            }}
            form={form}
            component={CompanySearchField}
          />
        </FormField>
      )}

      <FormField>
        <Label>Title</Label>
        <Field name={"title"} component={TextInputField} options={[]} />
      </FormField>
      <FormField>
        <Label>Employment Type</Label>
        <Field
          name={"employmentType"}
          component={DefaultSelectField}
          options={[
            { value: "Full-time", label: "Full-time" },
            { value: "Part-time", label: "Part-time" },
            { value: "Self-Employed", label: "Self-Employed" },
            { value: "Freelance", label: "Freelance" },
            { value: "Internship", label: "Internship" },
          ]}
        />
      </FormField>

      <FormField>
        <Label>Location</Label>
        <Field name={"location"} component={LocationInputField} />
      </FormField>
      <FormField
        style={{
          flexDirection: "row-reverse",
          marginRight: "auto",
          alignItems: "center",
        }}
      >
        <Label>Remote</Label>
        <Field name={"remote"} component={ToggleSwitchField} />
      </FormField>
      <FormRow>
        <FormField>
          <Label>Start Date </Label>
          <Field
            name={"startDateMonth"}
            component={DefaultSelectField}
            options={[
              { value: 1, label: "January" },
              { value: 2, label: "February" },
              { value: 3, label: "March" },
              { value: 4, label: "April" },
              { value: 5, label: "May" },
              { value: 6, label: "June" },
              { value: 7, label: "July" },
              { value: 8, label: "August" },
              { value: 9, label: "September" },
              { value: 10, label: "October" },
              { value: 11, label: "November" },
              { value: 12, label: "December" },
            ]}
          />
        </FormField>
        <FormField>
          <span style={{ color: "white" }}>.</span>
          <Field
            name={"startDateYear"}
            component={DefaultSelectField}
            options={TalentProfileOptions.workHistoryYears}
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Label>End Date </Label>
          <Field
            name={"endDateMonth"}
            component={DefaultSelectField}
            options={[
              { value: 1, label: "January" },
              { value: 2, label: "February" },
              { value: 3, label: "March" },
              { value: 4, label: "April" },
              { value: 5, label: "May" },
              { value: 6, label: "June" },
              { value: 7, label: "July" },
              { value: 8, label: "August" },
              { value: 9, label: "September" },
              { value: 10, label: "October" },
              { value: 11, label: "November" },
              { value: 12, label: "December" },
            ]}
          />
        </FormField>
        <FormField>
          <span style={{ color: "white" }}>.</span>
          <Field
            name={"endDateYear"}
            component={DefaultSelectField}
            options={TalentProfileOptions.workHistoryYears}
          />
        </FormField>
      </FormRow>
      <FormField
        style={{
          flexDirection: "row-reverse",
          marginRight: "auto",
          alignItems: "center",
        }}
      >
        <Label>I am currently working in this role</Label>
        <Field name={"currentlyWorking"} component={ToggleSwitchField} />
      </FormField>
      <FormField>
        <Label>Skills Used</Label>
        <Field name={"skills"} component={CommonMultipleSkillsSelect} />
      </FormField>
      <FormField>
        <Label>Role Description</Label>
        <Field name={"roleDescription"} component={TextareaField} />
      </FormField>
      {/*
      <FormField>
        <FormLabel>Key Contributions</FormLabel>
        <FieldArray name={"keyContributions"}>
          {({ fields }) => (
            <RowLines>
              {fields?.map((name, index) => {
                return (
                  <div id={name}>
                    <Field
                      onFocus={(event: FocusEvent) => {
                        //remove all empty fields but this
                        formProps.values.keyContributions_?.forEach(
                          (i: any, ii: any) => {
                            if (i == undefined && index != ii) {
                              formProps.form.mutators.remove(
                                "keyContributions",
                                ii
                              );
                            }
                          }
                        );
                      }}
                      id={`keyContributions_${index}`}
                      name={`${name}`}
                      component={TextInputField}
                      placeholder={`Contribution #${index + 1}`}
                    />
                    <RowLineSpacer />
                  </div>
                );
              })}
              {!hasEmptyLine(formProps.values.keyContributions) && (
                <NewLineInput
                  onFocus={(event: FocusEvent) => {
                    //remove all empty fields but this
                    formProps.values.keyContributions?.forEach(
                      (i: any, ii: any) => {
                        if (i == undefined) {
                          formProps.form.mutators.remove(
                            "keyContributions",
                            ii
                          );
                        }
                      }
                    );
                  }}
                  placeholder={`Provide key contributions`}
                  onChange={(event) => {
                    event.target.disabled = true;
                    const index = formProps.values.keyContributions
                      ? formProps.values.keyContributions.length
                      : 0;
                    formProps.form.mutators.insert(
                      "keyContributions",
                      index,
                      event.target.value
                    );
                    event.target.value = "";
                    setTimeout(() => {
                      const el = document.getElementById(
                        "keyContributions_" + index
                      );
                      el.focus();
                      event.target.disabled = false;
                    });
                  }}
                />
              )}
            </RowLines>
          )}
        </FieldArray>
      </FormField>
      */}
    </>
  );
};

export default WorkHistoryEditForm;
