import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div``;

type Props = {
  condition: Boolean;
} & PropsWithChildren;

const ConditionalRender: React.FC<Props> = (props) => {
  if (props.condition) {
    return props.children;
  } else {
    return null;
  }
};

export default ConditionalRender;
