import React, { useState } from "react";
import styled from "styled-components";
import HoverPopup from "src/Modules/Talent/Pages/TalentProfile/HoverPopup";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import SalaryAvailabilityEditForm from "src/Modules/Talent/Pages/TalentProfile/SalaryAvailabilityEditForm";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import WorkPrefencesEditForm from "src/Modules/Talent/Pages/TalentProfile/WorkPreferencesEditForm";
import InformationLineIcon from "remixicon-react/InformationLineIcon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Section = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  height: 32px;

  border: 1px solid #ededf0;
  border-radius: 6px;
`;

const Icon = styled.div`
  width: 20px;
  aspect-ratio: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  gap: 2px;

  display: flex;
  flex-direction: row;

  &.notcomplete {
    color: #9fa4b3;
  }
`;

const getClassForUncompleted = (status) => {
  if (status == null || status == undefined || status == "UnmetRequired") {
    return "notcomplete";
  }

  return "";
};

const Star = styled.div`
  /* Notch/Label/Small */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  color: #105cf7;
`;

const MetRequiredIcon = styled.div`
  width: 18px;
  height: 18px;
  aspect-ratio: 1;
  border-radius: 999px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--Notch-Secondary-Orange-50, #fff8e2);
  color: var(--Notch-Secondary-Orange-600, #fbb32b);

  &.issue {
    background: #fef2f2;
  }
`;

const getIcon = (section) => {
  let status = section?.status;
  let message = section?.info;

  if (status == "Issue") {
    return (
      <div message={message}>
        <MetRequiredIcon className={"issue"}>
          <InformationLineIcon size={18} color={"red"} />
        </MetRequiredIcon>
      </div>
    );
  }

  if (status == "s") {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_721_4513)">
          <path
            d="M10.0001 18.3337C5.39758 18.3337 1.66675 14.6028 1.66675 10.0003C1.66675 5.39783 5.39758 1.66699 10.0001 1.66699C14.6026 1.66699 18.3334 5.39783 18.3334 10.0003C18.3334 14.6028 14.6026 18.3337 10.0001 18.3337ZM9.16925 13.3337L15.0609 7.44116L13.8826 6.26283L9.16925 10.977L6.81175 8.61949L5.63341 9.79782L9.16925 13.3337Z"
            fill="#105CF7"
          />
        </g>
        <defs>
          <clipPath id="clip0_721_4513">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (status == "Exceeded") {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_721_4513)">
          <path
            d="M10.0001 18.3337C5.39758 18.3337 1.66675 14.6028 1.66675 10.0003C1.66675 5.39783 5.39758 1.66699 10.0001 1.66699C14.6026 1.66699 18.3334 5.39783 18.3334 10.0003C18.3334 14.6028 14.6026 18.3337 10.0001 18.3337ZM9.16925 13.3337L15.0609 7.44116L13.8826 6.26283L9.16925 10.977L6.81175 8.61949L5.63341 9.79782L9.16925 13.3337Z"
            fill="#105CF7"
          />
        </g>
        <defs>
          <clipPath id="clip0_721_4513">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (status == "Suggested") {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_721_4513)">
          <path
            d="M10.0001 18.3337C5.39758 18.3337 1.66675 14.6028 1.66675 10.0003C1.66675 5.39783 5.39758 1.66699 10.0001 1.66699C14.6026 1.66699 18.3334 5.39783 18.3334 10.0003C18.3334 14.6028 14.6026 18.3337 10.0001 18.3337ZM9.16925 13.3337L15.0609 7.44116L13.8826 6.26283L9.16925 10.977L6.81175 8.61949L5.63341 9.79782L9.16925 13.3337Z"
            fill="#ccc"
          />
        </g>
        <defs>
          <clipPath id="clip0_721_4513">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (status == "Minimum") {
    return (
      <MetRequiredIcon>
        <InformationLineIcon size={18} color={"#fbb32b"} />
      </MetRequiredIcon>
    );
  }

  // New statuses
  if (status == "Pristine") {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0001 18.3337C5.39758 18.3337 1.66675 14.6028 1.66675 10.0003C1.66675 5.39783 5.39758 1.66699 10.0001 1.66699C14.6026 1.66699 18.3334 5.39783 18.3334 10.0003C18.3334 14.6028 14.6026 18.3337 10.0001 18.3337ZM10.0001 16.667C11.7682 16.667 13.4639 15.9646 14.7141 14.7144C15.9644 13.4641 16.6667 11.7684 16.6667 10.0003C16.6667 8.23222 15.9644 6.53652 14.7141 5.28628C13.4639 4.03604 11.7682 3.33366 10.0001 3.33366C8.23197 3.33366 6.53628 4.03604 5.28604 5.28628C4.03579 6.53652 3.33341 8.23222 3.33341 10.0003C3.33341 11.7684 4.03579 13.4641 5.28604 14.7144C6.53628 15.9646 8.23197 16.667 10.0001 16.667Z"
          fill="#D2D4DB"
        />
      </svg>
    );
  }
};

const ProfileStrenghtSections: React.FC = (props) => {
  let profileStrengthScore = props.value;
  let sections = profileStrengthScore?.sections;

  return (
    <Container>
      <Section>
        <Icon>{getIcon(sections.basicDetails)}</Icon>
        <Label
          className={getClassForUncompleted(sections.basicDetails?.status)}
        >
          Basic Details<Star>*</Star>
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.summary)}</Icon>
        <Label className={getClassForUncompleted(sections.summary?.status)}>
          Summary<Star>*</Star>
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.skills)}</Icon>
        <Label className={getClassForUncompleted(sections.skills?.status)}>
          Skills<Star>*</Star>
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.workHistory)}</Icon>
        <Label className={getClassForUncompleted(sections.workHistory?.status)}>
          Work History<Star>*</Star>
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.education)}</Icon>
        <Label className={getClassForUncompleted(sections.education?.status)}>
          Education
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.projects)}</Icon>
        <Label className={getClassForUncompleted(sections.projects?.status)}>
          Projects
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.certifications)}</Icon>
        <Label
          className={getClassForUncompleted(sections.certifications?.status)}
        >
          Certifications & Awards
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.interests)}</Icon>
        <Label className={getClassForUncompleted(sections.interests?.status)}>
          Interests
        </Label>
      </Section>
      <Section>
        <Icon>{getIcon(sections.social)}</Icon>
        <Label className={getClassForUncompleted(sections.social?.status)}>
          Social Profiles
        </Label>
      </Section>
    </Container>
  );
};

export default ProfileStrenghtSections;
