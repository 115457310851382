import React from "react";
import styled from "styled-components";
import TalentProfileSectionCard from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import TalentProfileSectionList from "src/Modules/Talent/Pages/TalentProfile/TalentProfileSectionList";
import AddFillIcon from "remixicon-react/AddFillIcon";
import { FormField } from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import WorkHistoryEntry from "src/Modules/Talent/Pages/TalentProfile/Sections/WorkHistoryEntry";
import WorkHistoryEditForm from "src/Modules/Talent/Pages/TalentProfile/Sections/WorkHistoryEditForm";
import ConditionalRender from "src/Modules/Talent/Pages/TalentProfile/ConditionalRender";

const Container = styled.div``;

const WorkHistorySection: React.FC = (props) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `/api/talent/${props.profile.talent.id}/profile/workHistories`,
        data
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `/api/talent/${props.profile.talent.id}/profile/workHistories/${data.id}`,
        data
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (data) =>
      axios.delete(
        `/api/talent/${props.profile.talent.id}/profile/workHistories/${data.id}`
      ),
    onSuccess: (data) => {
      props.onUpdate(data);
    },
  });

  let profile = props.profile;

  return (
    <TalentProfileSectionCard
      title="Work History"
      status={props.profileStrength.sections?.workHistory?.status}
      info={props.profileStrength.sections?.workHistory?.info}
      messages={props.profileStrength.sections?.workHistory?.messages}
    >
      <ConditionalRender condition={true}>
        <TalentProfileSectionList
          singleItemView={(item) => <WorkHistoryEntry data={item} />}
          createMutation={createMutation}
          updateMutation={updateMutation}
          deleteMutation={deleteMutation}
          items={profile.sortedWorkHistories.map((item) => {
            let obj = item.data;
            obj.id = item.id;
            return obj;
          })}
          profile={profile}
          itemForm={(formProps) => (
            <WorkHistoryEditForm formProps={formProps} />
          )}
          createItemLabel={
            <>
              <AddFillIcon size={20} />
              Add Work History
            </>
          }
        />
      </ConditionalRender>
    </TalentProfileSectionCard>
  );
};

export default WorkHistorySection;
