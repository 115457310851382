import React from "react";
import styled from "styled-components";
import {
  typography,
  TypographyVariants,
} from "src/Modules/Core/Components/Atoms/Typography/typography.css";

const Container = styled.div``;

type Props = {} & TypographyVariants & React.ComponentPropsWithRef<"div">;

const Typography: React.FC<Props> = (props) => {
  let { type } = props;

  return (
    <Container className={typography({ type })}>{props.children}</Container>
  );
};

export default Typography;
