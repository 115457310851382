import React, { useState } from "react";
import styled from "styled-components";
import Select, { OptionProps, SingleValueProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import { FieldRenderProps } from "react-final-form";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";
import {
  DropdownIndicator,
  Option,
  ValueContainer,
} from "src/Modules/Core/Components/Form/Select/SelectComponents";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import { useMediaQuery } from "usehooks-ts";
import TalentProfileOptions from "src/Modules/Talent/Pages/TalentProfile/TalentProfileOptions";

const Container = styled.div`
  width: 100%;
  min-height: 32px;
  height: fit-content;
  .react-select__control {
    min-height: 32px;
    height: fit-content;
  }

  .react-select__value-container {
    flex-wrap: wrap;
    height: fit-content;
    display: flex;
  }

  div[class$="container"] {
    height: fit-content;
  }

  .react-select__container {
    height: fit-content;
    margin: 20px;
  }

  .react-select__menu {
    min-height: fit-content;
    min-width: 300px;
  }
`;

const ContainerStatic = styled.div`
  min-height: 32px;
  height: 32px;

  .react-select__control {
    min-height: 32px;
    height: fit-content;
    overflow: scroll;
  }

  .react-select__container {
    height: fit-content;
    margin: 20px;
  }

  .react-select__value-container {
    width: 30px;
  }
`;

const Dot = styled.div`
  width: 5px;
  aspect-ratio: 1;
  border-radius: 999px;
  background: #e6eefe;
`;

const GreenDot = styled(Dot)`
  background: #00c181;
`;

const OrangeDot = styled(Dot)`
  background: #fbb32b;
`;

const GrayDot = styled(Dot)`
  background: #cbc8c8;
`;

const Label = styled.div``;

const Value = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

type SelectFieldProps = FieldRenderProps<[], any>;

const CustomOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 12px;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  height: fit-content;
  gap: 6px;
  cursor: pointer;

  &:hover {
    background: #f8fafc;
  }

  .flag {
    width: 16px;
    height: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #1c274a;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    margin-bottom: 12px;
    line-height: 1;
  }
`;

const DotContainer = styled.div`
  margin-top: 4px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #565e78;
`;

const SingleValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const CustomSingleValue = (props: SingleValueProps) => {
  let value = null;

  if (props.hasValue) {
    value = props.getValue()[0].value;
  }

  let currentOption = props.options.find((i) => {
    return i.value == value;
  });

  let RenderDot = <></>;

  switch (value) {
    case "Active":
      RenderDot = <GreenDot />;
      break;
    case "Open":
      RenderDot = <OrangeDot />;
      break;
    case "Unavailable":
      RenderDot = <GrayDot />;
      break;
    default:
      RenderDot = null;
  }

  if (value == null) {
    return (
      <SingleValueContainer>
        <GrayDot />
        <span>Not set</span>
      </SingleValueContainer>
    );
  }

  return (
    <SingleValueContainer>
      {RenderDot}
      <span>{currentOption.label}</span>
    </SingleValueContainer>
  );
};

const getDescription = (value) => {
  switch (value) {
    case "Active":
      return (
        <span>
          You are interested in new opportunities and would be readily available
          if it was the right fit.{" "}
          <b>This maximizes your chance of getting matched.</b>
        </span>
      );
    case "Open":
      return (
        <span>
          You are not actively looking for a new job but would be open to
          learning about new offers that match your skills and preferences.
        </span>
      );
    case "Unavailable":
      return (
        <span>
          You are not looking for a new job and are not currently interested in
          learning about new offers. <b>You won't be matched with any jobs.</b>
        </span>
      );
    default:
      return null;
  }
};

const CustomOption = (props: OptionProps) => {
  let currentOption = props.options.find((i) => {
    return i.value == props.value;
  });

  let RenderDot = <></>;

  switch (currentOption.value) {
    case "Active":
      RenderDot = <GreenDot />;
      break;
    case "Open":
      RenderDot = <OrangeDot />;
      break;
    case "Unavailable":
      RenderDot = <GrayDot />;
      break;
    default:
      RenderDot = null;
  }

  return (
    <CustomOptionContainer
      onClick={() => {
        props.selectOption(currentOption);
      }}
    >
      <DotContainer>{RenderDot}</DotContainer>
      <Text>
        <label>{currentOption.label}</label>
        <Description>{getDescription(currentOption.value)}</Description>
      </Text>
    </CustomOptionContainer>
  );
};

const TalentHiringStatusField: React.FC<SelectFieldProps> = (props) => {
  let selectedOptions = null;
  let isMobile = useMediaQuery("(max-width: 600px)");

  let options = TalentProfileOptions.jobSearchingStatus;

  selectedOptions = options.filter((option: any) => {
    return option.value === props.input.value;
  });

  let RenderContainer = Container;

  if (props.static == "MobileOnly") {
    if (isMobile) {
      RenderContainer = Container;
    } else {
      RenderContainer = ContainerStatic;
    }
  }

  let SelectComponent = Select;

  return (
    <RenderContainer>
      <SelectComponent
        closeMenuOnSelect={!props.isMulti}
        isMulti={props.isMulti}
        hideSelectedOptions={false}
        classNamePrefix="react-select"
        components={{
          DropdownIndicator: DropdownIndicator,
          Option: CustomOption,
          ValueContainer: ValueContainer,
          SingleValue: CustomSingleValue,
        }}
        styles={DefaultSelectStyle}
        options={options}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        value={selectedOptions}
        onChange={(newValue: any, actionMeta: any) => {
          if (props.isMulti) {
            props.input.onChange(newValue.map((i: any) => i.value));
          } else {
            props.input.onChange(newValue.value);
          }
        }}
        {...props}
      />
    </RenderContainer>
  );
};

export default TalentHiringStatusField;
